import OpenAI from 'openai';
import indexedDBService from './indexedDBService';
import axios from 'axios';

const getApiKey = () => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  
  if (!apiKey) {
    throw new Error(
      'OpenAI API key is missing. Please ensure:\n' +
      '1. You have a .env or .env.local file in your project root\n' +
      '2. The file contains: REACT_APP_OPENAI_API_KEY=your_api_key\n' +
      '3. You have restarted your development server'
    );
  }
  
  return apiKey;
};

let openaiInstance = null;

const getOpenAI = () => {
  if (!openaiInstance) {
    openaiInstance = new OpenAI({
      apiKey: getApiKey(),
      dangerouslyAllowBrowser: true
    });
  }
  return openaiInstance;
};

class OpenAIService {
  constructor() {
    this.apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    this.baseURL = 'https://api.openai.com/v1/chat/completions';
  }

  // Helper function to check if a date is within range
  isDateInRange(date, startDate, endDate) {
    const bookingDate = new Date(date);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return bookingDate >= start && bookingDate <= end;
  }

  // Helper to validate date range
  validateDateRange(startDate, endDate) {
    if (!startDate || !endDate) {
      throw new Error('Start date and end date are required');
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error('Invalid date format');
    }

    if (start > end) {
      throw new Error('Start date must be before end date');
    }

    return { start, end };
  }

  // Helper to filter clients by date range
  filterClientsByDateRange(clients, startDate, endDate) {
    const { start, end } = this.validateDateRange(startDate, endDate);
    
    return clients.map(client => ({
      ...client,
      opportunities: client.opportunities?.filter(opp => 
        opp.CloseDate && this.isDateInRange(opp.CloseDate, start, end)
      ) || []
    })).filter(client => client.opportunities.length > 0);
  }

  summarizeBookingData(clients, startDate, endDate) {
    // console.log('Summarizing booking data for date range:', { startDate, endDate });
    
    // Validate and filter data by date range
    const filteredClients = this.filterClientsByDateRange(clients, startDate, endDate);
    // console.log('Filtered clients count:', filteredClients.length);

    const summary = {
      totalBookings: 0,
      totalRevenue: 0,
      locationStats: {},
      designStats: {},
      monthlyStats: {},
      clientStats: {},
      brandStats: {}
    };

    // Process filtered data
    filteredClients.forEach(client => {
      client.opportunities?.forEach(opp => {
        if (!opp.CloseDate || !opp.Total_Hire_Fees__c) return;

        const date = new Date(opp.CloseDate);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthKey = `${year}-${month + 1}`;
        const revenue = Number(opp.Total_Hire_Fees__c) || 0;
        const location = opp.Location_Name__r?.Name || 'Unknown';
        const design = opp.Location_Name__r?.Design__c || 'Unknown';
        const brand = opp.Brand__c || 'Unknown';

        // Update summary statistics
        summary.totalBookings++;
        summary.totalRevenue += revenue;

        // Update location stats
        summary.locationStats[location] = summary.locationStats[location] || { bookings: 0, revenue: 0 };
        summary.locationStats[location].bookings++;
        summary.locationStats[location].revenue += revenue;

        // Update design stats
        if (design) {
          const designs = design.split(';').map(d => d.trim());
          designs.forEach(d => {
            summary.designStats[d] = summary.designStats[d] || { bookings: 0, revenue: 0 };
            summary.designStats[d].bookings++;
            summary.designStats[d].revenue += revenue;
          });
        }

        // Update monthly stats
        summary.monthlyStats[monthKey] = summary.monthlyStats[monthKey] || { bookings: 0, revenue: 0 };
        summary.monthlyStats[monthKey].bookings++;
        summary.monthlyStats[monthKey].revenue += revenue;

        // Update client stats
        summary.clientStats[client.clientName] = summary.clientStats[client.clientName] || { bookings: 0, revenue: 0 };
        summary.clientStats[client.clientName].bookings++;
        summary.clientStats[client.clientName].revenue += revenue;

        // Update brand stats
        summary.brandStats[brand] = summary.brandStats[brand] || { bookings: 0, revenue: 0 };
        summary.brandStats[brand].bookings++;
        summary.brandStats[brand].revenue += revenue;
      });
    });

    // Convert stats to sorted arrays and take top entries
    return {
      overview: {
        totalBookings: summary.totalBookings,
        totalRevenue: summary.totalRevenue,
        averageBookingValue: summary.totalRevenue / summary.totalBookings || 0,
        dateRange: {
          start: new Date(startDate).toISOString(),
          end: new Date(endDate).toISOString()
        }
      },
      topLocations: Object.entries(summary.locationStats)
        .sort((a, b) => b[1].revenue - a[1].revenue)
        .slice(0, 50)
        .map(([name, stats]) => ({ name, ...stats })),
      topDesigns: Object.entries(summary.designStats)
        .sort((a, b) => b[1].revenue - a[1].revenue)
        .slice(0, 50)
        .map(([name, stats]) => ({ name, ...stats })),
      recentMonths: Object.entries(summary.monthlyStats)
        .sort((a, b) => b[0].localeCompare(a[0]))
        .slice(0, 52)
        .map(([month, stats]) => ({ month, ...stats })),
      topClients: Object.entries(summary.clientStats)
        .sort((a, b) => b[1].revenue - a[1].revenue)
        .slice(0, 50)
        .map(([name, stats]) => ({ name, ...stats })),
      topBrands: Object.entries(summary.brandStats)
        .sort((a, b) => b[1].revenue - a[1].revenue)
        .slice(0, 10)
        .map(([name, stats]) => ({ name, ...stats }))
    };
  }

  // Helper function to strip Markdown formatting
  stripMarkdown(text) {
    if (typeof text !== 'string') return text;
    return text.replace(/\*\*/g, '');
  }

  // Helper to clean response data
  cleanResponseData(data) {
    if (Array.isArray(data)) {
      return data.map(item => this.cleanResponseData(item));
    }
    if (typeof data === 'object' && data !== null) {
      const cleaned = {};
      Object.entries(data).forEach(([key, value]) => {
        cleaned[key] = this.cleanResponseData(value);
      });
      return cleaned;
    }
    return this.stripMarkdown(data);
  }

  async analyzeBookingTrends(startDate, endDate) {
    try {
      if (!window.location.pathname.includes('/insights')) {
        // console.log('Skipping AI analysis - not on Insights page');
        return null;
      }

      const clients = await indexedDBService.getAllClients();
      const summarizedData = this.summarizeBookingData(
        clients,
        startDate,
        endDate
      );

      // console.log('Sending analysis request to OpenAI...', { startDate, endDate });

      const prompt = `
        You are a data analyst working for Pure Locations, specializing in location hire and event spaces in Australia for photoshoots, events, and filming. 
        We don't do early bird discounts, offers, or promotions. Analyze this booking data for specific, actionable insights.

        Focus on:
        1. Revenue: Identify specific revenue patterns, including exact percentage changes and growth rates
        2. Locations: Name specific locations and their performance metrics
        3. Seasonality: Identify exact peak booking months and quiet periods in Australia
        4. Client behavior: Highlight specific client segments and their booking patterns
        5. Future opportunities: Provide specific, data-backed predictions
        6. Design trends: Identify specific design styles and their performance metrics

        Data period: ${summarizedData.overview.dateRange.start} to ${summarizedData.overview.dateRange.end}
        Data: ${JSON.stringify(summarizedData)}

        Provide analysis in this JSON format:
        {
          "keyInsights": [
            "Specific insight with exact numbers and percentages",
            "Specific location or client performance metrics"
          ],
          "revenueTrends": {
            "analysis": "Include specific growth rates, peak months, and exact revenue figures",
            "prediction": "Specific revenue target for next quarter based on current trends"
          },
          "locationInsights": {
            "topLocations": ["location1 ($X revenue, Y bookings)", "location2..."],
            "designTrends": "Specific design styles with booking numbers",
            "recommendations": "Specific actions with expected ROI"
          },
          "seasonalPatterns": {
            "peakPeriods": "Exact months and location hire numbers",
            "recommendations": "Specific pricing or marketing actions for each season"
          },
          "clientInsights": {
            "topClients": ["client1 ($X spent, Y bookings)", "client2..."],
            "patterns": "Specific booking patterns with numbers",
            "recommendations": "Targeted actions for specific client segments"
          }
        }

        Requirements:
        1. All insights must include specific numbers, percentages, and dollar amounts
        2. All recommendations must be specific and actionable
        3. Focus on Australian market seasonality
        4. Include specific revenue targets and growth predictions
        5. Highlight exact dates and patterns
        6. Include specific marketing recommendations
        7. Do not use any Markdown formatting or special characters
        8. Format currency values with dollar signs and commas
      `;

      const openai = getOpenAI();
      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4o-mini",
        temperature: 0.66,
        max_tokens: 800,
        top_p: 0.75,
        frequency_penalty: 0.5,
        presence_penalty: 0.55,
        response_format: { type: "json_object" }
      });

      // console.log('Received response from OpenAI');
      const result = JSON.parse(completion.choices[0].message.content);

      // Clean the response data
      return this.cleanResponseData(result);
    } catch (error) {
      console.error('Error in analyzeBookingTrends:', error);
      if (error.response) {
        console.error('OpenAI API Error:', {
          status: error.response.status,
          data: error.response.data
        });
      }
      throw new Error(`Failed to analyze trends: ${error.message}`);
    }
  }

  async queryBookingData(question, clients, startDate, endDate) {
    try {
      // console.log('Starting booking data query...', { question, startDate, endDate });
      
      // First, get the summarized data
      const summarizedData = this.summarizeBookingData(clients, startDate, endDate);

      const prompt = `
        You are a data analyst for Pure Locations, a company specializing in location hire and event spaces in Australia.
        Answer the following question about our booking data. Use specific numbers and percentages.
        Be concise but thorough. Format currency in dollars with dollar signs and commas.
        Do not use any Markdown formatting or special characters.

        Question: ${question}

        Here's the relevant booking data for the period ${summarizedData.overview.dateRange.start} to ${summarizedData.overview.dateRange.end}:
        ${JSON.stringify(summarizedData, null, 2)}

        Requirements:
        1. Answer directly and specifically
        2. Include exact numbers and percentages where relevant
        3. Format all currency values with dollar signs and commas
        4. If the question cannot be answered with the available data, say so clearly
        5. Keep the response under 200 words
        6. Focus only on the data that's relevant to the question
        7. Do not use any Markdown formatting or special characters
      `;

      // console.log('Sending query to OpenAI...');
      const openai = getOpenAI();
      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4o-mini",
        temperature: 0.66,
        max_tokens: 800,
        top_p: 0.75,
        frequency_penalty: 1,
        presence_penalty: 0.50
      });

      // console.log('Received response from OpenAI');
      return {
        answer: this.stripMarkdown(completion.choices[0].message.content),
        data: summarizedData
      };
    } catch (error) {
      console.error('Error in queryBookingData:', error);
      if (error.response) {
        console.error('OpenAI API Error:', {
          status: error.response.status,
          data: error.response.data
        });
      }
      throw new Error(`Failed to query data: ${error.message}`);
    }
  }

  async generateEmailContent(prompt) {
    if (!this.apiKey) {
      console.error('OpenAI API key is missing');
      throw new Error('OpenAI API key is not configured');
    }

    try {
      console.log('Starting email generation with OpenAI...', {
        apiKeyLength: this.apiKey.length,
        promptLength: prompt.length,
        baseURL: this.baseURL
      });

      const requestBody = {
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: "You are a professional email writer for Pure Locations, a location scouting company. Write short, personalized emails following this exact structure:\n\n1. Start with a brief, friendly greeting using their first name\n2. A short opening line about their wellbeing\n3. One sentence introducing why you're reaching out\n4. One or two sentences describing the key features of the location\n5. A clear call to action about arranging a recce or getting more details\n\nImportant rules:\n- Format the email in HTML with appropriate paragraph tags (<p>)\n- Keep the entire email under 100 words\n- Do not include the subject line in the body\n- Do not include any kind of sign-off\n- Make sure the location URL is wrapped in an anchor tag\n- Focus on unique features that match their past preferences\n- Maintain a friendly, professional tone\n- Do not add any empty lines or unnecessary line breaks\n- Do not use <br> tags unless absolutely necessary for formatting\n- Keep paragraphs together without extra spacing"
          },
          {
            role: "user",
            content: prompt
          }
        ],
        temperature: 0.66,
        max_tokens: 800
      };

  

      const response = await axios.post(
        this.baseURL,
        requestBody,
        {
          headers: {
            'Authorization': `Bearer ${this.apiKey}`,
            'Content-Type': 'application/json'
          }
        }
      );

 
      
      if (!response.data) {
        console.error('No data in OpenAI response');
        throw new Error('No response data from OpenAI');
      }

      const generatedContent = response.data.choices[0]?.message?.content;
      
      if (!generatedContent) {
        console.error('No content in OpenAI response:', response.data);
        throw new Error('No content generated from OpenAI');
      }

      // The unsubscribe message should be added after the location image in the final email
      return generatedContent;
    } catch (error) {
      console.error('Error generating email content:', {
        name: error.name,
        message: error.message,
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        config: error.config ? {
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers
        } : null
      });

      if (error.response?.data?.error) {
        console.error('OpenAI API Error details:', error.response.data);
        throw new Error(`OpenAI API Error: ${error.response.data.error.message}`);
      }
      throw error;
    }
  }
}

export default new OpenAIService(); 