import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://bdm.purelocations.com.au/api/sf'
  : 'https://bdm.purelocations.com.au/api/sf';

const BATCH_SIZE = 199;
const QUERY_DELAY = 200;

// Enhanced logging
const log = (type, message, data = null) => {
  const timestamp = new Date().toISOString();
  // console.log(`[${timestamp}] [SF-${type}] ${message}`);
  if (data) {
    // console.log(JSON.stringify(data, null, 2));
  }
};

// Error logging
const logError = (type, error, context = {}) => {
  const timestamp = new Date().toISOString();
  console.error(`[${timestamp}] [SF-ERROR] [${type}]`, {
    message: error.message,
    response: error.response?.data,
    status: error.response?.status,
    context,
    stack: error.stack
  });
};

class SalesforceService {
  constructor() {
    this.accessToken = null;
    this.instanceUrl = null;
    this.retryCount = 0;
    this.maxRetries = 3;
    this.retryDelay = 1000;
  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async authenticate() {
    try {
      log('AUTH', 'Initiating authentication');
      const response = await axios.post(`${API_BASE_URL}/auth`);

      log('AUTH', 'Authentication successful', {
        hasAccessToken: !!response.data.access_token,
        instanceUrl: response.data.instance_url
      });

      this.accessToken = response.data.access_token;
      this.instanceUrl = response.data.instance_url;
      return response.data;
    } catch (error) {
      logError('AUTH', error);
      throw new Error('Failed to authenticate with Salesforce. Please check your credentials.');
    }
  }

  async getAllAccountIds() {
    try {
      log('QUERY', 'Starting to fetch all account IDs');
      let allIds = [];
      let done = false;
      let nextRecordsUrl = null;
      let batchNumber = 0;

      const initialQuery = `
        SELECT 
          Id,
          Name,
          RecordTypeId
        FROM Account 
        WHERE RecordTypeId IN ('0125g0000004aLaAAI', '0125g0000004aLfAAI')
        ORDER BY Name ASC NULLS LAST
      `;

      while (!done) {
        batchNumber++;
        log('QUERY', `Fetching account IDs batch #${batchNumber}`);

        try {
          const response = await axios.post(`${API_BASE_URL}/query`, {
            query: batchNumber === 1 ? initialQuery : null,
            nextRecordsUrl
          });

          const records = response.data.records;
          allIds = allIds.concat(records.map(record => record.Id));
          
          done = !response.data.nextRecordsUrl;
          nextRecordsUrl = response.data.nextRecordsUrl;

          log('QUERY', `Batch #${batchNumber} complete`, {
            batchSize: records.length,
            totalIdsSoFar: allIds.length,
            hasMore: !done,
            totalSize: response.data.totalSize,
           
          });

          if (!done) {
            await this.sleep(QUERY_DELAY);
          }
        } catch (error) {
          logError('QUERY', error, {
            context: 'getAllAccountIds',
            batchNumber,
            totalIdsSoFar: allIds.length
          });

          if (allIds.length > 0) {
            log('QUERY', `Returning partial results due to error`, {
              totalIds: allIds.length
            });
            return allIds;
          }
          throw error;
        }
      }

      log('QUERY', 'Completed fetching all account IDs', {
        totalIds: allIds.length
      });

      return allIds;
    } catch (error) {
      logError('QUERY', error, { context: 'getAllAccountIds' });
      throw error;
    }
  }

  async getAccountsByIds(ids) {
    try {
      log('QUERY', 'Fetching accounts by IDs', {
        idCount: ids.length,
        sampleIds: ids.slice(0, 5)
      });

      const query = `
        SELECT 
          Id,
          Name,
          RecordTypeId,
          Industry_Sectors__c,
          Address__c,
          Client_Status__c,
          Phone,
          Website,
          Location_Status__c,
          OwnerId,
          Owner.Name,
          Owner.MediumPhotoUrl,
          Owner.Email,
          AnnualRevenue,
          Client_Bond__c,
          Design__c,
          Client_Cleaning_Fee__c,
          Location_Tier__c,
          IsDeleted,
          SystemModstamp,
          LastModifiedDate,
          (
            SELECT 
              Id,
              Total_Hire_Fees__c,
              CloseDate,
              Name,
              Brand__c,
              Amount,
              Net_Profit__c,
              Gross_Profit__c,
              Revenue__c,
              Type_of_H__c,
              GST__c,
              Total_Hire_Fees_GST__c,
              StageName,
              Type,
              Location_Name__r.Name,
              Location_Name__r.Address__c,
              Location_Name__r.Design__c,
              Location_Name__r.Location_Tier__c,
              Location_Name__r.Website_Feature_Image_URL__c
            FROM Opportunities
            WHERE StageName IN ('Closed Won', 'Closed Lost')
            ORDER BY CloseDate DESC
          ),
          (
            SELECT 
              Id,
              Name,
              Opportunity__r.Name,
              Opportunity__r.Location_Name__c,
              Opportunity__r.Location_Name__r.Name,
              Opportunity__r.Location_Name__r.Website_Feature_Image_URL__c,
              Opportunity__r.Total_Hire_Fees__c,
              Opportunity__r.Start_Date_Time__c,
              Opportunity__r.Date_From__c,
              Opportunity__r.CloseDate,
              Opportunity__r.Type_of_H__c,
              CreatedDate,
              Recce_Stage__c,
              Recce_Start_Date_Time__c
            FROM Potential_Locations__r
            ORDER BY CreatedDate DESC
          )
        FROM Account 
        WHERE Id IN ('${ids.join("','")}')
        ORDER BY Name ASC
      `;

      const response = await axios.post(`${API_BASE_URL}/query`, { query });
      
      // Add debugging for important location
      const importantLocation = response.data.records.find(record => record.Id === '0015g000003WiY4AAK');
      if (ids.includes('0015g000003WiY4AAK')) {
        log('QUERY', 'Important Location Check', {
          locationId: '0015g000003WiY4AAK',
          found: !!importantLocation,
          isDeleted: importantLocation?.IsDeleted,
          lastModified: importantLocation?.LastModifiedDate,
          recordType: importantLocation?.RecordTypeId,
          status: importantLocation?.Location_Status__c
        });
      }

      // Separate and format accounts by RecordTypeId
      const clients = response.data.records
        .filter(record => record.RecordTypeId === '0125g0000004aLaAAI')
        .map(record => ({
          accountId: record.Id,
          clientName: record.Name,
          industry: record.Industry_Sectors__c || 'N/A',
          location: record.Address__c || 'N/A',
          owner: {
            name: record.Owner?.Name || 'N/A',
            photoUrl: record.Owner?.MediumPhotoUrl || '',
            email: record.Owner?.Email || 'N/A'
          },
          opportunities: (record.Opportunities?.records || []).map(opp => ({
            ...opp,
            typeOfHire: opp.Type_of_H__c || 'N/A'
          })),
          annualRevenue: record.AnnualRevenue || 0,
          phone: record.Phone || 'N/A',
          website: record.Website || 'N/A',
          lastSyncedAt: new Date().toISOString()
        }));

      const locations = response.data.records
        .filter(record => record.RecordTypeId === '0125g0000004aLfAAI')
        .map(record => ({
          accountId: record.Id,
          name: record.Name,
          status: record.Location_Status__c,
          address: record.Address__c || 'N/A',
          design: record.Design__c || 'N/A',
          locationTier: record.Location_Tier__c || 'N/A',
          lastSyncedAt: new Date().toISOString(),
          potentialLocations: (record.Potential_Locations__r?.records || []).map(pl => ({
            ...pl,
            typeOfHire: pl.Opportunity__r?.Type_of_H__c || 'N/A'
          }))
        }));
      
      log('QUERY', 'Successfully fetched accounts', {
        requestedIds: ids.length,
        receivedRecords: response.data.records.length,
        clientsCount: clients.length,
        locationsCount: locations.length,
        missingRecords: ids.length - response.data.records.length
      });

      return { clients, locations };
    } catch (error) {
      logError('QUERY', error, {
        context: 'getAccountsByIds',
        idCount: ids.length
      });
      throw error;
    }
  }

  async batchQuery() {
    try {
      this.retryCount = 0;
      log('BATCH', 'Starting batch query process');

      // First get all account IDs
      log('BATCH', 'Fetching all account IDs...');
      const allIds = await this.getAllAccountIds();
      log('BATCH', `Total accounts found: ${allIds.length}`);

      // Split IDs into chunks
      const chunks = [];
      for (let i = 0; i < allIds.length; i += BATCH_SIZE) {
        chunks.push(allIds.slice(i, i + BATCH_SIZE));
      }
      log('BATCH', `Split into ${chunks.length} chunks of ${BATCH_SIZE}`);

      // Process each chunk
      let allClients = [];
      let allLocations = [];
      
      for (let i = 0; i < chunks.length; i++) {
        log('BATCH', `Processing chunk ${i + 1} of ${chunks.length}`);
        
        try {
          const { clients, locations } = await this.getAccountsByIds(chunks[i]);
          allClients = allClients.concat(clients);
          allLocations = allLocations.concat(locations);
          log('BATCH', `Progress: ${allClients.length + allLocations.length}/${allIds.length} accounts processed`);

          // Add a delay between chunks to prevent rate limiting
          if (i < chunks.length - 1) {
            await this.sleep(QUERY_DELAY);
          }
        } catch (error) {
          logError('BATCH', error, {
            context: 'Processing chunk',
            chunkIndex: i,
            chunkSize: chunks[i].length
          });
          
          // Retry logic for chunk processing
          if (this.retryCount < this.maxRetries) {
            this.retryCount++;
            log('BATCH', `Retrying chunk ${i + 1} (Attempt ${this.retryCount} of ${this.maxRetries})`);
            i--; // Retry the same chunk
            await this.sleep(this.retryDelay * this.retryCount);
            continue;
          }

          // If we've already got some records, return those rather than failing completely
          if (allClients.length > 0 || allLocations.length > 0) {
            log('BATCH', `Returning partial results due to error`, {
              processedClients: allClients.length,
              processedLocations: allLocations.length
            });
            return { clients: allClients, locations: allLocations };
          }
          throw error;
        }
      }

      log('BATCH', 'Completed processing all accounts', {
        totalClients: allClients.length,
        totalLocations: allLocations.length
      });

      return { clients: allClients, locations: allLocations };
    } catch (error) {
      logError('BATCH', error, { context: 'batchQuery' });
      throw error;
    }
  }

  async getFinancialYearOpportunities() {
    try {
      log('QUERY', 'Fetching financial year opportunities by month');

      const query = `
        SELECT 
          CALENDAR_MONTH(Start_Date_Time__c) Month,
          COUNT(Id) OpportunityCount,
          SUM(Total_Hire_Fees__c) TotalRevenue,
          SUM(Gross_Profit__c) TotalProfit,
          CALENDAR_YEAR(Start_Date_Time__c) Year
        FROM Opportunity 
        WHERE Start_Date_Time__c >= 2024-07-01T00:00:00Z 
        AND Start_Date_Time__c < 2025-07-01T00:00:00Z
        AND (
          StageName IN ('Closed Won', 'Location Chosen', 'Booking Confirmed', 
                       'Draft Contracts', 'Contract in DS', 'Signed Contract', 
                       'Entry Instructions')
          OR (StageName = 'Enquiry' AND CALENDAR_MONTH(Start_Date_Time__c) = 1 
              AND CALENDAR_YEAR(Start_Date_Time__c) = 2025)
        )
        GROUP BY CALENDAR_MONTH(Start_Date_Time__c), CALENDAR_YEAR(Start_Date_Time__c)
        ORDER BY CALENDAR_YEAR(Start_Date_Time__c), CALENDAR_MONTH(Start_Date_Time__c)
      `;

      log('QUERY', 'Executing query', { query });
      const response = await axios.post(`${API_BASE_URL}/query`, { query });
      
      // Initialize array with zeros for all 12 months (July 2024 to June 2025)
      const monthlyData = Array(12).fill().map((_, index) => {
        // For July-Dec, use 2024. For Jan-June, use 2025
        const year = index < 6 ? 2024 : 2025;
        const month = index < 6 ? index + 7 : index - 5;
        return {
          month,
          monthName: new Date(year, month - 1).toLocaleString('default', { month: 'long' }),
          opportunityCount: 0,
          totalRevenue: 0,
          totalProfit: 0,
          year
        };
      });

      // Process the records
      response.data.records.forEach(record => {
        const month = record.Month;
        const year = record.Year;
        
        // Calculate the index in our array (July = 0, June = 11)
        let monthIndex;
        if (year === 2024 && month >= 7) {
          monthIndex = month - 7;
        } else if (year === 2025 && month <= 6) {
          monthIndex = month + 5;
        } else {
          return; // Skip if outside our financial year
        }

        if (monthIndex >= 0 && monthIndex < 12) {
          monthlyData[monthIndex] = {
            month,
            monthName: new Date(year, month - 1).toLocaleString('default', { month: 'long' }),
            opportunityCount: record.OpportunityCount || 0,
            totalRevenue: record.TotalRevenue || 0,
            totalProfit: record.TotalProfit || 0,
            year
          };
        }
      });

      // Add debug logging for January specifically
      const januaryData = monthlyData.find(m => m.month === 1 && m.year === 2025);
      log('QUERY', 'January 2025 Data', {
        rawData: response.data.records.find(r => r.Month === 1 && r.Year === 2025),
        processedData: januaryData
      });

      return monthlyData;

    } catch (error) {
      logError('QUERY', error, { 
        context: 'getFinancialYearOpportunities',
        error: error.message,
        response: error.response?.data
      });
      throw error;
    }
  }

  // Add test function
  async testFinancialYearQuery() {
    try {
      log('TEST', 'Starting financial year opportunities test');
      
      if (!this.isAuthenticated()) {
        log('TEST', 'Not authenticated, authenticating first...');
        await this.authenticate();
      }

      const results = await this.getFinancialYearOpportunities();
      
      log('TEST', 'Test completed successfully', {
        monthsWithData: results.filter(m => m.opportunityCount > 0).length,
        totalOpportunities: results.reduce((sum, m) => sum + m.opportunityCount, 0),
        monthlyBreakdown: results.map(m => ({
          month: m.monthName,
          opportunities: m.opportunityCount,
          revenue: m.totalRevenue,
          profit: m.totalProfit
        }))
      });

      return results;
    } catch (error) {
      logError('TEST', error, { context: 'testFinancialYearQuery' });
      throw error;
    }
    
  }

  async getOpportunitiesComparison() {
    try {
      log('QUERY', 'Fetching opportunities comparison for current and previous financial years');

      const query = `
        SELECT 
          CALENDAR_MONTH(CloseDate) Month,
          CALENDAR_YEAR(CloseDate) Year,
          StageName,
          COUNT(Id) OpportunityCount
        FROM Opportunity 
        WHERE CloseDate >= 2023-07-01 
        AND CloseDate <= 2025-06-30
        
        GROUP BY CALENDAR_MONTH(CloseDate), CALENDAR_YEAR(CloseDate), StageName
        ORDER BY CALENDAR_YEAR(CloseDate), CALENDAR_MONTH(CloseDate)
      `;

      log('QUERY', 'Executing opportunities comparison query');
      const response = await axios.post(`${API_BASE_URL}/query`, { query });
      
      // Initialize arrays for both financial years (July-June)
      const currentFYData = Array(12).fill(0);
      const previousFYData = Array(12).fill(0);
      const currentFYLostData = Array(12).fill(0);
      const previousFYLostData = Array(12).fill(0);
      let currentFYTotal = 0;
      let previousFYTotal = 0;
      let currentFYLostTotal = 0;
      let previousFYLostTotal = 0;

      // Process the records
      response.data.records.forEach(record => {
        const month = record.Month;
        const year = record.Year;
        const count = record.OpportunityCount || 0;
        const isLost = record.StageName === 'Closed Lost';
        
        // Calculate the index in our array (July = 0, June = 11)
        let monthIndex;
        if (month >= 7) {
          monthIndex = month - 7;
        } else {
          monthIndex = month + 5;
        }

        // Determine which financial year this belongs to
        if ((year === 2024 && month >= 7) || (year === 2025 && month <= 6)) {
          // Current FY (2024-25)
          if (isLost) {
            currentFYLostData[monthIndex] += count;
            currentFYLostTotal += count;
          } else {
            currentFYData[monthIndex] += count;
            currentFYTotal += count;
          }
        } else if ((year === 2023 && month >= 7) || (year === 2024 && month <= 6)) {
          // Previous FY (2023-24)
          if (isLost) {
            previousFYLostData[monthIndex] += count;
            previousFYLostTotal += count;
          } else {
            previousFYData[monthIndex] += count;
            previousFYTotal += count;
          }
        }
      });

      log('QUERY', 'Opportunities comparison processed', {
        currentFYTotal,
        previousFYTotal,
        currentFYLostTotal,
        previousFYLostTotal,
        monthsWithData: response.data.records.length
      });

      return {
        currentYear: currentFYData,
        previousYear: previousFYData,
        currentYearLost: currentFYLostData,
        previousYearLost: previousFYLostData,
        currentYearTotal: currentFYTotal,
        previousYearTotal: previousFYTotal,
        currentFYLostTotal,
        previousFYLostTotal,
        currentFY: 2024,
        previousFY: 2023
      };

    } catch (error) {
      logError('QUERY', error, { 
        context: 'getOpportunitiesComparison',
        error: error.message,
        response: error.response?.data
      });
      throw error;
    }
  }

  async getPipelineOpportunities() {
    try {
      log('QUERY', 'Fetching pipeline opportunities by stage');

      const query = `
        SELECT 
          StageName,
          COUNT(Id) OpportunityCount
        FROM Opportunity 
        WHERE StageName NOT IN ('Closed Won', 'Closed Lost')
        GROUP BY StageName
      `;

      log('QUERY', 'Executing pipeline query');
      const response = await axios.post(`${API_BASE_URL}/query`, { query });

      // Define the correct stage order
      const stageOrder = [
        'Case',
        'Followed Up',
        'Contacted',
        'File Pull',
        'Quoted',
        'Recce',
        'Held',
        'Location Chosen',
        'Booking Confirmed',
        'Draft Contracts',
        'Contract in DS',
        'Signed Contract',
        'Entry Instructions'
      ];

      // Map stages to their order index
      const stageToIndex = Object.fromEntries(
        stageOrder.map((stage, index) => [stage, index])
      );

      const pipelineData = response.data.records
        .map(record => ({
          id: record.StageName,
          label: record.StageName,
          value: record.OpportunityCount,
          orderIndex: stageToIndex[record.StageName] ?? 999, // Put unknown stages at the end
          color: [
            '#FF0000', // Case (Red)
            '#FFB74D', // Followed Up
            '#FFA726', // Contacted
            '#FF9800', // File Pull
            '#FB8C00', // Quoted
            '#F57C00', // Recce
            '#EF6C00', // Held
            '#E65100', // Location Chosen
            '#D84315', // Booking Confirmed
            '#BF360C', // Draft Contracts
            '#C62828', // Contract in DS
            '#B71C1C', // Signed Contract
            '#880E4F'  // Entry Instructions
          ][stageToIndex[record.StageName] ?? 0]
        }))
        .sort((a, b) => a.orderIndex - b.orderIndex); // Sort by the defined order

      log('QUERY', 'Pipeline data processed', {
        totalStages: pipelineData.length,
        totalOpportunities: pipelineData.reduce((sum, item) => sum + item.value, 0),
        stages: pipelineData.map(item => `${item.label}: ${item.value}`)
      });

      return pipelineData;

    } catch (error) {
      logError('QUERY', error, { 
        context: 'getPipelineOpportunities',
        error: error.message,
        response: error.response?.data
      });
      throw error;
    }
  }

  async updateContactPurePromoteFields(contactId, lastContact, lastLocation) {
    try {
      log('UPDATE', 'Updating contact Pure Promote fields', {
        contactId,
        lastContact,
        lastLocation
      });

      // Format the date for Salesforce
      const formattedDate = new Date(lastContact).toISOString();

      // Send the update request with the correct data structure
      const response = await axios.post(`${API_BASE_URL}/update`, {
        contactId,
        lastContact: formattedDate,
        lastLocation
      });
      
      log('UPDATE', 'Successfully updated contact', {
        contactId,
        success: response.data.success,
        lastContact: formattedDate,
        lastLocation
      });

      return response.data;
    } catch (error) {
      logError('UPDATE', error, {
        context: 'updateContactPurePromoteFields',
        contactId,
        lastContact,
        lastLocation
      });
      throw error;
    }
  }

  /**
   * Lookup a Salesforce User ID by email address
   * @param {string} email - The email address of the Salesforce user
   * @returns {Promise<string|null>} - The Salesforce User ID or null if not found
   */
  async getUserIdByEmail(email) {
    try {
      if (!email) {
        log('QUERY', 'No email provided for getUserIdByEmail');
        return null;
      }

      log('QUERY', 'Looking up Salesforce User ID by email', { email });

      // Ensure we're authenticated with Salesforce
      if (!this.accessToken) {
        await this.authenticate();
      }

      // Format email for SOQL, escape single quotes
      const formattedEmail = email.replace(/'/g, "\\'");

      // Create the SOQL query to find the User record
      const query = `
        SELECT Id, Name, Email
        FROM User
        WHERE Email = '${formattedEmail}'
        LIMIT 1
      `;

      const response = await axios.post(`${API_BASE_URL}/query`, 
        { query },
        { 
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data.records && response.data.records.length > 0) {
        const userId = response.data.records[0].Id;
        log('QUERY', 'Successfully found User ID', {
          email,
          userId,
          userName: response.data.records[0].Name
        });
        return userId;
      }

      log('QUERY', 'No User found with the provided email', { email });
      return null;
    } catch (error) {
      logError('QUERY', error, {
        context: 'getUserIdByEmail',
        email
      });
      return null; // Return null instead of throwing to make it easier to handle
    }
  }

  async createPurePromoteRecord(contactId, locationId, sendDatetime, ownerID) {
    try {
      log('CREATE', 'Creating Pure_Promote__c record', {
        contactId,
        locationId,
        sendDatetime,
        ownerID,
        contactIdType: typeof contactId,
      });

      // Validate parameters
      if (!contactId) {
        throw new Error('Contact ID is required');
      }
      
      if (!locationId) {
        throw new Error('Location ID is required');
      }

      // First verify the contact exists in Salesforce
      try {
        const contactQuery = `
          SELECT Id, Name, Email, Contact_Notes__c
          FROM Contact
          WHERE Id = '${contactId}'
          LIMIT 1
        `;
        
        const contactResponse = await axios.post(`${API_BASE_URL}/query`, { query: contactQuery });
        if (!contactResponse.data?.records?.length) {
          throw new Error('Contact not found in Salesforce');
        }
        
        const contactNotes = contactResponse.data.records[0].Contact_Notes__c;
        log('CREATE', 'Retrieved contact for Pure_Promote__c creation', {
          contactId,
          hasNotes: !!contactNotes,
          contactName: contactResponse.data.records[0].Name
        });

        // Format the date for Salesforce
        const formattedDate = new Date(sendDatetime).toISOString();

        // Prepare the request body
        const requestBody = {
          Contact_Sent_To__c: contactId,
          Location_Account_Promoted__c: locationId,
          Send_Datetime__c: formattedDate
        };
        
        // Only include OwnerId if provided
        if (ownerID) {
          requestBody.OwnerId = ownerID;
        }
        
        // Include notes if available
        if (contactNotes) {
          requestBody.Contact_Notes__c = contactNotes;
        }
        
        log('CREATE', 'Request body for Pure_Promote__c creation', requestBody);

        // Track the result of each operation
        let purePromoteResult = null;
        let contactUpdateResult = null;
        let usingFallback = false;

        try {
          // Try the new endpoint first to create Pure_Promote__c object
          log('CREATE', 'Attempting to use new createPurePromote endpoint');
          const response = await axios.post(`${API_BASE_URL}/createPurePromote`, requestBody);
          
          purePromoteResult = response.data;
          
          log('CREATE', 'Successfully created Pure_Promote__c record', {
            contactId,
            locationId,
            recordId: purePromoteResult.id
          });
        } catch (endpointError) {
          // If we get a 404, the endpoint doesn't exist yet
          if (endpointError.response && endpointError.response.status === 404) {
            log('CREATE', 'New endpoint not found (404), will only update contact');
            usingFallback = true;
          } else {
            // For other errors, log and continue to try contact update
            logError('CREATE', endpointError, {
              context: 'Pure_Promote__c creation failed, continuing with contact update',
              status: endpointError.response?.status
            });
          }
        }

        // Now also update the Contact record for backward compatibility
        try {
          log('CREATE', 'Updating Contact Pure Promote fields for backward compatibility');
          
          const updateResponse = await axios.post(`${API_BASE_URL}/update`, {
            contactId,
            lastContact: formattedDate,
            lastLocation: locationId
          });
          
          contactUpdateResult = updateResponse.data;
          
          log('CREATE', 'Successfully updated Contact fields', {
            contactId,
            success: contactUpdateResult.success
          });
        } catch (contactError) {
          logError('CREATE', contactError, {
            context: 'Contact update failed after Pure_Promote__c creation',
            contactId
          });
          
          // Only throw if we also failed to create the Pure_Promote__c record
          if (!purePromoteResult) {
            throw contactError;
          }
        }
        
        // Return a combined result
        return {
          success: purePromoteResult?.success || contactUpdateResult?.success,
          message: purePromoteResult 
            ? 'Pure_Promote__c record created successfully' 
            : 'Contact updated successfully',
          id: purePromoteResult?.id || contactId,
          usingFallback,
          contactNotes,
          purePromoteResult,
          contactUpdateResult
        };
      } catch (contactError) {
        logError('CREATE', contactError, {
          context: 'Failed to verify contact existence',
          contactId
        });
        throw new Error('Contact not found in Salesforce');
      }
    } catch (error) {
      logError('CREATE', error, {
        context: 'createPurePromoteRecord',
        contactId,
        locationId,
        sendDatetime,
        ownerID,
        errorResponse: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText
      });
      throw error;
    }
  }

  isAuthenticated() {
    return !!this.accessToken && !!this.instanceUrl;
  }

  /**
   * Poll for recent Pure Promote records to check for team activity
   * @param {Date} sinceDate - Only get records since this date (ISO string)
   * @returns {Promise<Array>} Array of recent Pure_Promote__c records
   */
  async pollRecentPurePromoteRecords(sinceDate) {
    try {
      if (!sinceDate) {
        // Default to looking back 7 days if no date specified (changed from 24 hours)
        sinceDate = new Date();
        sinceDate.setDate(sinceDate.getDate() - 7);
      }
      
      // Ensure sinceDate is a string in ISO format
      const formattedDate = typeof sinceDate === 'string' ? sinceDate : sinceDate.toISOString();
      
      log('POLL', 'Polling for recent Pure_Promote__c records', {
        sinceDate
      });

      // Try the new API endpoint first (for Pure_Promote__c records)
      try {
        // Format the date properly for Salesforce
        const dateObj = new Date(formattedDate);
        // Format date as YYYY-MM-DDThh:mm:ssZ (no milliseconds)
        const formattedSalesforceDate = dateObj.toISOString().split('.')[0] + 'Z';
        
        log('POLL', 'Sending query to endpoint with formatted date', {
          original: formattedDate,
          formatted: formattedSalesforceDate,
          humanReadable: new Date(formattedSalesforceDate).toLocaleString()
        });
        
        const response = await axios.post(`${API_BASE_URL}/queryPurePromote`, {
          sinceDate: formattedSalesforceDate
        });
        
        if (response.data && response.data.records) {
          log('POLL', 'Found recent Pure_Promote__c records', {
            count: response.data.records.length,
            firstRecordDate: response.data.records.length > 0 
              ? new Date(response.data.records[0].Send_Datetime__c).toLocaleString() 
              : 'No records found'
          });
          return response.data.records;
        }
        
        return [];
      } catch (endpointError) {
        // If we get a 404, the endpoint doesn't exist yet - fall back to querying contacts
        if (endpointError.response && endpointError.response.status === 404) {
          log('POLL', 'Pure_Promote__c query endpoint not found, falling back to contact query');
          
          // Format the date for SOQL - needs to be in the format: YYYY-MM-DDThh:mm:ssZ
          // Ensure it's properly formatted for the SOQL query - datetime literals should NOT be quoted
          const dateObj = new Date(formattedDate);
          // Format date as YYYY-MM-DDThh:mm:ssZ (no milliseconds)
          const soqlDateFormat = dateObj.toISOString().split('.')[0] + 'Z';
          
          log('POLL', 'Formatted date for SOQL query', { 
            original: formattedDate,
            formatted: soqlDateFormat
          });
          
          // Use contact queries as fallback with properly formatted date
          const fallbackQuery = `
            SELECT Id, Pure_Promote_Last_Contact__c, Pure_Promote_Last_Location__c 
            FROM Contact 
            WHERE Pure_Promote_Last_Contact__c >= ${soqlDateFormat}
            ORDER BY Pure_Promote_Last_Contact__c DESC
            LIMIT 100
          `;
          
          log('POLL', 'Executing fallback query', { query: fallbackQuery });
          
          const fallbackResponse = await axios.post(`${API_BASE_URL}/query`, {
            query: fallbackQuery
          });
          
          if (fallbackResponse.data && fallbackResponse.data.records) {
            log('POLL', 'Found recently updated contacts (fallback)', {
              count: fallbackResponse.data.records.length
            });
            
            // Process the contact records to match Pure_Promote__c structure
            return fallbackResponse.data.records.map(contact => {
              // Try to extract location ID from the location string if possible
              let locationAccountId = null;
              const locationString = contact.Pure_Promote_Last_Location__c || '';
              const locationIdMatch = locationString.match(/([a-zA-Z0-9]{15,18})/);
              if (locationIdMatch && locationIdMatch[1]) {
                locationAccountId = locationIdMatch[1];
              }
              
              return {
                Contact_Sent_To__c: contact.Id,
                Send_Datetime__c: contact.Pure_Promote_Last_Contact__c,
                Location_Account_Promoted__c: locationAccountId,
                Location_Name__c: locationString.replace(/\([a-zA-Z0-9]{15,18}\)/g, '').trim(),
                usingFallback: true
              };
            });
          }
          
          return [];
        }
        
        // If it's not a 404, re-throw the error
        throw endpointError;
      }
    } catch (error) {
      logError('POLL', error, {
        context: 'pollRecentPurePromoteRecords',
        sinceDate
      });
      // Return empty array on error rather than throwing
      return [];
    }
  }

  /**
   * Query for Pure Promote records by specific contact IDs or emails
   * @param {Array<string>} contactIdentifiers - Array of Contact IDs or email addresses
   * @param {Date} [sinceDate] - Optional date filter to only get records since this date
   * @returns {Promise<Array>} Array of Pure_Promote__c records
   */
  async queryPurePromoteByContactIds(contactIdentifiers, sinceDate = null) {
    try {
      if (!contactIdentifiers || !Array.isArray(contactIdentifiers) || contactIdentifiers.length === 0) {
        log('QUERY', 'No contact identifiers provided for queryPurePromoteByContactIds');
        return [];
      }
      
      // First, determine if we're dealing with emails or Salesforce IDs
      const isEmailArray = contactIdentifiers.some(id => id.includes('@'));
      
      let salesforceContactIds = contactIdentifiers;
      
      // If we're dealing with emails, query for Contact records first
      if (isEmailArray) {
        log('QUERY', 'Contact identifiers appear to be email addresses, querying contacts first');
        
        // Format emails for SOQL, escape single quotes
        const formattedEmails = contactIdentifiers.map(email => `'${email.replace(/'/g, "\\'")}'`).join(',');
        
        // Query contacts by email addresses
        const contactQuery = `
          SELECT 
            Id, 
            Email, 
            Contact_Notes__c, 
            VIP_Contact__c,
            OwnerId,
            Owner.Name,
            Account.BillingStateCode,
            Account.Industry_Sectors__c
          FROM Contact
          WHERE Email IN (${formattedEmails})
        `;
        
        log('QUERY', 'Querying contacts by email', {
          emailCount: contactIdentifiers.length,
          sampleEmails: contactIdentifiers.slice(0, 3)
        });
        
        try {
          const contactResponse = await axios.post(`${API_BASE_URL}/query`, { query: contactQuery });
          
          if (contactResponse.data && contactResponse.data.records && contactResponse.data.records.length > 0) {
            // Store contact data including notes
            const contactsWithNotes = new Map();
            
            // Map contact IDs to their data
            contactResponse.data.records.forEach(record => {
              contactsWithNotes.set(record.Id, {
                Email: record.Email,
                Contact_Notes__c: record.Contact_Notes__c || null,
                VIP_Contact__c: record.VIP_Contact__c || false,
                ownerId: record.OwnerId || null,
                ownerName: record.Owner?.Name || null,
                billingStateCode: record.Account?.BillingStateCode || null,
                industrySector: record.Account?.Industry_Sectors__c || null
              });
            });
            
            // Still extract just the IDs for the Pure_Promote__c query
            salesforceContactIds = contactResponse.data.records.map(record => record.Id);
            
            log('QUERY', `Found ${salesforceContactIds.length} Salesforce contacts matching the provided emails`);
            
            if (salesforceContactIds.length === 0) {
              log('QUERY', 'No Salesforce contacts found for the provided emails, returning empty array');
              return [];
            }
            
            // After getting the Pure_Promote__c records, we'll merge in the notes
            // Continue with the rest of the function, but we'll later enrich the records
            var contactNotesMap = contactsWithNotes;
          } else {
            log('QUERY', 'No contacts found for the provided email addresses');
            return [];
          }
        } catch (contactError) {
          logError('QUERY', contactError, {
            context: 'Querying contacts by email',
            emailCount: contactIdentifiers.length
          });
          return [];
        }
      }
      
      log('QUERY', `Querying Pure_Promote__c records for ${salesforceContactIds.length} contacts`);
      
      // Format date condition if provided
      let dateCondition = '';
      if (sinceDate) {
        const dateObj = new Date(sinceDate);
        const formattedDate = dateObj.toISOString().split('.')[0] + 'Z';
        dateCondition = ` AND Send_Datetime__c >= ${formattedDate}`;
      }
      
      // Prepare the contact IDs for the IN clause (wrap each ID in single quotes)
      const formattedContactIds = salesforceContactIds.map(id => `'${id}'`).join(',');
      
      // Create the SOQL query to find Pure_Promote__c records for these contacts
      const query = `
        SELECT 
          Id, 
          Contact_Sent_To__c,
          Contact_Sent_To__r.Contact_Notes__c,
          Contact_Sent_To__r.VIP_Contact__c,
          Contact_Sent_To__r.OwnerId,
          Contact_Sent_To__r.Owner.Name,
          Contact_Sent_To__r.Account.BillingStateCode,
          Contact_Sent_To__r.Account.Industry_Sectors__c,
          Location_Account_Promoted__c,
          Send_Datetime__c,
          CreatedById,
          OwnerId,
          Name,
          CreatedDate
        FROM Pure_Promote__c 
        WHERE Contact_Sent_To__c IN (${formattedContactIds})${dateCondition}
        ORDER BY Send_Datetime__c DESC
      `;
      
      log('QUERY', 'Executing query for Pure_Promote__c by contact IDs', {
        contactCount: salesforceContactIds.length,
        sampleContactIds: salesforceContactIds.slice(0, 3),
        hasSinceDateFilter: !!sinceDate
      });
      
      const response = await axios.post(`${API_BASE_URL}/query`, { query });
      
      if (response.data && response.data.records) {
        const records = response.data.records;
        log('QUERY', `Found ${records.length} Pure_Promote__c records for these contacts`, {
          sampleRecords: records.slice(0, 2).map(r => ({
            id: r.Id,
            contactId: r.Contact_Sent_To__c,
            datetime: r.Send_Datetime__c
          }))
        });
        
        // Transform Salesforce records into the format we use in the app
        const vipContacts = records.map(record => {
          const contactId = record.Contact_Sent_To__c;
          // If we have notes directly from a relationship field, use it
          if (record.Contact_Sent_To__r) {
            return {
              ...record,
              Contact_Notes__c: record.Contact_Sent_To__r.Contact_Notes__c,
              VIP_Contact__c: record.Contact_Sent_To__r.VIP_Contact__c || false,
              ownerId: record.Contact_Sent_To__r.OwnerId || null,
              ownerName: record.Contact_Sent_To__r.Owner?.Name || null,
              billingStateCode: record.Contact_Sent_To__r.Account?.BillingStateCode || null,
              industrySector: record.Contact_Sent_To__r.Account?.Industry_Sectors__c || null
            };
          }
          // Otherwise, check the contact notes map we built earlier
          else if (contactNotesMap && contactNotesMap.has(contactId)) {
            return {
              ...record,
              Contact_Notes__c: contactNotesMap.get(contactId).Contact_Notes__c,
              VIP_Contact__c: contactNotesMap.get(contactId).VIP_Contact__c || false,
              ownerId: contactNotesMap.get(contactId).ownerId || null,
              ownerName: contactNotesMap.get(contactId).ownerName || null,
              billingStateCode: contactNotesMap.get(contactId).billingStateCode || null,
              industrySector: contactNotesMap.get(contactId).industrySector || null
            };
          }
          return record;
        });
        
        return vipContacts;
      }
      
      return [];
    } catch (error) {
      logError('QUERY', error, {
        context: 'queryPurePromoteByContactIds',
        contactCount: contactIdentifiers?.length || 0
      });
      // Return empty array on error rather than throwing
      return [];
    }
  }

  /**
   * Update Contact_Notes__c field for a specific contact
   * @param {string} contactId - The Salesforce Contact ID
   * @param {string} notes - The notes content to save
   * @returns {Promise<Object>} Result of the update operation
   */
  async updateContactNotes(contactId, notes) {
    try {
      log('UPDATE', 'Updating contact notes', {
        contactId,
        notesLength: notes ? notes.length : 0
      });

      if (!contactId) {
        throw new Error('Contact ID is required');
      }

      // Send the update request
      const response = await axios.post(`${API_BASE_URL}/update`, {
        contactId,
        Contact_Notes__c: notes
      });
      
      log('UPDATE', 'Successfully updated contact notes', {
        contactId,
        success: response.data.success
      });

      return response.data;
    } catch (error) {
      logError('UPDATE', error, {
        context: 'updateContactNotes',
        contactId
      });
      throw error;
    }
  }

  /**
   * Update VIP_Contact__c field for a specific contact
   * @param {string} contactId - The Salesforce Contact ID
   * @param {boolean} isVip - Whether the contact is a VIP
   * @returns {Promise<Object>} Result of the update operation
   */
  async updateContactVipStatus(contactId, isVip) {
    try {
      log('UPDATE', 'Updating contact VIP status', {
        contactId,
        isVip
      });

      if (!contactId) {
        throw new Error('Contact ID is required');
      }

      // Send the update request
      const response = await axios.post(`${API_BASE_URL}/update`, {
        contactId,
        VIP_Contact__c: isVip
      });
      
      log('UPDATE', 'Successfully updated contact VIP status', {
        contactId,
        isVip,
        success: response.data.success
      });

      return response.data;
    } catch (error) {
      logError('UPDATE', error, {
        context: 'updateContactVipStatus',
        contactId,
        isVip
      });
      throw error;
    }
  }

  /**
   * Fetch all VIP contacts directly from Salesforce
   * @returns {Promise<Array>} Array of VIP contacts
   */
  async getVIPContacts() {
    try {
      log('QUERY', 'Fetching VIP contacts from Salesforce');

      // Ensure we're authenticated with Salesforce
      if (!this.accessToken) {
        await this.authenticate();
      }

      // Query VIP contacts with their most recent Pure_Promote__c records
      const query = `
        SELECT 
          Id,
          AccountId,
          Account.Name,
          Account.BillingStateCode,
          Account.Industry_Sectors__c,
          Contact_Notes__c,
          CreatedById,
          CreatedDate,
          Email,
          FirstName,
          LastName,
          MobilePhone,
          Name,
          no_promote__c,
          OwnerId,
          Owner.Name,
          Owner.Email,
          Phone,
          Pure_Promote_Last_Contact__c,
          Pure_Promote_Last_Location__c,
          VIP_Contact__c
        FROM Contact 
        WHERE VIP_Contact__c = true 
        ORDER BY Name ASC
      `;

      log('QUERY', 'Executing VIP contacts query');
      
      try {
        const response = await axios.post(`${API_BASE_URL}/query`, { query });
        console.log('VIP contacts query response:', response.data);
        
        if (response.data && response.data.records) {
          const records = response.data.records;
          console.log('Processing VIP contacts:', records.length);
          
          // Transform Salesforce records into the format we use in the app
          const vipContacts = records.map(record => {
            return {
              id: record.Id,
              contactId: record.Id,
              email: record.Email,
              name: record.Name,
              firstName: record.FirstName,
              lastName: record.LastName,
              phone: record.Phone,
              mobilePhone: record.MobilePhone,
              accountId: record.AccountId,
              company: record.Account?.Name || '',
              ownerId: record.OwnerId,
              ownerName: record.Owner?.Name,
              ownerEmail: record.Owner?.Email,
              // Set status based on Pure_Promote_Last_Contact__c field
              status: record.Pure_Promote_Last_Contact__c ? 'contacted' : 'pending',
              // Use the fields directly from the Contact record
              Pure_Promote_Last_Contact__c: record.Pure_Promote_Last_Contact__c || null,
              Pure_Promote_Last_Location__c: record.Pure_Promote_Last_Location__c || null,
              Contact_Notes__c: record.Contact_Notes__c,
              VIP_Contact__c: true,
              no_promote__c: record.no_promote__c,
              billingStateCode: record.Account?.BillingStateCode || null,
              industrySector: record.Account?.Industry_Sectors__c || null,
              // Add recentActivity data if there's a recent Pure_Promote record
              recentActivity: record.Pure_Promote_Last_Contact__c ? {
                sentTime: new Date(record.Pure_Promote_Last_Contact__c),
                locationId: record.Pure_Promote_Last_Location__c,
                isRecent: true
              } : null,
              // Add empty arrays/defaults for our app's internal tracking
              emailsSent: [],
              lastUpdated: new Date().toISOString()
            };
          });
          
          // After getting the basic contact info, fetch their Pure_Promote records
          if (vipContacts.length > 0) {
            const contactIds = vipContacts.map(contact => contact.id);
            const purePromoteQuery = `
              SELECT 
                Id,
                Contact_Sent_To__c,
                Location_Account_Promoted__c,
                Send_Datetime__c
              FROM Pure_Promote__c
              WHERE Contact_Sent_To__c IN ('${contactIds.join("','")}')
              ORDER BY Send_Datetime__c DESC
            `;

            const purePromoteResponse = await axios.post(`${API_BASE_URL}/query`, { query: purePromoteQuery });
            
            if (purePromoteResponse.data && purePromoteResponse.data.records) {
              // Group Pure_Promote records by contact
              const purePromoteByContact = {};
              purePromoteResponse.data.records.forEach(record => {
                if (!purePromoteByContact[record.Contact_Sent_To__c]) {
                  purePromoteByContact[record.Contact_Sent_To__c] = [];
                }
                purePromoteByContact[record.Contact_Sent_To__c].push(record);
              });

              // Update contacts with their Pure_Promote records
              vipContacts.forEach(contact => {
                const contactPromotes = purePromoteByContact[contact.id] || [];
                if (contactPromotes.length > 0) {
                  const latestPromote = contactPromotes[0];
                  contact.Pure_Promote_Last_Contact__c = latestPromote.Send_Datetime__c;
                  contact.Pure_Promote_Last_Location__c = latestPromote.Location_Account_Promoted__c;
                  contact.status = 'contacted';
                  contact.recentActivity = {
                    sentTime: new Date(latestPromote.Send_Datetime__c),
                    locationId: latestPromote.Location_Account_Promoted__c,
                    isRecent: true
                  };
                }
              });
            }
          }
          
          console.log('Processed VIP contacts:', vipContacts.length);
          return vipContacts;
        }
        
        console.log('No VIP contacts found in response');
        return [];
      } catch (queryError) {
        console.error('Error executing VIP contacts query:', queryError);
        throw queryError;
      }
    } catch (error) {
      logError('QUERY', error, {
        context: 'getVIPContacts',
        error: error.message,
        response: error.response?.data
      });
      throw error;
    }
  }

  /**
   * Get user details by Salesforce User ID
   * @param {string} userId - The Salesforce User ID
   * @returns {Promise<Object|null>} - The Salesforce User details or null if not found
   */
  async getUserById(userId) {
    try {
      if (!userId) {
        log('QUERY', 'No user ID provided for getUserById');
        return null;
      }

      log('QUERY', 'Looking up Salesforce User by ID', { userId });

      // Ensure we're authenticated with Salesforce
      if (!this.accessToken) {
        await this.authenticate();
      }

      // Format userId for SOQL, escape single quotes
      const formattedUserId = userId.replace(/'/g, "\\'");

      // Create the SOQL query to find the User record
      const query = `
        SELECT Id, Name, Email, MediumPhotoUrl
        FROM User
        WHERE Id = '${formattedUserId}'
        LIMIT 1
      `;

      const response = await axios.post(`${API_BASE_URL}/query`, 
        { query },
        { 
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data.records && response.data.records.length > 0) {
        const userData = response.data.records[0];
        log('QUERY', 'Successfully found User details', {
          userId,
          userName: userData.Name,
          userEmail: userData.Email
        });
        return userData;
      }

      log('QUERY', 'No User found with the provided ID', { userId });
      return null;
    } catch (error) {
      logError('QUERY', error, {
        context: 'getUserById',
        userId
      });
      return null; // Return null instead of throwing to make it easier to handle
    }
  }
}

export default new SalesforceService(); 