import React, { useState, useCallback, useEffect } from 'react';
import { Box, AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Button, FormControl, Select, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, People as PeopleIcon, LocationOn as LocationIcon, Refresh as RefreshIcon, AutoAwesome as SparkleIcon, Campaign as CampaignIcon, AccessTime as AccessTimeIcon, Map as MapIcon } from '@mui/icons-material';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import dataSyncService from '../services/dataSyncService';
import indexedDBService from '../services/indexedDBService';
import { useDateRange } from '../context/DateContext';

const drawerWidth = 240;

const DashboardLayout = ({ onLogout, userRole }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { startDate: contextStartDate, endDate: contextEndDate, updateDates } = useDateRange();
  const [startDate, setStartDate] = useState(() => {
    const savedStartDate = localStorage.getItem('globalStartDate');
    return savedStartDate ? dayjs(savedStartDate) : dayjs().subtract(365, 'day');
  });
  const [endDate, setEndDate] = useState(() => {
    const savedEndDate = localStorage.getItem('globalEndDate');
    return savedEndDate ? dayjs(savedEndDate) : dayjs();
  });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [syncingData, setSyncingData] = useState(() => {
    // Check if there's an ongoing sync
    const syncInProgress = localStorage.getItem('syncInProgress');
    return syncInProgress === 'true';
  });
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const [nextSyncTime, setNextSyncTime] = useState(null);
  const [countdown, setCountdown] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Update context when local dates change
  useEffect(() => {
    updateDates(startDate, endDate);
  }, [startDate, endDate, updateDates]);

  // Save dates to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('globalStartDate', startDate.toISOString());
    localStorage.setItem('globalEndDate', endDate.toISOString());
  }, [startDate, endDate]);

  // Add effect to fetch and update last sync time
  useEffect(() => {
    const fetchLastSyncTime = async () => {
      try {
        const lastSync = await dataSyncService.getLastSyncTime();
        if (lastSync) {
          setLastSyncTime(new Date(lastSync));
          // Calculate next sync time (6 hours from last sync)
          const nextSync = new Date(lastSync + (6 * 60 * 60 * 1000));
          setNextSyncTime(nextSync);
        }
      } catch (error) {
        console.error('Error fetching last sync time:', error);
      }
    };
    fetchLastSyncTime();
  }, []);

  // Add effect for countdown timer and auto-sync
  useEffect(() => {
    if (!nextSyncTime) return;

    const checkAndSync = async () => {
      const now = new Date();
      const diff = nextSyncTime - now;
      
      // Check if sync is already in progress
      const syncInProgress = localStorage.getItem('syncInProgress') === 'true';
      
      if (diff <= 0 && !syncInProgress) {
        // Time to sync
        setCountdown('Syncing...');
        await handleRefreshData();
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      
      setCountdown(`${hours}h ${minutes}m ${seconds}s`);
    };

    const timer = setInterval(checkAndSync, 1000);
    checkAndSync(); // Initial check

    return () => clearInterval(timer);
  }, [nextSyncTime]);

  // Add effect for initial sync check on component mount
  useEffect(() => {
    const checkInitialSync = async () => {
      // Check if there's an ongoing sync
      const syncInProgress = localStorage.getItem('syncInProgress') === 'true';
      if (syncInProgress) {
        setCountdown('Syncing...');
        return;
      }

      const lastSync = await dataSyncService.getLastSyncTime();
      if (!lastSync || (Date.now() - lastSync) > 6 * 60 * 60 * 1000) {
        // If no last sync or it's been more than 6 hours
        await handleRefreshData();
      }
    };
    checkInitialSync();
  }, []);

  // Add effect to clean up sync state if the component unmounts during sync
  useEffect(() => {
    return () => {
      if (syncingData) {
        localStorage.removeItem('syncInProgress');
      }
    };
  }, [syncingData]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRefreshData = async () => {
    // Check if sync is already in progress
    const syncInProgress = localStorage.getItem('syncInProgress') === 'true';
    if (syncInProgress || syncingData) return;

    try {
      // Set sync state
      localStorage.setItem('syncInProgress', 'true');
      setSyncingData(true);
      setCountdown('Syncing...');

      // Clear all IndexedDB stores first
      await Promise.all([
        indexedDBService.clearClients(),
        indexedDBService.clearLocations(),
        indexedDBService.clearPurePromoteContacts(),
        indexedDBService.clearPurePromoteReports()
      ]);

      // Clear any localStorage data related to Pure Promote
      localStorage.removeItem('purePromoteState');
      localStorage.removeItem('purePromoteFilters');
      localStorage.removeItem('purePromoteSelection');

      await dataSyncService.syncData();

      // Update last sync time
      const newLastSync = Date.now();
      setLastSyncTime(new Date(newLastSync));
      setNextSyncTime(new Date(newLastSync + (6 * 60 * 60 * 1000)));

      // Dispatch refresh event
      window.dispatchEvent(new CustomEvent('globalDataRefresh', {
        detail: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      }));

      setCountdown('Sync complete');
      setTimeout(() => {
        const diff = nextSyncTime - Date.now();
        if (diff > 0) {
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          setCountdown(`${hours}h ${minutes}m ${seconds}s`);
        }
      }, 2000);

    } catch (error) {
      console.error('Error refreshing data:', error);
      setCountdown('Sync failed');
    } finally {
      // Clear sync state
      localStorage.removeItem('syncInProgress');
      setSyncingData(false);
    }
  };

  const handleDateChange = useCallback((type, newValue) => {
    if (type === 'start') {
      setStartDate(newValue);
    } else {
      setEndDate(newValue);
    }
    // Update context
    updateDates(
      type === 'start' ? newValue : startDate,
      type === 'end' ? newValue : endDate
    );
    // Save to localStorage
    localStorage.setItem('globalStartDate', type === 'start' ? newValue.toISOString() : startDate.toISOString());
    localStorage.setItem('globalEndDate', type === 'end' ? newValue.toISOString() : endDate.toISOString());
    // Dispatch date change event
    window.dispatchEvent(new CustomEvent('globalDateChange', {
      detail: {
        startDate: type === 'start' ? newValue.toISOString() : startDate.toISOString(),
        endDate: type === 'end' ? newValue.toISOString() : endDate.toISOString()
      }
    }));
  }, [startDate, endDate, updateDates]);

  const handleQuickRangeChange = (e) => {
    const range = e.target.value;
    let newStartDate = dayjs();
    const newEndDate = dayjs();

    switch (range) {
      case '30':
        newStartDate = newEndDate.subtract(30, 'day');
        break;
      case '90':
        newStartDate = newEndDate.subtract(90, 'day');
        break;
      case '180':
        newStartDate = newEndDate.subtract(180, 'day');
        break;
      case '365':
        newStartDate = newEndDate.subtract(365, 'day');
        break;
      case 'all':
        newStartDate = dayjs('1970-01-01');
        break;
      default:
        newStartDate = newEndDate.subtract(365, 'day');
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    updateDates(newStartDate, newEndDate);

    // Save to localStorage
    localStorage.setItem('globalStartDate', newStartDate.toISOString());
    localStorage.setItem('globalEndDate', newEndDate.toISOString());

    window.dispatchEvent(new CustomEvent('globalDateChange', {
      detail: {
        startDate: newStartDate.toISOString(),
        endDate: newEndDate.toISOString()
      }
    }));
  };

  const getMenuItems = () => {
    if (userRole === 'admin') {
      return [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
        { text: 'AI Insights', icon: <SparkleIcon />, path: '/insights' },
        { text: 'Clients', icon: <PeopleIcon />, path: '/clients' },
        { text: 'Locations', icon: <LocationIcon />, path: '/locations' },
        { text: 'Pure Promote', icon: <CampaignIcon />, path: '/pure-promote' },
        { text: 'Marketing', icon: <MapIcon />, path: '/marketing' },
      ];
    } else {
      return [
        { text: 'Clients', icon: <PeopleIcon />, path: '/clients' },
        { text: 'Locations', icon: <LocationIcon />, path: '/locations' },
        { text: 'Pure Promote', icon: <CampaignIcon />, path: '/pure-promote' }
      ];
    }
  };

  const menuItems = getMenuItems();

  const handleNavigation = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const isDateRangeNeeded = () => {
    const path = location.pathname;
    return !path.includes('/clients/') && !path.includes('/locations/'); // Disable on detail pages
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Purelytics
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ p: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => handleDateChange('start', newValue)}
            slotProps={{ 
              textField: { 
                size: 'small', 
                sx: { 
                  mb: 2,
                  opacity: isDateRangeNeeded() ? 1 : 0.5,
                  pointerEvents: isDateRangeNeeded() ? 'auto' : 'none'
                } 
              } 
            }}
            format="DD/MM/YYYY"
            disabled={!isDateRangeNeeded()}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => handleDateChange('end', newValue)}
            slotProps={{ 
              textField: { 
                size: 'small', 
                sx: { 
                  mb: 2,
                  opacity: isDateRangeNeeded() ? 1 : 0.5,
                  pointerEvents: isDateRangeNeeded() ? 'auto' : 'none'
                } 
              } 
            }}
            format="DD/MM/YYYY"
            disabled={!isDateRangeNeeded()}
          />
        </LocalizationProvider>
        
        <FormControl fullWidth size="small" sx={{ 
          mb: 2,
          opacity: isDateRangeNeeded() ? 1 : 0.5,
          pointerEvents: isDateRangeNeeded() ? 'auto' : 'none'
        }}>
          <Select
            defaultValue="365"
            onChange={handleQuickRangeChange}
            disabled={!isDateRangeNeeded()}
          >
            <MenuItem value="30">Last 30 Days</MenuItem>
            <MenuItem value="90">Last 3 Months</MenuItem>
            <MenuItem value="180">Last 6 Months</MenuItem>
            <MenuItem value="365">Last Year</MenuItem>
            <MenuItem value="all">All Time</MenuItem>
          </Select>
        </FormControl>
       
        <Button
          fullWidth
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={handleRefreshData}
          disabled={isRefreshing}
          sx={{ mt: 1 }}
        >
          {isRefreshing ? 'Refreshing...' : 'Refresh All Data'}
        </Button>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem 
            key={item.text}
            onClick={() => handleNavigation(item.path)}
            selected={location.pathname === item.path}
            sx={{ 
              '&:hover': { 
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                cursor: 'pointer'
              },
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              paddingBottom: 1,
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      
      {/* Add Logout Button */}
      <Box sx={{ p: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          color="error"
          onClick={onLogout}
          sx={{
            mt: 2,
            mb: 2,
            borderColor: 'error.main',
            '&:hover': {
              borderColor: 'error.dark',
              backgroundColor: 'error.light',
              color: 'error.dark',
            }
          }}
        >
          Logout
        </Button>
      </Box>
      
      {/* Update the sync status display */}
      <Box sx={{ 
        position: 'absolute', 
        bottom: 0, 
        left: 0, 
        right: 0, 
        p: 2, 
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        bgcolor: 'background.paper'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <AccessTimeIcon fontSize="small" color="action" />
          <Typography variant="body2" color="text.secondary">
            Last Synced: {lastSyncTime ? new Date(lastSyncTime).toLocaleString() : 'Never'}
          </Typography>
        </Box>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            color: countdown === 'Syncing...' ? 'primary.main' : 
                   countdown === 'Sync complete' ? 'success.main' :
                   countdown === 'Sync failed' ? 'error.main' : 
                   'text.secondary'
          }}
        >
          Status: {countdown}
        </Typography>
      </Box>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Purelytics Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '100%',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      
      {/* Add loading overlay */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 2,
          flexDirection: 'column',
          gap: 2
        }}
        open={syncingData}
      >
        <CircularProgress color="inherit" size={60} />
        <Typography variant="h6">
          Syncing Data...
        </Typography>
      </Backdrop>
    </Box>
  );
};

export default DashboardLayout; 