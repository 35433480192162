import React, { useState, useEffect } from 'react';
import { 
  DataGridPro,
  GridToolbarContainer, 
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  GridCsvExportMenuItem
} from '@mui/x-data-grid-pro';
import {
  Box,
  Typography,
  Avatar,
  Alert,
  Snackbar,
  Grid,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Chip,
  Divider,
} from '@mui/material';
import { 
  Warning as WarningIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  OpenInNew as OpenInNewIcon,
  CalendarMonth as CalendarMonthIcon,
  Palette as PaletteIcon,
  EmojiEvents as EmojiEventsIcon,
  ThumbUp as ThumbUpIcon,
  SentimentNeutral as SentimentNeutralIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
} from '@mui/icons-material';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import indexedDBService from '../services/indexedDBService';
import salesforceService from '../services/salesforceService';
import { useNavigate } from 'react-router-dom';
import { 
  CalendarMonth, 
  TrendingUp, 
  EmojiEvents,
  Palette
} from '@mui/icons-material';
import { ResponsiveCalendar } from '@nivo/calendar';
import { ResponsiveFunnel } from '@nivo/funnel';

const designRankColors = {
  0: {
    bg: '#FFD700',
    hover: '#FFC300',
    text: '#000000'
  },
  1: {
    bg: '#C0C0C0',
    hover: '#A6A6A6',
    text: '#000000'
  },
  2: {
    bg: '#CD7F32',
    hover: '#B87333',
    text: '#FFFFFF'
  },
  3: {
    bg: '#1fb7d7',
    hover: '#048ad2',
    text: '#FFFFFF'
  },
  4: {
    bg: '#5cb461',
    hover: '#45a248',
    text: '#FFFFFF'
  }
};

const Dashboard = () => {
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState(() => ({ 
    start: new Date(localStorage.getItem('globalStartDate') || new Date().setFullYear(new Date().getFullYear() - 1)), 
    end: new Date(localStorage.getItem('globalEndDate') || new Date()) 
  }));

  const [summaryData, setSummaryData] = useState({
    totalRevenue: 0,
    grossProfit: 0,
    activeLocations: 0,
    totalBookings: 0,
    winLossRatio: 0,
    totalWon: 0,
    totalLost: 0
  });

  const [accountManagerWorkload, setAccountManagerWorkload] = useState([]);

  const [revenueByIndustry, setRevenueByIndustry] = useState([]);
  const [topClientsByRevenue, setTopClientsByRevenue] = useState([]);
  const [topLocationPerformance, setTopLocationPerformance] = useState([]);
  const [predictedBookings, setPredictedBookings] = useState([]);
  const [popularDesigns, setPopularDesigns] = useState({ monthly: [], yearly: [] });
  const [bookingTrends, setBookingTrends] = useState({
    years: [],
    data: []
  });
  const [monthlyProfits2025, setMonthlyProfits2025] = useState(Array(6).fill(0));
  const [isTestingQuery, setIsTestingQuery] = useState(false);
  const [opportunitiesComparison, setOpportunitiesComparison] = useState({
    currentYear: Array(12).fill(0),
    previousYear: Array(12).fill(0),
    currentYearTotal: 0,
    previousYearTotal: 0
  });
  const [pipelineData, setPipelineData] = useState([]);
  const [financialYearData, setFinancialYearData] = useState({
    bookings: Array(12).fill(0),
    revenue: Array(12).fill(0),
    profit: Array(12).fill(0),
    data: []  // For tooltip data
  });

  useEffect(() => {
    loadDashboardData(dateRange.start, dateRange.end);

    const handleGlobalDateChange = (event) => {
      const { startDate, endDate } = event.detail;
      setDateRange({ start: startDate, end: endDate });
      loadDashboardData(startDate, endDate);
    };

    window.addEventListener('globalDateChange', handleGlobalDateChange);

    return () => {
      window.removeEventListener('globalDateChange', handleGlobalDateChange);
    };
  }, []);

  const processBookingTrends = (clients) => {
    // Create a map to store bookings by year and month
    const bookingsByYearMonth = new Map();
    const years = new Set();

    // Define valid stages
    const validStages = ['Signed Contract', 'Entry Instructions', 'Closed Won'];

    clients.forEach(client => {
      client.opportunities?.forEach(opp => {
        if (opp.CloseDate && validStages.includes(opp.StageName)) {
          const date = new Date(opp.CloseDate);
          const year = date.getFullYear();
          const month = date.getMonth();
          
          years.add(year);
          const key = `${year}-${month}`;
          
          if (!bookingsByYearMonth.has(key)) {
            bookingsByYearMonth.set(key, 0);
          }
          bookingsByYearMonth.set(key, bookingsByYearMonth.get(key) + 1);
        }
      });
    });

    // Convert the map into series data for the chart
    const sortedYears = Array.from(years).sort();
    const seriesData = sortedYears.map(year => {
      const yearData = Array(12).fill(0);
      
      for (let month = 0; month < 12; month++) {
        const key = `${year}-${month}`;
        yearData[month] = bookingsByYearMonth.get(key) || 0;
      }

      return {
        year,
        data: yearData,
        label: year.toString()
      };
    });

    return {
      years: sortedYears,
      data: seriesData
    };
  };

  const calculatePipelineData = (clients) => {
    // console.log('Calculating pipeline data from clients:', clients?.length);
    
    // Define stages in the order we want them to appear
    const stageOrder = [
      'Enquiry',
      'Proposal',
      'Negotiation',
      'Location Chosen',
      'Booking Confirmed',
      'Draft Contracts',
      'Contract in DS',
      'Signed Contract',
      'Entry Instructions'
    ];

    // Initialize counts
    const stageCounts = stageOrder.reduce((acc, stage) => {
      acc[stage] = 0;
      return acc;
    }, {});

    // Count opportunities by stage
    clients.forEach(client => {
      if (!client.opportunities) return;
      
      client.opportunities.forEach(opp => {
        if (opp.StageName && 
            opp.StageName !== 'Closed Won' && 
            opp.StageName !== 'Closed Lost' && 
            stageCounts.hasOwnProperty(opp.StageName)) {
          stageCounts[opp.StageName]++;
        }
      });
    });

    // Create funnel data in correct order
    const colors = [
      '#4169E1', // Enquiry (Cool blue)
      '#6495ED', // Proposal (Cornflower blue) 
      '#1E90FF', // Negotiation (Dodger blue)
      '#00BFFF', // Location Chosen (Deep sky blue)
      '#FFB74D', // Booking Confirmed (Orange)
      '#FF9800', // Draft Contracts (Darker orange)
      '#F57C00', // Contract in DS (Even darker orange)
      '#E65100', // Signed Contract (Deep orange)
      '#BF360C'  // Entry Instructions (Burnt orange/red)
    ];

    const data = stageOrder
      .map((stage, index) => ({
        id: stage,
        value: stageCounts[stage],
        label: `${stage} (${stageCounts[stage]})`,
        color: colors[index]
      }))
      .filter(item => item.value > 0);

    // console.log('Processed pipeline data:', data);
    return data;
  };

  const loadDashboardData = async (startDate, endDate) => {
    try {
      const clients = await indexedDBService.getAllClients();
      
      // Process booking trends
      const trends = processBookingTrends(clients);
      setBookingTrends(trends);

      // Process opportunities comparison using new Salesforce query
      const opportunitiesData = await salesforceService.getOpportunitiesComparison();
      setOpportunitiesComparison(opportunitiesData);

      // Get pipeline data directly from Salesforce
      const pipeline = await salesforceService.getPipelineOpportunities();
      setPipelineData(pipeline);

      // Convert dates to timestamps for comparison
      const startTimestamp = new Date(startDate).getTime();
      const endTimestamp = new Date(endDate).getTime();
      
      // Calculate summary data
      let totalRevenue = 0;
      let grossProfit = 0;
      let totalBookings = 0;
      let totalWon = 0;
      let totalLost = 0;
      const activeLocationsSet = new Set();
      const industryData = new Map();
      const clientRevenue = new Map();
      const clientProfit = new Map();
      const locationPerformance = new Map();
      const accountManagerData = new Map();

      clients.forEach(client => {
        const ownerName = client.owner?.name || 'Unassigned';
        const ownerEmail = client.owner?.email || 'N/A';
        const ownerPhoto = client.owner?.photoUrl || '';

        if (!accountManagerData.has(ownerName)) {
          accountManagerData.set(ownerName, {
            name: ownerName,
            email: ownerEmail,
            photoUrl: ownerPhoto,
            opportunities: 0,
            activeClients: new Set(),
            totalRevenue: 0
          });
        }

        client.opportunities?.forEach(opp => {
          if (!opp.CloseDate) return;

          const oppTimestamp = new Date(opp.CloseDate).getTime();
          
          if (oppTimestamp >= startTimestamp && oppTimestamp <= endTimestamp) {
            const managerData = accountManagerData.get(ownerName);
            managerData.opportunities++;
            managerData.activeClients.add(client.clientName);
            managerData.totalRevenue += Number(opp.Total_Hire_Fees__c) || 0;

            if (opp.StageName === 'Closed Won') {
              totalWon++;
              const revenue = Number(opp.Total_Hire_Fees__c) || 0;
              const profit = Number(opp.Gross_Profit__c) || 0;
              
              totalRevenue += revenue;
              grossProfit += profit;
              totalBookings++;

              // Process industry data
              const industry = client.Industry || 'Other';
              if (!industryData.has(industry)) {
                industryData.set(industry, { revenue: 0, profit: 0 });
              }
              const industryStats = industryData.get(industry);
              industryStats.revenue += revenue;
              industryStats.profit += profit;

              // Process client revenue data
              const clientName = client.clientName || 'Unknown Client';
              clientRevenue.set(clientName, (clientRevenue.get(clientName) || 0) + revenue);
              clientProfit.set(clientName, (clientProfit.get(clientName) || 0) + profit);

              // Process location data if available
              if (opp.Location_Name__r?.Name) {
                const locationName = opp.Location_Name__r.Name;
                if (!locationPerformance.has(locationName)) {
                  locationPerformance.set(locationName, { revenue: 0, profit: 0, bookings: 0 });
                }
                const locationStats = locationPerformance.get(locationName);
                locationStats.revenue += revenue;
                locationStats.profit += profit;
                locationStats.bookings++;
                activeLocationsSet.add(locationName);
              }
            } else if (opp.StageName === 'Closed Lost') {
              totalLost++;
            }
          }
        });
      });

      // Process account manager data for the pie chart
      const workloadData = Array.from(accountManagerData.values())
        .map(manager => ({
          id: manager.name,
          label: manager.name,
          value: manager.opportunities,
          activeClients: manager.activeClients.size,
          totalRevenue: manager.totalRevenue,
          email: manager.email,
          photoUrl: manager.photoUrl
        }))
        .filter(manager => manager.value > 0)
        .sort((a, b) => b.value - a.value);

      setAccountManagerWorkload(workloadData);

      // Calculate win/loss ratio
      const winLossRatio = totalWon + totalLost > 0 
        ? (totalWon / (totalWon + totalLost)) * 100 
        : 0;

      // Prepare booking data for the calendar
      const calendarData = [];
      const bookingsByDate = {};

      clients.forEach(client => {
        client.opportunities?.forEach(opp => {
          if (opp.CloseDate && opp.StageName === 'Closed Won') {
            const date = new Date(opp.CloseDate);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Months are 0-indexed
            const day = date.getDate();

            const key = `${year}-${month < 10 ? '0' + month : month}-${day}`;
            bookingsByDate[key] = (bookingsByDate[key] || 0) + 1;
          }
        });
      });

      // Convert bookingsByDate to the format required by Nivo Calendar
      for (const [date, count] of Object.entries(bookingsByDate)) {
        calendarData.push({
          day: date,
          value: count,
        });
      }

      // Update all states with filtered data
      setSummaryData({
        totalRevenue,
        grossProfit,
        activeLocations: activeLocationsSet.size,
        totalBookings,
        winLossRatio,
        totalWon,
        totalLost
      });

      // Update industry data
      setRevenueByIndustry(
        Array.from(industryData.entries())
          .map(([label, data]) => ({
            label: truncateLabel(label),
            fullName: label,
            value: data.revenue,
            profitValue: data.profit,
          }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5)
      );

      // Update client data
      setTopClientsByRevenue(
        Array.from(clientRevenue.entries())
          .map(([name, revenue]) => ({ 
            name: truncateLabel(name),
            fullName: name,
            value: revenue,
            profitValue: clientProfit.get(name) || 0,
          }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 10)
      );

      // Update location performance
      const locationsByRevenue = Array.from(locationPerformance.entries())
        .map(([name, data]) => ({
          name: truncateLabel(name),
          fullName: name,
          revenue: data.revenue,
          profit: data.profit,
          bookings: data.bookings,
        }))
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 10);

      setTopLocationPerformance(locationsByRevenue);

      // Calculate predictions
      setPredictedBookings(calculatePredictedBookings(clients));
      
      // Calculate and set popular designs
      const designData = calculatePopularDesigns(clients);
      setPopularDesigns(designData);
      
    } catch (error) {
      console.error('Error loading dashboard data:', error);
    }
  };

  const calculate2025MonthlyProfits = (clients) => {
    const monthlyProfits = Array(6).fill(0);
    
    clients.forEach(client => {
      client.opportunities?.forEach(opp => {
        if (opp.CloseDate && opp.StageName === 'Closed Won') {
          const date = new Date(opp.CloseDate);
          const year = date.getFullYear();
          const month = date.getMonth();
          
          if (year === 2025 && month <= 5) { // Only first 6 months of 2025
            monthlyProfits[month] += Number(opp.Gross_Profit__c) || 0;
          }
        }
      });
    });
    
    return monthlyProfits;
  };

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const clients = await indexedDBService.getAllClients();
        // Calculate 2025 monthly profits
        const profits2025 = calculate2025MonthlyProfits(clients);
        setMonthlyProfits2025(profits2025);
        
        // ... rest of your existing loadDashboardData logic ...
      } catch (error) {
        console.error('Error loading dashboard data:', error);
      }
    };

    loadDashboardData();
  }, []);

  // Common chart configuration for all bar charts
  const barChartSettings = {
    
    margin: { top: 10, bottom: 50, left: 70, right: 10 },
    slotProps: {
      legend: { hidden: true },
      tooltip: {
        followCursor: true,
        placement: 'top-start',
        componentsProps: {
          popper: {
            sx: {
              '& .MuiTooltip-tooltip': {
                bgcolor: 'transparent',
                p: 0,
                maxWidth: 'none'
              }
            }
          }
        }
      }
    },
    sx: {
      '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
        transform: 'rotate(0deg)',
        transformOrigin: 'top left',
        textAnchor: 'middle',
        dx: '-0.5em',
        dy: '0.5em',
      },
    },
  };

  // Function to truncate text
  const truncateLabel = (label, maxLength = 17) => {
    if (!label) return 'N/A';
    return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
  };

  const tooltipContent = ({ payload }) => {
    if (!payload?.length) return null;
    
    const value = payload[0]?.value;
    const formattedValue = value != null ? Number(value).toLocaleString() : 'N/A';
    
    return (
      <Box sx={{ 
        bgcolor: 'background.paper', 
        p: 1.5,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        minWidth: 150,
        maxWidth: 320
      }}>
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: 500,
            mb: 0.5,
            wordBreak: 'break-word'
          }}
        >
          {payload[0]?.payload?.fullName || 'Unknown'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ${formattedValue}
        </Typography>
      </Box>
    );
  };

  const calculatePredictedBookings = (clients) => {
    const predictions = [];
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    clients.forEach(client => {
      // Group bookings by month and year
      const bookingsByMonthYear = new Map();
      const revenueByMonthYear = new Map();
      const allBookingYears = new Set();
      
      client.opportunities?.forEach(opp => {
        if (opp.CloseDate && opp.StageName === 'Closed Won') {
          const date = new Date(opp.CloseDate);
          const month = date.getMonth();
          const year = date.getFullYear();
          const key = `${month}-${year}`;
          allBookingYears.add(year);
          
          // Track bookings count and revenue
          bookingsByMonthYear.set(key, (bookingsByMonthYear.get(key) || 0) + 1);
          revenueByMonthYear.set(key, (revenueByMonthYear.get(key) || 0) + (Number(opp.Total_Hire_Fees__c) || 0));
        }
      });

      // Check each month for booking patterns
      for (let month = 0; month < 12; month++) {
        const currentYear = new Date().getFullYear();
        
        // Calculate recent booking patterns (last 2 years)
        let recentBookings = 0;
        let recentRevenue = 0;
        for (let year = currentYear - 1; year >= currentYear - 2; year--) {
          const key = `${month}-${year}`;
          if (bookingsByMonthYear.has(key)) {
            recentBookings += bookingsByMonthYear.get(key);
            recentRevenue += revenueByMonthYear.get(key) || 0;
          }
        }

        // Analyze historical patterns (5 years)
        let consecutiveYears = 0;
        let maxConsecutive = 0;
        let totalBookings = 0;
        let totalRevenue = 0;
        let yearlyBookings = [];
        let yearlyRevenue = [];
        
        for (let year = currentYear - 1; year >= currentYear - 5; year--) {
          const key = `${month}-${year}`;
          const bookings = bookingsByMonthYear.get(key) || 0;
          const revenue = revenueByMonthYear.get(key) || 0;
          
          yearlyBookings.push({ year, bookings });
          yearlyRevenue.push({ year, revenue });
          
          if (bookings > 0) {
            consecutiveYears++;
            totalBookings += bookings;
            totalRevenue += revenue;
          } else {
            maxConsecutive = Math.max(maxConsecutive, consecutiveYears);
            consecutiveYears = 0;
          }
        }
        maxConsecutive = Math.max(maxConsecutive, consecutiveYears);

        // Calculate year-over-year growth rates
        const growthRates = [];
        for (let i = 1; i < yearlyBookings.length; i++) {
          const currentYear = yearlyBookings[i];
          const previousYear = yearlyBookings[i - 1];
          if (previousYear.bookings > 0) {
            const growth = (currentYear.bookings - previousYear.bookings) / previousYear.bookings;
            growthRates.push(growth);
          }
        }

        // Calculate average growth rate
        const avgGrowthRate = growthRates.length > 0 
          ? growthRates.reduce((sum, rate) => sum + rate, 0) / growthRates.length 
          : 0;

        // Calculate booking consistency score (0-1)
        const yearsWithData = yearlyBookings.filter(y => y.bookings > 0).length;
        const consistencyScore = yearsWithData / yearlyBookings.length;

        // Calculate seasonal strength (how concentrated bookings are in this month)
        let totalYearBookings = 0;
        let thisMonthBookings = 0;
        allBookingYears.forEach(year => {
          for (let m = 0; m < 12; m++) {
            const key = `${m}-${year}`;
            const bookings = bookingsByMonthYear.get(key) || 0;
            totalYearBookings += bookings;
            if (m === month) {
              thisMonthBookings += bookings;
            }
          }
        });
        const seasonalStrength = totalYearBookings > 0 ? thisMonthBookings / totalYearBookings : 0;

        // Enhanced probability calculation
        let baseProbability = 0;
        
        // Factor 1: Recent booking strength (40% weight)
        if (recentBookings >= 3) {
          baseProbability += 40;
        } else if (recentBookings > 0) {
          baseProbability += (recentBookings / 3) * 40;
        }

        // Factor 2: Historical consistency (30% weight)
        baseProbability += consistencyScore * 30;

        // Factor 3: Seasonal strength (20% weight)
        baseProbability += seasonalStrength * 20;

        // Factor 4: Growth trend (10% weight)
        const growthFactor = avgGrowthRate > 0 ? Math.min(avgGrowthRate * 10, 10) : 0;
        baseProbability += growthFactor;

        // Include in predictions if meets minimum criteria
        if (recentBookings >= 3 || maxConsecutive >= 3 || (consistencyScore > 0.6 && seasonalStrength > 0.2)) {
          predictions.push({
            clientName: client.clientName,
            clientId: client.accountId,
            clientData: client,
            month: month,
            monthName: months[month],
            probability: Math.min(Math.round(baseProbability), 100),
            historicalBookings: totalBookings,
            averageRevenue: totalRevenue / totalBookings,
            consecutiveYears: maxConsecutive,
            recentBookings: recentBookings,
            consistencyScore: Math.round(consistencyScore * 100),
            seasonalStrength: Math.round(seasonalStrength * 100),
            growthRate: Math.round(avgGrowthRate * 100),
            averageBookingValue: Math.round(recentRevenue / recentBookings)
          });
        }
      }
    });

    // Sort by month first, then by probability
    return predictions.sort((a, b) => {
      if (a.month === b.month) {
        return b.probability - a.probability;
      }
      return a.month - b.month;
    });
  };

  const calculatePopularDesigns = (clients) => {
    // Track designs by month and yearly totals
    const designsByMonth = new Array(12).fill().map(() => new Map());
    const yearlyDesigns = new Map();
    
    // Collect historical data
    clients.forEach(client => {
      client.opportunities?.forEach(opp => {
        if (opp.CloseDate && opp.StageName === 'Closed Won' && opp.Location_Name__r?.Design__c) {
          const bookingMonth = new Date(opp.CloseDate).getMonth();
          const designs = opp.Location_Name__r.Design__c.split(';').map(d => d.trim());
          
          designs.forEach(design => {
            // Monthly tracking
            const currentCount = designsByMonth[bookingMonth].get(design) || 0;
            designsByMonth[bookingMonth].set(design, currentCount + 1);
            
            // Yearly tracking
            const yearlyCount = yearlyDesigns.get(design) || 0;
            yearlyDesigns.set(design, yearlyCount + 1);
          });
        }
      });
    });

    // Get top 3 designs for the year
    const topYearlyDesigns = Array.from(yearlyDesigns.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([design, bookings]) => ({
        design,
        bookings
      }));

    // Process monthly data - now showing top 5 for each month
    const monthlyData = Array.from({ length: 12 }, (_, month) => {
      const designCounts = designsByMonth[month];
      const topDesigns = Array.from(designCounts.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5) // Changed from 3 to 5
        .map(([design, count]) => ({
          design,
          bookings: count
        }));

      return {
        month,
        designs: topDesigns
      };
    });

    return {
      monthly: monthlyData,
      yearly: topYearlyDesigns
    };
  };

  const calculateMonthlyEstimatedRevenue = (predictions) => {
    if (!predictions || predictions.length === 0) return '0';
    
    // Calculate total estimated revenue for the month
    const totalEstimatedRevenue = predictions.reduce((total, prediction) => {
      // Calculate expected number of bookings based on historical pattern
      // Use recent bookings as a stronger indicator
      const expectedBookings = Math.max(
        1,  // Minimum 1 booking if client is predicted
        Math.ceil(prediction.recentBookings / 2)  // Average recent bookings per year, rounded up
      );
      
      // Use the most recent average booking value as base
      const baseBookingValue = prediction.averageBookingValue || prediction.averageRevenue;
      
      // Apply growth target and market adjustments
      const estimatedBookingValue = baseBookingValue * 1.1; // 10% growth target
      
      // Calculate total expected revenue for this client
      const clientRevenue = estimatedBookingValue * expectedBookings;
      
      // Apply a more balanced weighting system
      const weightedRevenue = clientRevenue * 
        Math.min(0.95, // Cap at 95% to be slightly conservative
          (prediction.probability / 100) * // Probability weight
          Math.max(0.7, prediction.consistencyScore / 100) * // Consistency weight (minimum 70%)
          Math.max(0.8, 1 + (prediction.growthRate / 200)) // Growth adjustment (minimum 80%)
        );
      
      return total + weightedRevenue;
    }, 0);

    // Apply a final adjustment to align with typical monthly targets
    const finalRevenue = Math.min(
      300000, // Cap at 300K to avoid unrealistic predictions
      Math.max(
        totalEstimatedRevenue,
        totalEstimatedRevenue * (150000 / Math.max(totalEstimatedRevenue, 75000)) // Adjust towards 150K target
      )
    );

    return Math.round(finalRevenue).toLocaleString();
  };

  const handleTestQuery = async () => {
    try {
      setIsTestingQuery(true);
      const results = await salesforceService.testFinancialYearQuery();
      // console.log('Financial Year Query Results:', results);
      
      // The data is already ordered in financial year format from the query
      setFinancialYearData({
        bookings: results.map(m => m.opportunityCount),
        revenue: results.map(m => m.totalRevenue),
        profit: results.map(m => m.totalProfit),
        data: results.map(m => ({ year: 2024 }))
      });
    } catch (error) {
      console.error('Error testing financial year query:', error);
    } finally {
      setIsTestingQuery(false);
    }
  };

  // Initial load of financial year data
  useEffect(() => {
    handleTestQuery();
  }, []);

  const hasBookingInMonth = (prediction, monthToCheck) => {
    const currentYear = new Date().getFullYear();
    const validStages = ['Signed Contract', 'Entry Instructions', 'Closed Won'];

    return prediction.clientData?.opportunities?.some(opp => {
      if (!opp.CloseDate || !validStages.includes(opp.StageName)) return false;
      const oppDate = new Date(opp.CloseDate);
      return oppDate.getMonth() === monthToCheck && 
             oppDate.getFullYear() === currentYear;
    });
  };

  return (
    <Box>      
      <Grid container spacing={3}>
        {/* Monthly Profit Targets vs Actuals */}
        <Grid item xs={12}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden',
            mb: 2
          }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TrendingUp sx={{
                    fontSize: 32,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                  }} />
                  <Box>
                    <Typography variant="h5" fontWeight="600">
                      Financial Year Goal Analysis
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Tracking bookings against monthly targets (July 2024 - June 2025)
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: 400 }}>
                <LineChart
                  series={[
                    {
                      data: financialYearData.profit,
                      label: 'Booking Revenue',
                      color: '#20A336FF',
                      showMark: true,
                      area: true,
                      areaOpacity: 0.3,
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'No data'
                    },
                    {
                      data: [180000, 183000, 165000, 170000, 175000, 125000, 110000, 175000, 190000, 145000, 165000, 169000],
                      label: 'Target Budget',
                      color: '#FFBB00FF',
                      showMark: true,
                      area: true,
                      dot: false,
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'No data'
                    }
                  ]}
                  xAxis={[{
                    data: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                    scaleType: 'point',
                    tickLabelStyle: {
                      fontSize: 12,
                      transform: 'translateY(2px)'
                    },
                    tickSize: 8,
                    tickRotation: 0,
                    gridLines: {
                      color: (index) => index === 6 ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)'
                    }
                  }]}
                  yAxis={[{
                    min: 0,
                    max: 250000,
                    tickNumber: 5,
                    tickLabelStyle: {
                      fontSize: 12
                    },
                    valueFormatter: (value) => value != null ? `$${(value/1000).toFixed(0)}K` : 'No data'
                  }]}
                  sx={{
                    '.MuiLineElement-root': {
                      strokeWidth: 2
                    },
                    '.MuiAreaElement-root': {
                      fillOpacity: 0.2
                    },
                    '.MuiChartsAxis-line': {
                      stroke: 'rgba(0, 0, 0, 0.2)'
                    },
                    '.MuiChartsAxis-tick': {
                      stroke: 'rgba(0, 0, 0, 0.2)'
                    },
                    '.MuiChartsAxis-grid': {
                      stroke: 'rgba(0, 0, 0, 0.1)',
                      strokeDasharray: '3 3'
                    }
                  }}
                  grid={true}
                  disableAxisListener={true}
                  height={400}
                  margin={{ left: 70, right: 30, top: 30, bottom: 30 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'top', horizontal: 'middle' },
                      padding: 0,
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                      markGap: 5,
                      itemGap: 15,
                      labelStyle: {
                        fontSize: 12
                      }
                    },
                    tooltip: {
                      trigger: 'item',
                      followCursor: true,
                      content: ({ active, series }) => {
                        if (!active?.[0]) return null;
                        const monthIndex = active[0].dataIndex;
                        const month = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'][monthIndex];
                        
                        return (
                          <Box
                            sx={{
                              p: 2,
                              bgcolor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              boxShadow: '0 8px 32px rgba(0,0,0,0.08)'
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                              {month}
                            </Typography>
                            {series.map((serie) => {
                              const value = serie.data[monthIndex];
                              return (
                                <Box key={serie.label} sx={{ mb: 0.5 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '100%',
                                        bgcolor: serie.color
                                      }}
                                    />
                                    <Typography variant="body2">
                                      {serie.label}: <strong>${value != null ? value.toLocaleString() : 'No data'}</strong>
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      }
                    }
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Opportunities Year-over-Year Comparison */}
        <Grid item xs={12} md={8}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden',
            mb: 2
          }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TrendingUp sx={{
                    fontSize: 32,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                  }} />
                  <Box>
                    <Typography variant="h5" fontWeight="600">
                      Enquiries Year-over-Year Comparison
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Total enquiries by month: FY{opportunitiesComparison.currentFY}-{(opportunitiesComparison.currentFY + 1).toString().slice(-2)} vs FY{opportunitiesComparison.previousFY}-{(opportunitiesComparison.previousFY + 1).toString().slice(-2)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" color="text.secondary">
                      Total Opportunities
                    </Typography>
                    <Typography variant="h6" fontWeight="600">
                      {opportunitiesComparison.currentYearTotal.toLocaleString()} vs {opportunitiesComparison.previousYearTotal.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: 400 }}>
                <LineChart
                  series={[
                    {
                      data: opportunitiesComparison.currentYear,
                      label: `FY${opportunitiesComparison.currentFY}-${(opportunitiesComparison.currentFY + 1).toString().slice(-2)}`,
                      color: '#20A336FF',
                      showMark: true,
                      area: true,
                      areaOpacity: 0.3,
                      valueFormatter: (value) => value != null ? value.toLocaleString() : 'No data'
                    },
                    {
                      data: opportunitiesComparison.previousYear,
                      label: `FY${opportunitiesComparison.previousFY}-${(opportunitiesComparison.previousFY + 1).toString().slice(-2)}`,
                      color: '#6994DFFF',
                      showMark: true,
                      area: true,
                      areaOpacity: 0.1,
                      valueFormatter: (value) => value != null ? value.toLocaleString() : 'No data'
                    }
                  ]}
                  xAxis={[{
                    data: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                    scaleType: 'point',
                    tickLabelStyle: {
                      fontSize: 12
                    }
                  }]}
                  yAxis={[{
                    min: 0,
                    tickNumber: 5,
                    tickLabelStyle: {
                      fontSize: 12
                    },
                    valueFormatter: (value) => value != null ? value.toLocaleString() : 'No data'
                  }]}
                  sx={{
                    '.MuiLineElement-root': {
                      strokeWidth: 2
                    },
                    '.MuiAreaElement-root': {
                      fillOpacity: 0.1
                    },
                    '.MuiChartsAxis-line': {
                      stroke: 'rgba(0, 0, 0, 0.2)'
                    },
                    '.MuiChartsAxis-tick': {
                      stroke: 'rgba(0, 0, 0, 0.2)'
                    },
                    '.MuiChartsAxis-grid': {
                      stroke: 'rgba(0, 0, 0, 0.1)',
                      strokeDasharray: '3 3'
                    }
                  }}
                  grid={true}
                  disableAxisListener={true}
                  height={400}
                  margin={{ left: 70, right: 30, top: 30, bottom: 30 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'top', horizontal: 'middle' },
                      padding: 0,
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                      markGap: 5,
                      itemGap: 15,
                      labelStyle: {
                        fontSize: 12
                      }
                    },
                    tooltip: {
                      trigger: 'item',
                      followCursor: true,
                      content: ({ active, series }) => {
                        if (!active?.[0]) return null;
                        const monthIndex = active[0].dataIndex;
                        const months = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
                        const month = months[monthIndex];
                        const isSecondHalf = monthIndex >= 6;
                        const displayMonth = `${month} ${isSecondHalf ? opportunitiesComparison.currentFY + 1 : opportunitiesComparison.currentFY}`;
                        
                        return (
                          <Box
                            sx={{
                              p: 2,
                              bgcolor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              boxShadow: '0 8px 32px rgba(0,0,0,0.08)'
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                              {displayMonth}
                            </Typography>
                            {series.map((serie) => {
                              const value = serie.data[monthIndex];
                              const prevValue = monthIndex > 0 ? serie.data[monthIndex - 1] : null;
                              const change = prevValue !== null 
                                ? ((value - prevValue) / prevValue * 100).toFixed(1)
                                : null;
                              
                              return (
                                <Box key={serie.label} sx={{ mb: 0.5 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        bgcolor: serie.color
                                      }}
                                    />
                                    <Typography variant="body2">
                                      {serie.label}: <strong>{value != null ? value.toLocaleString() : 'No data'} opportunities</strong>
                                    </Typography>
                                  </Box>
                                  {change !== null && (
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        ml: 3,
                                        color: change > 0 ? 'success.main' : change < 0 ? 'error.main' : 'text.secondary'
                                      }}
                                    >
                                      {change > 0 ? '↑' : change < 0 ? '↓' : '→'} {Math.abs(change)}% from previous month
                                    </Typography>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      }
                    }
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Pipeline Funnel */}
        <Grid item xs={12} md={4}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden',
            mb: 2,
            height: '97%'
          }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 1
              }}>
                <TrendingUp sx={{
                  fontSize: 32,
                  color: 'primary.main',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                }} />
                <Box>
                  <Typography variant="h5" fontWeight="600">
                    Enquiry Pipeline Status
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Current enquiries by stage
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ height: 400 }}>
                {pipelineData && pipelineData.length > 0 ? (
                  <ResponsiveFunnel
                    data={pipelineData}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    valueFormat=">-.0f"
                    colors={{ datum: 'color' }}
                    
                    borderWidth={20}
                    labelColor="#ffffff"
                    enableLabel={true}
                    currentPartSizeExtension={10}
                    currentBorderWidth={40}
                    motionConfig="gentle"
                    theme={{
                      labels: {
                        text: {
                          fontSize: 12,
                          fontWeight: 600,
                          fill: '#000000'
                        }
                      }
                    }}
                  />
                ) : (
                  <Box 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      color: 'text.secondary'
                    }}
                  >
                    <TrendingUp sx={{ fontSize: 48, opacity: 0.5 }} />
                    <Typography variant="body1">
                      No active opportunities in pipeline
                    </Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Revenue Analysis Cards */}
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} sx={{ height: '400px' }}>
            <Grid item xs={6}>
              <Card sx={{ 
                background: 'radial-gradient(at top center,#aa57bf,#7d20a3)', 
                color: 'white', 
                height: '100%',
                display: 'flex'
              }}>
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: 1,
                  padding: '16px !important'
                }}>
                  <Typography color="white" variant="body2" gutterBottom>
                    Revenue
                  </Typography>
                  <Typography variant="h4" fontWeight="600">
                    ${summaryData.totalRevenue.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={6}>
              <Card sx={{ 
                background: 'radial-gradient(at top center,#fb991f, #f06f04)', 
                color: 'white', 
                height: '100%',
                display: 'flex'
              }}>
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: 1,
                  padding: '16px !important'
                }}>
                  <Typography color="white" variant="body2" gutterBottom>
                    Profit
                  </Typography>
                  <Typography variant="h4" fontWeight="600">
                    ${summaryData.grossProfit.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={6}>
              <Card sx={{ 
                background: 'radial-gradient(at top center, #5cb461, #45a248)', 
                color: 'white', 
                height: '100%',
                display: 'flex'
              }}>
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: 1,
                  padding: '16px !important'
                }}>
                  <Typography color="white" variant="body2" gutterBottom>
                    Locations
                  </Typography>
                  <Typography variant="h4" fontWeight="600">
                    {summaryData.activeLocations.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={6}>
              <Card sx={{ 
                background: 'radial-gradient(at top center, #1fb7d7, #048ad2)', 
                color: 'white', 
                height: '100%',
                display: 'flex'
              }}>
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: 1,
                  padding: '16px !important'
                }}>
                  <Typography color="white" variant="body2" gutterBottom>
                    Bookings
                  </Typography>
                  <Typography variant="h4" fontWeight="600">
                    {summaryData.totalBookings.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Account Manager Workload */}
        <Grid item xs={12} md={4}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden',
            height: '400px'
          }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 0, fontWeight: 500, textAlign: 'center', fontSize: '1rem' }}>
                Account Manager Enquiry Distribution
              </Typography>
              <Box sx={{ height: 350, position: 'relative' }}>
                <PieChart
                  series={[
                    {
                      data: accountManagerWorkload.map((manager, index) => ({
                        ...manager,
                        color: [
                          '#aa57bf',
                          '#fb991f',
                          '#5cb461',
                          '#1fb7d7',
                          '#f06292',
                          '#ba68c8',
                          '#4db6ac',
                          '#7986cb',
                          '#9575cd',
                          '#4dd0e1',
                        ][index % 10]
                      })),
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                      arcLabel: (item) => `${item.value}`,
                      arcLabelMinAngle: 5,
                      innerRadius: 5,
                      paddingAngle: 0,
                      cornerRadius: 0,
                    },
                  ]}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'middle', horizontal: 'right' },
                      padding: 0,
                      itemMarkWidth: 8,
                      itemMarkHeight: 8,
                      markGap: 5,
                      itemGap: 8,
                      labelStyle: {
                        fontSize: 11
                      }
                    },
                    tooltip: {
                      trigger: 'item',
                      followCursor: true,
                      content: ({ datum }) => {
                        if (!datum) return null;
                        return (
                          <Box
                            sx={{
                              p: 2,
                              bgcolor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                              minWidth: 200
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                              {datum.photoUrl ? (
                                <Avatar
                                  src={datum.photoUrl}
                                  alt={datum.label}
                                  sx={{ width: 32, height: 32 }}
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    bgcolor: datum.color
                                  }}
                                >
                                  {datum.label.charAt(0)}
                                </Avatar>
                              )}
                              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                {datum.label}
                              </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                              <Typography variant="body2">
                                Active Opportunities: <strong>{datum.value}</strong>
                              </Typography>
                              <Typography variant="body2">
                                Active Clients: <strong>{datum.activeClients}</strong>
                              </Typography>
                              <Typography variant="body2">
                                Total Revenue: <strong>${datum.totalRevenue.toLocaleString()}</strong>
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                {datum.email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }
                    }
                  }}
                  height={350}
                  margin={{
                    top: 10,
                    bottom: 10,
                    left: 10,
                    right: 140
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Win/Loss Ratio Gauge */}
        <Grid item xs={12} md={4}>
          <Card sx={{ 
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden',
            height: '400px'
          }}>
            <CardContent sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              position: 'relative',
              padding: '16px !important'
            }}>
              <Typography variant="h6" sx={{ mb: 0, fontWeight: 500, textAlign: 'center', fontSize: '1rem'  }}>
                Win/Loss Ratio
              </Typography>
              <Box sx={{ 
                width: '100%', 
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}>
                <Box sx={{
                  width: '200px',
                  height: '200px',
                  borderRadius: '50%',
                  background: `conic-gradient(
                    #4CAF50 ${summaryData.winLossRatio}%,
                    #f44336 ${summaryData.winLossRatio}% 100%
                  )`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '160px',
                    height: '160px',
                    borderRadius: '50%',
                    background: 'white',
                  }
                }}>
                  <Box sx={{ position: 'relative', textAlign: 'center' }}>
                    <Typography variant="h3" sx={{ fontWeight: 700, color: summaryData.winLossRatio >= 50 ? '#4CAF50' : '#f44336' }}>
                      {Math.round(summaryData.winLossRatio)}%
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Win Rate
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                gap: 4, 
                mt: 2,
                justifyContent: 'center',
                width: '100%'
              }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" color="success.main" sx={{ fontWeight: 600 }}>
                    Won Opportunities
                  </Typography>
                  <Typography variant="h6">
                    {summaryData.totalWon}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" color="error.main" sx={{ fontWeight: 600 }}>
                    Lost Opportunities
                  </Typography>
                  <Typography variant="h6">
                    {summaryData.totalLost}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Revenue Analysis Section */}
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="600" sx={{ 
            mt: 4, 
            mb: 2, 
            color: 'text.primary',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            Revenue Analysis
          </Typography>
          
          <Grid container>
            
            

            {/* Top 10 Clients by Revenue */}
            <Grid item xs={12} md={12}>
              <Card sx={{
                background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                overflow: 'hidden',
                height: '500px'
              }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>Top 10 Clients by Revenue & Profit</Typography>
                  <Box sx={{ height: 450 }}>
                    <BarChart
                      dataset={topClientsByRevenue}
                      grid={{ vertical: true, horizontal: true }}
                      yAxis={[{ 
                        scaleType: 'linear',
                        valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      }]}
                      xAxis={[{ 
                        scaleType: 'band', 
                        dataKey: 'name',
                        tickLabelStyle: {
                          angle: -30,
                          textAnchor: 'end',
                          fontSize: 9,
                          dx: '-0.5em',
                          dy: '0.5em',
                        },
                      }]}
                      margin={{ left: 70, right: 30, top: 30, bottom: 100 }}
                      series={[
                        { 
                          dataKey: 'value',
                          label: 'Revenue',
                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                          color: '#aa57bf',
                        },
                        { 
                          dataKey: 'profitValue',
                          label: 'Profit',
                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                          color: '#fb991f',
                        }
                      ]}
                      height={450}
                      layout="vertical"
                      {...barChartSettings}
                      slotProps={{
                        ...barChartSettings.slotProps,
                        legend: {
                          hidden: false,
                          direction: 'row',
                          position: { vertical: 'bottom', horizontal: 'middle' },
                          padding: { top: 20, bottom: 0 },
                        },
                        tooltip: {
                          content: tooltipContent,
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Top Location Performance */}
        <Grid item xs={12}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden'
          }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>Top 10 Locations Performance</Typography>
              <Box sx={{ height: 500 }}>
                <BarChart
                  grid={{ vertical: true, horizontal: true }}
                  dataset={topLocationPerformance}
                  yAxis={[{ 
                    scaleType: 'linear',
                    valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                  }]}
                  xAxis={[{ 
                    scaleType: 'band', 
                    dataKey: 'name',
                    tickLabelStyle: {
                      angle: -45,
                      textAnchor: 'end',
                      fontSize: 12,
                      dx: '-0.5em',
                      dy: '0.5em',
                    },
                  }]}
                  series={[
                    { 
                      dataKey: 'revenue',
                      label: 'Revenue',
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      color: '#aa57bf', // Match revenue card color
                    },
                    { 
                      dataKey: 'profit',
                      label: 'Profit',
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      color: '#fb991f', // Match profit card color
                    }
                  ]}
                  height={500}
                  {...barChartSettings}
                  slotProps={{
                    ...barChartSettings.slotProps,
                    legend: {
                      hidden: false,
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: { top: 20, bottom: 0 },
                    },
                    tooltip: {
                      content: ({ active, series }) => {
                        if (!active?.[0]) return null;
                        const locationIndex = active[0].dataIndex;
                        const locationData = topLocationPerformance[locationIndex];
                        
                        return (
                          <Box sx={{ 
                            bgcolor: 'background.paper', 
                            p: 1.5,
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: 2,
                            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
                          }}>
                            <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
                              {locationData?.fullName || locationData?.name || 'Unknown'}
                            </Typography>
                            {series.map((serie) => {
                              const value = serie.data[locationIndex];
                              return (
                                <Box key={serie.label} sx={{ mb: 0.5 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        bgcolor: serie.color
                                      }}
                                    />
                                    <Typography variant="body2">
                                      {serie.label}: ${value?.toLocaleString() ?? 'N/A'}
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        

        {/* Booking Trends Section */}
        <Grid item xs={12}>
          <Card sx={{
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            borderRadius: 3,
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            overflow: 'hidden'
          }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TrendingUp sx={{
                    fontSize: 32,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                  }} />
                  <Box>
                    <Typography variant="h5" fontWeight="600">
                      Booking Trends Analysis
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Monthly booking patterns across different years
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: 400, mt: 2 }}>
                <LineChart
                  series={bookingTrends.data.map((yearData, index) => ({
                    data: yearData.data,
                    label: yearData.year.toString(),
                    showMark: true,
                    grid: { vertical: true, horizontal: true },
                    area: true,
                    color: [
                      '#aa57bf', // Purple (matches revenue)
                      '#fb991f', // Orange (matches profit)
                      '#5cb461', // Green (matches active locations)
                      '#1fb7d7', // Blue (matches bookings)
                      '#f06292', // Pink
                    ][index % 5],
                    valueFormatter: (value) => `${value} bookings`
                  }))}
                  xAxis={[{
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    scaleType: 'point',
                    tickLabelStyle: {
                      fontSize: 12,
                      transform: 'translateY(2px)'
                    },
                    tickSize: 8,
                    tickRotation: 0,
                    gridLines: {
                      color: (index) => index === 6 ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)'
                    }
                  }]}
                  yAxis={[{
                    min: 0,
                    tickLabelStyle: {
                      fontSize: 12
                    }
                  }]}
                  
                  sx={{
                    '.MuiLineElement-root': {
                      strokeWidth: 2
                    },
                    '.MuiAreaElement-root': {
                      fillOpacity: 0.1
                    },
                    '.MuiChartsAxis-line': {
                      stroke: 'rgba(0, 0, 0, 0.2)'
                    },
                    '.MuiChartsAxis-tick': {
                      stroke: 'rgba(0, 0, 0, 0.2)'
                    },
                    '.MuiChartsAxis-grid': {
                      stroke: 'rgba(0, 0, 0, 0.1)',
                      strokeDasharray: '3 3'
                    }
                  }}
                  height={400}
                  margin={{ left: 70, right: 30, top: 30, bottom: 30 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'top', horizontal: 'middle' },
                      padding: 0,
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                      markGap: 5,
                      itemGap: 15,
                      labelStyle: {
                        fontSize: 12
                      }
                    },
                    tooltip: {
                      trigger: 'axis',
                      followCursor: true,
                      content: ({ active, series }) => {
                        if (!active?.[0]) return null;
                        const monthIndex = active[0].dataIndex;
                        const month = ['January', 'February', 'March', 'April', 'May', 'June', 
                                     'July', 'August', 'September', 'October', 'November', 'December'][monthIndex];
                        
                        return (
                          <Box
                            sx={{
                              p: 2,
                              bgcolor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              boxShadow: '0 8px 32px rgba(0,0,0,0.08)'
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                              {month}
                            </Typography>
                            {series.map((serie, index) => {
                              const value = serie.data[monthIndex];
                              const yearData = bookingTrends.data[index];
                              const prevMonthValue = monthIndex > 0 ? serie.data[monthIndex - 1] : null;
                              const change = prevMonthValue !== null 
                                ? ((value - prevMonthValue) / prevMonthValue * 100).toFixed(1)
                                : null;
                              
                              return (
                                <Box key={serie.label} sx={{ mb: 0.5 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        bgcolor: serie.color
                                      }}
                                    />
                                    <Typography variant="body2">
                                      {yearData.year}: <strong>{value} bookings</strong>
                                    </Typography>
                                  </Box>
                                  {change !== null && (
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        ml: 3,
                                        color: change > 0 ? 'success.main' : change < 0 ? 'error.main' : 'text.secondary'
                                      }}
                                    >
                                      {change > 0 ? '↑' : change < 0 ? '↓' : '→'} {Math.abs(change)}% from previous month
                                    </Typography>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      }
                    }
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Booking Predictions Section */}
        <Grid item xs={12}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden'
          }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CalendarMonth sx={{
                    fontSize: 32,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                  }} />
                  <Box>
                    <Typography variant="h5" fontWeight="600">
                      Predicted Client Bookings by Month
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Based on historical booking patterns and client behavior
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ 
                height: 400, 
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '3px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '3px',
                  '&:hover': {
                    background: '#666'
                  }
                }
              }}>
                {Array.from({ length: 12 }, (_, month) => {
                  const monthPredictions = predictedBookings
                    .filter(pred => pred.month === month)
                    .slice(0, 10);
                    
                  if (monthPredictions.length === 0) return null;
                  
                  return (
                    <Box key={month} sx={{ mb: 3 }}>
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          mb: 1,
                          color: 'black',
                          px: 2,
                          fontWeight: 500,
                          letterSpacing: 1
                        }}
                      >
                        {new Date(2024, month).toLocaleString('default', { month: 'long' })}
                      </Typography>
                      {monthPredictions.map((prediction, index) => (
                        <Box key={index} sx={{ 
                          mb: 1.5,
                          border: '1px solid #e0e0e0',
                          background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
                          borderRadius: 2,
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 2,
                          px: 2,
                          py: 1,
                          boxShadow: '0 8px 32px rgba(0,0,0,0.04)',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            transform: 'translateX(8px)',
                            boxShadow: '0 8px 32px rgba(0,0,0,0.12)'
                          }
                        }}>
                          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                            {hasBookingInMonth(prediction, month) ? (
                              <SentimentVerySatisfiedIcon 
                                sx={{ 
                                  color: '#4CAF50',
                                  fontSize: '24px'
                                }} 
                              />
                            ) : (
                              <SentimentNeutralIcon 
                                sx={{ 
                                  color: '#9e9e9e',
                                  fontSize: '24px'
                                }} 
                              />
                            )}
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                cursor: 'pointer',
                                color: 'primary.main',
                                fontWeight: 500,
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                              onClick={() => navigate(`/clients/${prediction.clientId}`, {
                                state: { client: prediction.clientData }
                              })}
                            >
                              {prediction.clientName}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {prediction.historicalBookings} historical bookings
                            </Typography>
                          </Box>
                          <Box sx={{ 
                            bgcolor: '#4fa954',
                            color: 'white',
                            px: 1.5,
                            py: 0.5,
                            borderRadius: 10,
                            minWidth: 90,
                            textAlign: 'center'
                          }}>
                            <Typography variant="caption" sx={{ fontWeight: 500 }}>
                              {prediction.probability.toFixed(0)}% likely
                            </Typography>
                          </Box>
                          <Typography variant="caption" sx={{ 
                            color: 'text.secondary',
                            minWidth: 80,
                            textAlign: 'right'
                          }}>
                            ~${prediction.averageBookingValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Popular Design Predictions */}
        <Grid item xs={12} md={12}>
          <Card sx={{
            background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            overflow: 'hidden'
          }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Palette sx={{
                    fontSize: 32,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                  }} />
                  <Box>
                    <Typography variant="h5" fontWeight="600">
                      All Time Most Popular Designs
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Analysis of top performing design styles across all periods
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Grid container spacing={3}>
                {/* Top 3 Designs Section */}
                <Grid item xs={12} md={4}>
                  <Box sx={{
                    background: 'linear-gradient(135deg, #f5f7ff 0%, #ffffff 100%)',
                    borderRadius: 2,
                    p: 3,
                    height: '100%',
                    boxShadow: '0 8px 32px rgba(0,0,0,0.08)'
                  }}>
                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                      Top 3 Overall
                    </Typography>
                    
                    {popularDesigns?.yearly?.map((design, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 2,
                          p: 2,
                          borderRadius: 2,
                          background: 'white',
                          border: '1px solid',
                          borderColor: 'divider',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          transform: index === 0 ? 'scale(1.02)' : 'none',
                          boxShadow: index === 0 ? '0 8px 32px rgba(0,0,0,0.08)' : '0 8px 32px rgba(0,0,0,0.04)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateX(8px)',
                            boxShadow: '0 8px 32px rgba(0,0,0,0.12)'
                          }
                        }}
                      >
                        <Box sx={{
                          width: 40,
                          height: 40,
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: index === 0 ? 'linear-gradient(135deg, #FFD700 0%, #FFC300 100%)' :
                                              index === 1 ? 'linear-gradient(135deg, #C0C0C0 0%, #A6A6A6 100%)' :
                                              'linear-gradient(135deg, #CD7F32 0%, #B87333 100%)',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                        }}>
                          <EmojiEvents sx={{ 
                            color: 'white',
                            fontSize: 20
                          }} />
                        </Box>
                        
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="600">
                            {design.design}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {design.bookings.toLocaleString()} total bookings
                          </Typography>
                        </Box>
                        
                        <Typography
                          variant="h6"
                          sx={{
                            color: index === 0 ? '#FFD700' :
                                   index === 1 ? '#A6A6A6' :
                                   '#CD7F32',
                            fontWeight: 600
                          }}
                        >
                          #{index + 1}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                {/* Monthly Trends Section */}
                <Grid item xs={12} md={8}>
                  <Box sx={{
                    background: 'linear-gradient(135deg, #f5f7ff 0%, #ffffff 100%)',
                    borderRadius: 2,
                    p: 3,
                    height: '100%',
                    boxShadow: '0 8px 32px rgba(0,0,0,0.08)'
                  }}>
                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                      Monthly Design Trends
                    </Typography>
                    
                    <Box sx={{
                      maxHeight: 400,
                      overflowY: 'auto',
                      pr: 2,
                      '&::-webkit-scrollbar': {
                        width: '6px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '3px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '3px',
                        '&:hover': {
                          background: '#666',
                        },
                      },
                    }}>
                      {popularDesigns?.monthly?.map(({ month, designs }) => {
                        if (!designs?.length) return null;
                        
                        return (
                          <Box
                            key={month}
                            sx={{
                              mb: 2.5,
                              background: 'white',
                              borderRadius: 2,
                              boxShadow: '0 8px 32px rgba(0,0,0,0.04)',
                              overflow: 'hidden'
                            }}
                          >
                            <Box sx={{
                              p: 2,
                              borderBottom: '1px solid',
                              borderColor: 'divider',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1.5,
                              background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)'
                            }}>
                              <CalendarMonth sx={{
                                color: 'primary.main',
                                opacity: 0.8
                              }} />
                              <Typography variant="subtitle1" fontWeight="600">
                                {new Date(2024, month).toLocaleString('default', { month: 'long' })}
                              </Typography>
                            </Box>

                            <Box sx={{
                              p: 2,
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 1
                            }}>
                              {designs.map((design, index) => (
                                <Chip
                                  key={design.design}
                                  label={
                                    <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1
                                    }}>
                                      <Typography component="span" fontWeight={index === 0 ? 600 : 400}>
                                        {design.design}
                                      </Typography>
                                      <Box
                                        component="span"
                                        sx={{
                                          px: 1,
                                          py: 0.25,
                                          borderRadius: 1,
                                          fontSize: '0.75rem',
                                          background: 'rgba(0,0,0,0.08)',
                                          color: 'inherit'
                                        }}
                                      >
                                        {design.bookings}
                                      </Box>
                                    </Box>
                                  }
                                  sx={{
                                    borderRadius: '8px',
                                    background: index === 0 ? 'linear-gradient(135deg, #FFD700 0%, #FFC300 100%)' :
                                              index === 1 ? 'linear-gradient(135deg, #C0C0C0 0%, #A6A6A6 100%)' :
                                              index === 2 ? 'linear-gradient(135deg, #CD7F32 0%, #B87333 100%)' :
                                              'linear-gradient(135deg, #f0f0f0 0%, #e0e0e0 100%)',
                                    color: index <= 2 ? 'white' : 'inherit',
                                    border: 'none',
                                    boxShadow: '0 8px 32px rgba(0,0,0,0.04)',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                      transform: 'translateY(-2px)',
                                      boxShadow: '0 8px 32px rgba(0,0,0,0.12)'
                                    }
                                  }}
                                />
                              ))}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard; 