import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  CircularProgress,
  Button,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import dayjs from 'dayjs';
import indexedDBService from '../services/indexedDBService';
import { useDateRange } from '../context/DateContext';

const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8',
  '#82CA9D', '#FFC658', '#FF7C43', '#665191', '#FF6B6B'
];

const Marketing = () => {
  const { startDate, endDate } = useDateRange();
  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [typeOfHires, setTypeOfHires] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedTypeOfHires, setSelectedTypeOfHires] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [opportunities, setOpportunities] = useState([]);
  const [locations, setLocations] = useState([]);
  // New state variables for Pure Promote data
  const [purePromoteData, setPurePromoteData] = useState(null);
  const [purePromoteLoading, setPurePromoteLoading] = useState(false);
  const [purePromoteError, setPurePromoteError] = useState(null);

  // Update dashboard when filters change
  const updateDashboard = async (industries, types, start, end) => {
    try {
      console.log('Updating dashboard with:', { 
        industries, 
        types, 
        startDate: start?.format('YYYY-MM-DD'), 
        endDate: end?.format('YYYY-MM-DD') 
      });

      // Ensure we have valid dayjs objects
      const startDate = dayjs(start).startOf('day');
      const endDate = dayjs(end).endOf('day');

      const [filteredMetrics, filteredOpportunities, allLocations] = await Promise.all([
        indexedDBService.getFilteredMetrics({
          industries,
          typeOfHires: types,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }),
        indexedDBService.filterOpportunities({
          industries,
          typeOfHires: types,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }),
        indexedDBService.getAllLocations()
      ]);

      // console.log('Raw filtered opportunities:', filteredOpportunities);

      if (!Array.isArray(filteredOpportunities)) {
        console.error('Filtered opportunities is not an array:', filteredOpportunities);
        return;
      }

      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // !!! CRITICAL WARNING: DO NOT MODIFY OPPORTUNITY STRUCTURE          !!!
      // !!! The PurePromote contact list depends on the complete original   !!!
      // !!! opportunity structure, especially Location_Name__r and          !!!
      // !!! Primary_Contact__r objects. Always preserve these using spread. !!!
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      
      // Map the opportunities to ensure all required fields are present
      const mappedOpportunities = filteredOpportunities
        .map((opp, index) => {
          if (!opp) {
            console.error(`Null opportunity at index ${index}`);
            return null;
          }

          // CRITICAL: Always preserve entire original structure for PurePromote compatibility
          // Any changes here could break the PurePromote contact list functionality!
          const mapped = {
            ...opp, // Keep ALL original fields - DO NOT REMOVE THIS SPREAD
            Id: opp.Id || `missing-id-${index}`,
            clientName: opp.clientName || 'N/A',
            Name: opp.Name || 'N/A',
            typeOfHire: opp.Type_of_H__c || opp.typeOfHire || 'N/A',
            industry: opp.industry || 'N/A',
            Total_Hire_Fees__c: Number(opp.Total_Hire_Fees__c) || 0,
            Gross_Profit__c: Number(opp.Gross_Profit__c) || 0,
            CloseDate: opp.CloseDate || null,
            locationName: opp.Location_Name__r?.Name || 'N/A',
            locationAddress: opp.Location_Name__r?.Address__c || 'N/A'
          };

          return mapped;
        })
        .filter(opp => {
          // Remove opportunities with no revenue and no location
          const hasRevenue = opp?.Total_Hire_Fees__c > 0;
          const hasLocation = opp?.locationName !== 'N/A';
          return hasRevenue && hasLocation;
        });

      // Get the set of location IDs from the filtered opportunities
      const chosenLocationIds = new Set(
        mappedOpportunities
          .map(opp => opp.Location_Name__r?.Id)
          .filter(id => id != null)
      );

   

      // Filter locations to only include those that are chosen in the filtered opportunities
      const filteredLocations = allLocations.filter(location => {
        const isChosen = chosenLocationIds.has(location.accountId);
     
        return isChosen;
      });

     

      setMetrics(filteredMetrics);
      setOpportunities(mappedOpportunities);
      setLocations(filteredLocations);

      console.log('Filtered locations:', {
        totalLocations: allLocations.length,
        filteredCount: filteredLocations.length,
        chosenLocationIds: Array.from(chosenLocationIds)
      });
    } catch (error) {
      console.error('Error updating dashboard:', error);
    }
  };

  // Function to load data based on current filters and date range
  const loadData = async (start, end) => {
    try {
      setLoading(true);
      // console.log('Loading data with date range:', { start, end });

      const [uniqueIndustries, uniqueTypeOfHires, locations] = await Promise.all([
        indexedDBService.getUniqueIndustries(),
        indexedDBService.getUniqueTypeOfHires(),
        indexedDBService.getAllLocations()
      ]);

      console.log('Loaded initial data:', {
        industries: uniqueIndustries,
        typeOfHires: uniqueTypeOfHires,
        locationCount: locations?.length
      });

      setIndustries(uniqueIndustries);
      setTypeOfHires(uniqueTypeOfHires);
      setLocations(locations);
      await updateDashboard(selectedIndustries, selectedTypeOfHires, start, end);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial data load
  useEffect(() => {
    if (startDate && endDate) {
      loadData(startDate, endDate);
    }
  }, [startDate, endDate]);

  // Listen for global date changes
  useEffect(() => {
    const handleGlobalDateChange = (event) => {
      const { startDate: newStartDate, endDate: newEndDate } = event.detail;
      loadData(dayjs(newStartDate), dayjs(newEndDate));
    };

    window.addEventListener('globalDateChange', handleGlobalDateChange);

    return () => {
      window.removeEventListener('globalDateChange', handleGlobalDateChange);
    };
  }, [selectedIndustries, selectedTypeOfHires]);

  // Handle filter changes
  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustries(value);
    updateDashboard(value, selectedTypeOfHires, startDate, endDate);
  };

  const handleTypeOfHireChange = (event) => {
    const value = event.target.value;
    setSelectedTypeOfHires(value);
    updateDashboard(selectedIndustries, value, startDate, endDate);
  };

  // DataGrid columns for opportunities
  const opportunityColumns = [
    { 
      field: 'clientName', 
      headerName: 'Client', 
      flex: 1
    },
    { 
      field: 'Name', 
      headerName: 'Opportunity', 
      flex: 1.5
    },
    { 
      field: 'typeOfHire', 
      headerName: 'Type of Hire', 
      flex: 1
    },
    { 
      field: 'industry', 
      headerName: 'Industry', 
      flex: 1
    },
    {
      field: 'Total_Hire_Fees__c',
      headerName: 'Revenue',
      flex: 1,
      type: 'number',
      valueFormatter: (params) => {
        if (params == null) return '$0.00';
        return new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(params);
      }
    },
    {
      field: 'Gross_Profit__c',
      headerName: 'Profit',
      flex: 1,
      type: 'number',
      valueFormatter: (params) => {
        if (params == null) return '$0.00';
        return new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(params);
      }
    },
    {
      field: 'locationName',
      headerName: 'Location',
      flex: 1.5
    },
    {
      field: 'locationAddress',
      headerName: 'Address',
      flex: 2
    },
    {
      field: 'CloseDate',
      headerName: 'Close Date',
      flex: 1,
      type: 'date',
      valueFormatter: (params) => {
        if (!params) return '';
        return dayjs(params).format('DD/MM/YYYY');
      }
    }
  ];

  // DataGrid columns for locations
  const locationColumns = [
    { 
      field: 'name', 
      headerName: 'Location Name', 
      flex: 1.5
    },
    { 
      field: 'address', 
      headerName: 'Address', 
      flex: 2
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      flex: 1
    },
    { 
      field: 'design', 
      headerName: 'Design', 
      flex: 1
    },
    { 
      field: 'locationTier', 
      headerName: 'Tier', 
      flex: 0.5
    },
    {
      field: 'lastSyncedAt',
      headerName: 'Last Updated',
      flex: 1,
      valueFormatter: (params) => {
        if (!params.value) return 'N/A';
        return dayjs(params.value).format('DD/MM/YYYY HH:mm');
      }
    }
  ];

  // Prepare chart data
  const prepareIndustryChartData = () => {
    if (!metrics?.byIndustry) return [];
    return Object.entries(metrics.byIndustry).map(([industry, data]) => ({
      name: industry,
      revenue: data.revenue,
      profit: data.profit,
      count: data.count
    }));
  };

  const prepareTypeOfHireChartData = () => {
    if (!metrics?.byTypeOfHire) return [];
    return Object.entries(metrics.byTypeOfHire).map(([type, data]) => ({
      name: type,
      value: data.revenue
    }));
  };

  const prepareSuburbChartData = () => {
    if (!opportunities.length) return [];
    
    // Extract suburbs from location names
    const suburbCounts = opportunities.reduce((acc, opp) => {
      const locationParts = opp.locationName.split(',');
      if (locationParts.length > 1) {
        const suburb = locationParts[1].trim();
        acc[suburb] = (acc[suburb] || 0) + 1;
      }
      return acc;
    }, {});

    // Convert to array and sort by count
    return Object.entries(suburbCounts)
      .map(([suburb, count]) => ({
        name: suburb,
        count
      }))
      .sort((a, b) => b.count - a.count);
  };

  // DataGrid columns for Pure Promote emails
  const purePromoteColumns = [
    { 
      field: 'Name', 
      headerName: 'Email ID', 
      flex: 1
    },
    { 
      field: 'contactName', 
      headerName: 'Contact', 
      flex: 1.5
    },
    { 
      field: 'contactEmail', 
      headerName: 'Email', 
      flex: 1.5
    },
    { 
      field: 'locationName', 
      headerName: 'Location Promoted', 
      flex: 1.5
    },
    {
      field: 'Send_Datetime__c',
      headerName: 'Send Date',
      flex: 1,
      valueFormatter: (params) => {
        if (!params) return '';
        try {
          // Use dayjs for more reliable formatting
          return dayjs(params).format('D/MM h:mm a');
        } catch (error) {
          console.error('Error formatting date:', error, params);
          return String(params);
        }
      }
    },
    {
      field: 'RelatedOpportunity', 
      headerName: 'Related Opportunity', 
      flex: 2,
      valueGetter: (params) => {
        // Add defensive checks to ensure params and params.row exist
        if (!params || !params.row) return null;
        
        // Use server-provided RelatedOpportunity if available
        if (params.row.RelatedOpportunity) {
          let opportunityText = params.row.RelatedOpportunity.Name;
          if (params.row.RelatedOpportunity.AdditionalCount > 0) {
            opportunityText += ` (+${params.row.RelatedOpportunity.AdditionalCount} more)`;
          }
          return opportunityText;
        }
        return null;
      },
      renderCell: (params) => {
        // Add defensive check for params
        if (!params) return 'None';
        
        // Get direct reference to RelatedOpportunity property
        const relOpp = params.row?.RelatedOpportunity;
        
        // For debugging
        // console.log("Rendering cell for:", params.row?.Name, "RelatedOpportunity:", relOpp);
        
        if (relOpp && relOpp.Name) {
          // If related opportunity exists, render it as a clickable link
          let displayText = relOpp.Name;
          if (relOpp.AdditionalCount > 0) {
            displayText += ` (+${relOpp.AdditionalCount} more)`;
          }
          
          return (
            <Box 
              sx={{ 
                textDecoration: 'underline', 
                cursor: 'pointer',
                color: 'primary.main'
              }}
              onClick={() => {
                // console.log('Navigate to opportunity:', relOpp.Id);
                // Open Salesforce opportunity record in a new tab
                const salesforceUrl = `https://purelocations.lightning.force.com/lightning/r/Opportunity/${relOpp.Id}/view`;
                window.open(salesforceUrl, '_blank');
              }}
            >
              {displayText}
            </Box>
          );
        }
        
        // If no related opportunity, return "None"
        return 'None';
      }
    },
    {
      field: 'OpportunityStage',
      headerName: 'Opportunity Stage',
      flex: 1.5,
      renderCell: (params) => {
        // Simply access RelatedOpportunity.Stage directly from the row
        if (params?.row?.RelatedOpportunity?.Stage) {
          return params.row.RelatedOpportunity.Stage;
        }
        return 'None';
      }
    }
  ];

  // Data preparation functions for Pure Promote emails
  const prepareEmailTimeSeriesData = () => {
    if (!purePromoteData?.emails) return [];
    
    // Group emails by date
    const emailsByDate = purePromoteData.emails.reduce((acc, email) => {
      const date = dayjs(email.Send_Datetime__c).format('YYYY-MM-DD');
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
    
    // Convert to array format for chart
    return Object.entries(emailsByDate)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
  };

  const prepareEmailsByLocationData = () => {
    if (!purePromoteData?.emails) return [];
    
    // Group emails by location
    const emailsByLocation = purePromoteData.emails.reduce((acc, email) => {
      // Use both approaches to get location name to handle different data structures
      const locationName = email.Location_Account_Promoted__r?.Name || 
                         (email.attributes?.type === 'Pure_Promote__c' ? 'Unknown' : email.locationName) || 
                         'Unknown';
      
      acc[locationName] = (acc[locationName] || 0) + 1;
      return acc;
    }, {});
    
    // Convert to array format for chart and take top 10
    return Object.entries(emailsByLocation)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10);
  };

  // Function to fetch Pure Promote data - wrapped in useCallback
  const fetchPurePromoteData = useCallback(async () => {
    try {
      setPurePromoteLoading(true);
      setPurePromoteError(null);
      
      // console.log('Current opportunities data available for lookup:', opportunities.length);
      
      // Format dates for query
      const formattedStartDate = startDate.format('YYYY-MM-DD');
      const formattedEndDate = endDate.format('YYYY-MM-DD');
      
      const requestBody = {
        startDate: `${formattedStartDate}T00:00:00Z`,
        endDate: `${formattedEndDate}T23:59:59Z`,
        industries: selectedIndustries,
        typeOfHires: selectedTypeOfHires,
        cacheBuster: new Date().getTime() // Add cache buster to prevent cached responses
      };
      
      // console.log('Fetching Pure Promote data with params:', requestBody);
      
      // Use relative URL when on same server
      const apiUrl = '/api/sf/marketingPurePromote';
      // console.log('Using API URL:', apiUrl);
      
      try {
        // Call the server endpoint
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
          credentials: 'include', // Include cookies for authentication if needed
        });
        
        // console.log('Response status:', response.status, response.statusText);
        
        // Check content type to detect HTML response
        const contentType = response.headers.get('content-type');
        // console.log('Response content type:', contentType);
        
        if (contentType && contentType.includes('text/html')) {
          const htmlText = await response.text();
          console.error('Received HTML instead of JSON:', htmlText.substring(0, 200) + '...');
          throw new Error('Server returned HTML instead of JSON. The API endpoint might not exist or is misconfigured.');
        }
        
        if (!response.ok) {
          // Try to parse as JSON, but handle case where it's not JSON
          let errorData;
          const responseText = await response.text();
          
          try {
            errorData = JSON.parse(responseText);
          } catch (e) {
            console.error('Error parsing response as JSON:', responseText.substring(0, 200) + '...');
            throw new Error(`Server returned non-JSON response with status ${response.status}`);
          }
          
          console.error('API error response:', errorData);
          throw new Error(errorData.details || errorData.error || 'Failed to fetch Pure Promote data');
        }
        
        // Safely parse JSON
        let data;
        try {
          const responseText = await response.text();
          data = JSON.parse(responseText);
        } catch (e) {
          console.error('Error parsing successful response as JSON:', e);
          throw new Error('Invalid JSON response from server');
        }
        
        if (!data || !data.records) {
          console.error('Invalid response format:', data);
          throw new Error('No data returned from API');
        }
        
        // console.log('Pure Promote data received:', data.records.length, 'records');
        
        // Log detailed information about the first record to help with debugging
        if (data.records && data.records.length > 0) {
          // console.log('First record structure:', JSON.stringify(data.records[0], null, 2));
          // console.log('Contact relationship available:', !!data.records[0]?.Contact_Sent_To__r);
          // console.log('Location relationship available:', !!data.records[0]?.Location_Account_Promoted__r);
          // console.log('Related opportunity available:', !!data.records[0]?.RelatedOpportunity);
        }
        
        // Process the data directly - no need for additional client-side lookups
        const processedEmails = data.records.map(record => {
          // Create a processed record with flattened structure for the DataGrid
          return {
            id: record.Id || record.Name,
            Name: record.Name,
            Send_Datetime__c: record.Send_Datetime__c,
            // Extract relationship data into direct properties
            contactName: record.Contact_Sent_To__r?.Name || 'N/A',
            contactEmail: record.Contact_Sent_To__r?.Email || 'N/A',
            locationName: record.Location_Account_Promoted__r?.Name || 'N/A',
            // Keep the RelatedOpportunity structure from the server
            RelatedOpportunity: record.RelatedOpportunity,
            AllRelatedOpportunities: record.AllRelatedOpportunities,
            // Store the full original record for reference
            originalRecord: record
          };
        });
        
        // Add specific debug logging for RelatedOpportunity structure
        if (processedEmails.length > 0 && processedEmails[0].RelatedOpportunity) {
          console.log("DETAILED STRUCTURE - First RelatedOpportunity:", 
            JSON.stringify(processedEmails[0].RelatedOpportunity, null, 2));
        }
        
        console.log('Processed emails with opportunity lookups:', 
          processedEmails.filter(email => email.RelatedOpportunity).length, 
          'emails have related opportunities'
        );
        
        // Process data
        const processed = {
          // Use the pre-processed emails with flattened structure
          emails: processedEmails,
          totalEmails: data.records.length,
          uniqueLocations: new Set(data.records
            .filter(r => r && r.Location_Account_Promoted__c)
            .map(r => r.Location_Account_Promoted__c)).size,
          uniqueContacts: new Set(data.records
            .filter(r => r && r.Contact_Sent_To__c)
            .map(r => r.Contact_Sent_To__c)).size,
          // Add new metric for leads generated - count emails with related opportunities
          leadsGenerated: processedEmails.filter(email => email.RelatedOpportunity).length
        };
        
        // console.log('Processed emails structure:', processedEmails[0]);
        
        setPurePromoteData(processed);
      } catch (fetchError) {
        // Handle specific fetch errors like CORS or network issues
        if (fetchError.message.includes('Failed to fetch') || 
            fetchError.message.includes('NetworkError') ||
            fetchError.message.includes('CORS')) {
          console.error('Network or CORS error:', fetchError);
          throw new Error('Unable to connect to the server. This might be due to network issues or CORS restrictions.');
        }
        
        // Re-throw other errors
        throw fetchError;
      }
    } catch (error) {
      console.error('Error fetching Pure Promote data:', error);
      setPurePromoteError(error.message);
    } finally {
      setPurePromoteLoading(false);
    }
  }, [startDate, endDate, selectedIndustries, selectedTypeOfHires]);

  // Update to fetch Pure Promote data when dashboard filters change
  useEffect(() => {
    if (startDate && endDate) {
      fetchPurePromoteData();
    }
  }, [startDate, endDate, fetchPurePromoteData]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: 600 }}>
        Marketing Dashboard
      </Typography>

      {/* Filters */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Industries</InputLabel>
            <Select
              multiple
              value={selectedIndustries}
              onChange={handleIndustryChange}
              input={<OutlinedInput label="Industries" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Type of Hire</InputLabel>
            <Select
              multiple
              value={selectedTypeOfHires}
              onChange={handleTypeOfHireChange}
              input={<OutlinedInput label="Type of Hire" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {typeOfHires.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Metrics Summary */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Opportunities</Typography>
              <Typography variant="h4">
                {metrics?.totalOpportunities.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Revenue</Typography>
              <Typography variant="h4">
                {new Intl.NumberFormat('en-AU', { 
                  style: 'currency', 
                  currency: 'AUD',
                  maximumFractionDigits: 0 
                }).format(metrics?.totalRevenue || 0)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>Revenue by Industry</Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={prepareIndustryChartData()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
                  <YAxis />
                  <Tooltip 
                    formatter={(value) => 
                      new Intl.NumberFormat('en-AU', { 
                        style: 'currency', 
                        currency: 'AUD',
                        maximumFractionDigits: 0 
                      }).format(value)
                    }
                  />
                  <Legend />
                  <Bar dataKey="revenue" fill="#0088FE" name="Revenue" />
                  <Bar dataKey="profit" fill="#00C49F" name="Profit" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>Revenue by Type of Hire</Typography>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={prepareTypeOfHireChartData()}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label={(entry) => entry.name}
                  >
                    {prepareTypeOfHireChartData().map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => 
                      new Intl.NumberFormat('en-AU', { 
                        style: 'currency', 
                        currency: 'AUD',
                        maximumFractionDigits: 0 
                      }).format(value)
                    }
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>Opportunities by Suburb</Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={prepareSuburbChartData()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#8884d8" name="Number of Opportunities" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Opportunities Data Table */}
      <Card>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>Opportunities</Typography>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={opportunities}
              columns={opportunityColumns}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 50, page: 0 },
                },
              }}
              rowCount={opportunities.length}
              getRowId={(row) => row.Id || `opp-${Math.random()}`}
              disableSelectionOnClick
              density="compact"
              sx={{
                '& .MuiDataGrid-cell': {
                  fontSize: '0.875rem',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#f5f5f5',
                  fontWeight: 'bold',
                }
              }}
            />
          </Box>
        </CardContent>
      </Card>

      {/* Pure Promote Email Reporting Section */}
      <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: 600 }}>
        Pure Promote Email Reporting
      </Typography>

      {/* Email Metrics Summary */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Emails Sent</Typography>
              <Typography variant="h4">
                {purePromoteData?.totalEmails || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Unique Locations Promoted</Typography>
              <Typography variant="h4">
                {purePromoteData?.uniqueLocations || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Unique Contacts</Typography>
              <Typography variant="h4">
                {purePromoteData?.uniqueContacts || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Leads Generated</Typography>
              <Typography variant="h4">
                {purePromoteData?.leadsGenerated || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Conversion Rate</Typography>
              <Typography variant="h4">
                {purePromoteData?.totalEmails ? 
                  `${((purePromoteData.leadsGenerated / purePromoteData.totalEmails) * 100).toFixed(1)}%` : 
                  '0%'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Email Charts */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>Emails Sent Over Time</Typography>
              {purePromoteLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={prepareEmailTimeSeriesData()}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#8884d8" name="Emails Sent" />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>Emails by Location</Typography>
              {purePromoteLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={prepareEmailsByLocationData()}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      label={(entry) => entry.name}
                    >
                      {prepareEmailsByLocationData().map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Pure Promote Data Table */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>Pure Promote Emails</Typography>
          {purePromoteLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : purePromoteError ? (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="200px">
              <Typography color="error" gutterBottom>Error loading Pure Promote data:</Typography>
              <Typography variant="body2" color="error" align="center" sx={{ maxWidth: '80%', whiteSpace: 'pre-wrap' }}>
                {purePromoteError}
              </Typography>
              <Box mt={2}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => fetchPurePromoteData()}
                  size="small"
                >
                  Retry
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {console.log('Emails to display:', purePromoteData?.emails?.length, 'records')}
              {console.log('First email in DataGrid:', purePromoteData?.emails?.[0])}
              {console.log('First email RelatedOpportunity property:', purePromoteData?.emails?.[0]?.RelatedOpportunity)}
              {console.log('First few emails RelatedOpportunity check:', 
                purePromoteData?.emails?.slice(0, 5).map(email => ({
                  name: email.Name,
                  hasRelOpp: !!email.RelatedOpportunity,
                  oppName: email.RelatedOpportunity?.Name
                }))
              )}
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={purePromoteData?.emails || []}
                  columns={purePromoteColumns}
                  pageSizeOptions={[10, 25, 50, 100]}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 50, page: 0 },
                    },
                  }}
                  rowCount={purePromoteData?.totalEmails || 0}
                  getRowId={(row) => row.id || row.Name || `email-${Math.random()}`}
                  disableSelectionOnClick
                  density="compact"
                  getRowClassName={(params) => {
                    // Add light green background for rows with related opportunities
                    return params.row?.RelatedOpportunity ? 'related-opportunity-row' : '';
                  }}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      fontSize: '0.875rem',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      backgroundColor: '#f5f5f5',
                      fontWeight: 'bold',
                    },
                    '& .related-opportunity-row': {
                      backgroundColor: '#e8f5e9', // Light green background
                    }
                  }}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
      
    </Box>
  );
};

export default Marketing; 