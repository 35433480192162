import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Button,
  TextField,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableRow,
  TableCell,
  Checkbox,
  Chip,
  Tooltip,
  IconButton,
  LinearProgress,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  GridCsvExportMenuItem,
} from '@mui/x-data-grid-pro';
import { 
  Description as DescriptionIcon,
  Send as SendIcon,
  AutoAwesome as AutoAwesomeIcon,
  OpenInNew as OpenInNewIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import purePromoteService from '../services/purePromoteService';
import openAIService from '../services/openAIService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import indexedDBService from '../services/indexedDBService';
import outlookService from '../services/outlookService';
import salesforceService from '../services/salesforceService';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Editor from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomToolbar = ({ 
  onOwnerChange, 
  currentOwner, 
  owners, 
  onStateChange, 
  currentState, 
  states, 
  onIndustryChange, 
  currentIndustry, 
  industries 
}) => {
  return (
    <GridToolbarContainer sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
      <FormControl size="small" sx={{ minWidth: 120, width: 'auto', m: 0.5 }}>
        <InputLabel>Filter by Owner</InputLabel>
        <Select
          value={currentOwner}
          onChange={onOwnerChange}
          label="Filter by Owner"
          size="small"
        >
          <MenuItem value="all">All Owners</MenuItem>
          {owners.map((owner) => (
            <MenuItem key={owner} value={owner}>
              {owner}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <FormControl size="small" sx={{ minWidth: 100, width: 'auto', m: 0.5 }}>
        <InputLabel>Filter by State</InputLabel>
        <Select
          value={currentState}
          onChange={onStateChange}
          label="Filter by State"
          size="small"
        >
          <MenuItem value="all">All States</MenuItem>
          {states.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <FormControl size="small" sx={{ minWidth: 120, width: 'auto', m: 0.5 }}>
        <InputLabel>Filter by Industry</InputLabel>
        <Select
          value={currentIndustry}
          onChange={onIndustryChange}
          label="Filter by Industry"
          size="small"
        >
          <MenuItem value="all">All Industries</MenuItem>
          {industries.map((industry) => (
            <MenuItem key={industry} value={industry}>
              {industry}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <Box sx={{ flex: 1, m: 0.5, minWidth: 120 }}>
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
};

// TabPanel component for the tabbed interface
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`pure-promote-tabpanel-${index}`}
      aria-labelledby={`pure-promote-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%', pt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const PurePromote = () => {
  // Move all state declarations to the top
  const [promotedLocation, setPromotedLocation] = useState(null);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTestMode, setIsTestMode] = useState(outlookService.getTestMode());
  const [currentOwner, setCurrentOwner] = useState('all');
  const [owners, setOwners] = useState([]);
  const [currentState, setCurrentState] = useState('all');
  const [states, setStates] = useState([]);
  const [currentIndustry, setCurrentIndustry] = useState('all');
  const [industries, setIndustries] = useState([]);
  const [emailData, setEmailData] = useState({
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    body: '',
    selectedContact: null
  });
  const [isGenerating, setIsGenerating] = useState(false);
  const [recentlyContactedIds, setRecentlyContactedIds] = useState({});
  const [pollingActive, setPollingActive] = useState(true);
  const [lastPollTime, setLastPollTime] = useState(() => {
    const date = new Date();
    date.setHours(date.getHours() - 24);
    return date;
  });
  const [locationCache, setLocationCache] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  
  // Add VIP-related state
  const [vipContacts, setVipContacts] = useState([]);
  const [filteredVipContacts, setFilteredVipContacts] = useState([]);
  const [isLoadingVIP, setIsLoadingVIP] = useState(false);
  const [vipError, setVipError] = useState(null);
  const [vipSelectionModel, setVipSelectionModel] = useState([]);
  const [vipOwners, setVipOwners] = useState([]);
  const [vipStates, setVipStates] = useState([]);
  const [vipIndustries, setVipIndustries] = useState([]);

  // Add handleTabChange function
  const handleTabChange = (event, newValue) => {
    // Reset all filters to 'all'
    setCurrentOwner('all');
    setCurrentState('all');
    setCurrentIndustry('all');
    // Clear any selected contacts
    setSelectedContactIds([]);
    setSelectedContact(null);
    // Update the tab
    setCurrentTab(newValue);
  };

  // Refs
  const quillRef = useRef(null);
  const editorRef = useRef(null);
  const pendingLocationFetches = useRef(new Set());
  const pollingIntervalRef = useRef(null);
  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadLocations = async () => {
      try {
        const locations = await indexedDBService.getAllLocations();
        // console.log(`Loaded ${locations.length} locations from IndexedDB`);
        
        // Log a sample location to debug the structure
        if (locations.length > 0) {
          console.log('Sample location structure:', {
            firstLocation: locations[0],
            hasAccountId: !!locations[0].accountId,
            hasName: !!locations[0].name,
            hasImage: !!locations[0].Website_Feature_Image_URL__c,
            hasPricing: !!locations[0].Pure_Location_8_Hrs_Photo_Shoot_Online__c
          });
        }
        
        setAvailableLocations(locations);
      } catch (error) {
        console.error('Error loading locations:', error);
        enqueueSnackbar('Failed to load locations. Please refresh the page.', { variant: 'error' });
      }
    };
    loadLocations();
  }, []);

  // After the loadContacts function definition but before it's called
  
  

  // Call this debug function
  useEffect(() => {
    
  }, []);

  useEffect(() => {
    const loadContacts = async () => {
      console.log('Loading contacts...');
      try {
        // Initialize filtered contacts to an empty array immediately
        setFilteredContacts([]);
        
        // First check if we have report data from Pure Promote report generation
        const reportData = purePromoteService.getReportData();
        
        if (reportData && Object.keys(reportData).length > 0) {
          // Process contacts from report
          console.log('Found report data:', {
            sections: Object.keys(reportData),
            contactCount: Object.values(reportData).reduce((total, section) => total + (section.contacts?.length || 0), 0)
          });
          
          // Use IndexedDB for storage and retrieval
          // Get the current list of contacts from IndexedDB
          let existingContacts = await indexedDBService.getPurePromoteContacts();
          console.log('Retrieved existing contacts from IndexedDB:', existingContacts.length);
          
          // Define verifyPurePromoteDataIntegrity function
          const verifyPurePromoteDataIntegrity = (data) => {
            console.log('Verifying PurePromote data integrity...');
            let validContacts = [];
            
            Object.entries(data).forEach(([ownerName, ownerData]) => {
              // Skip metadata entry
              if (ownerName === '__metadata') return;
              
              if (ownerData.contacts && Array.isArray(ownerData.contacts)) {
                // Process each contact
                ownerData.contacts.forEach(contact => {
                  if (!contact.email) {
                    console.error('FOUND INVALID CONTACT: Missing email', contact);
                    return; // Skip invalid contacts
                  }
                  
                  validContacts.push({
                    ...contact,
                    owner: ownerName || 'Unassigned',
                    ownerEmail: contact.ownerEmail || ownerData.ownerEmail,
                    location: contact.locationName || contact.locations || 'N/A',
                    locationUrl: contact.locationUrl || '',
                    opportunityName: contact.opportunityName || contact.latestOpportunity || 'N/A',
                    name: contact.name || 'N/A',
                    email: contact.email || '',
                    phone: contact.phone || '',
                    mobile: contact.mobile || '',
                    company: contact.company || '',
                    source: contact.source || 'N/A',
                    lastInteraction: contact.Pure_Promote_Last_Contact__c || contact.lastInteraction || null,
                    lastPromoteLocation: contact.Pure_Promote_Last_Location__c || '',
                    contactId: contact.Id || '',
                    Contact_Notes__c: contact.Contact_Notes__c || '',
                    status: 'pending',
                    billingStateCode: contact.billingStateCode || '',
                    industrySector: contact.industrySector || ''
                  });
                });
              }
            });
            
            console.log('PurePromote data verification complete:', {
              totalValidContacts: validContacts.length,
            });
            
            return validContacts;
          };
          
          // Verify the integrity of the contacts - ensure they have all required fields
          const validContacts = verifyPurePromoteDataIntegrity(reportData);
          
          // Check for duplicates and update or add new contacts
          const allContacts = [];
          const uniqueEmails = new Set();
          
          // First add existing contacts if they're not in the report data
          for (const contact of existingContacts) {
            // Skip contacts without an email
            if (!contact.email) continue;
            
            // Skip VIP contacts - they're handled separately
            if (contact.VIP_Contact__c === true) continue;
            
            // Check if this contact is in the report data
            const isInReport = validContacts.some(reportContact => reportContact.email === contact.email);
            
            if (!isInReport && !uniqueEmails.has(contact.email)) {
              allContacts.push(contact);
              uniqueEmails.add(contact.email);
            }
          }
          
          // Now add contacts from the report data
          for (const contact of validContacts) {
            // Skip contacts without an email
            if (!contact.email) continue;
            
            // Skip VIP contacts - they're handled separately
            if (contact.VIP_Contact__c === true) continue;
            
            // Find existing contact by email
            const existingContact = existingContacts.find(c => c.email === contact.email);
            
            if (existingContact) {
              // Merge new data with existing contact data
              const merged = {
                ...existingContact,
                ...contact,
                // Preserve certain fields from the existing contact
                status: existingContact.status || contact.status || 'pending',
                Pure_Promote_Last_Contact__c: existingContact.Pure_Promote_Last_Contact__c || contact.Pure_Promote_Last_Contact__c,
                Pure_Promote_Last_Location__c: existingContact.Pure_Promote_Last_Location__c || contact.Pure_Promote_Last_Location__c,
                Contact_Notes__c: existingContact.Contact_Notes__c || contact.Contact_Notes__c,
                // Use a generated ID if none is present
                id: existingContact.id || contact.id || `${contact.email}-${Date.now()}`,
                // Update tracking info
                lastUpdated: new Date().toISOString(),
                // Preserve email history
                emailsSent: existingContact.emailsSent || []
              };
              
              if (!uniqueEmails.has(merged.email)) {
                allContacts.push(merged);
                uniqueEmails.add(merged.email);
              }
            } else {
              // Add new contact with default values
              const newContact = {
                ...contact,
                status: contact.status || 'pending',
                // Use a generated ID if none is present
                id: contact.id || `${contact.email}-${Date.now()}`,
                // Initialize tracking info
                lastUpdated: new Date().toISOString(),
                // Initialize email history
                emailsSent: []
              };
              
              if (!uniqueEmails.has(newContact.email)) {
                allContacts.push(newContact);
                uniqueEmails.add(newContact.email);
              }
            }
          }
          
          // DEBUG: Log the first few filtered contacts
          console.log('Filtered contacts sample:', allContacts.slice(0, 3).map(c => ({
            name: c.name,
            owner: c.owner,
            state: c.billingStateCode,
            industry: c.industrySector
          })));
          
          // Save to IndexedDB
          try {
            await indexedDBService.savePurePromoteContacts(allContacts);
            // console.log('Successfully saved contacts to IndexedDB');
            
            const uniqueOwners = [...new Set(allContacts.map(contact => contact.owner))].sort();
            
            // Extract unique states and industries
            const uniqueStates = [...new Set(allContacts
              .map(contact => contact.billingStateCode)
              .filter(Boolean))].sort();
            
            const uniqueIndustries = [...new Set(allContacts
              .map(contact => contact.industrySector)
              .filter(Boolean))].sort();
            
            setContacts(allContacts);
            setOwners(uniqueOwners);
            setStates(uniqueStates);
            setIndustries(uniqueIndustries);
            
            // Clear the report data since we've processed it
            purePromoteService.clearReportData();
          } catch (error) {
            console.error('Error saving contacts to IndexedDB:', error);
            // Still set the contacts in state even if saving to IndexedDB fails
            const uniqueOwners = [...new Set(allContacts.map(contact => contact.owner))].sort();
            setContacts(allContacts);
            setOwners(uniqueOwners);
          }
        } else {
          // console.log('No report data found, checking IndexedDB...');
          // If no report data, try loading from IndexedDB
          const savedContacts = await indexedDBService.getPurePromoteContacts();
          
          if (savedContacts && savedContacts.length > 0) {
            // console.log('Found saved contacts in IndexedDB:', savedContacts.length);
            const uniqueOwners = [...new Set(savedContacts.map(contact => contact.owner))].sort();
            
            // Extract unique states and industries
            const uniqueStates = [...new Set(savedContacts
              .map(contact => contact.billingStateCode)
              .filter(Boolean))].sort();
            
            const uniqueIndustries = [...new Set(savedContacts
              .map(contact => contact.industrySector)
              .filter(Boolean))].sort();
            
            setOwners(uniqueOwners);
            setStates(uniqueStates);
            setIndustries(uniqueIndustries);
            setContacts(savedContacts);
          } else {
            // console.log('No saved contacts found');
            setContacts([]);
            setOwners([]);
          }
        }
      } catch (error) {
        console.error('Error loading contacts:', error);
        setContacts([]);
        setOwners([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadContacts();
  }, []);

  const handleEmailChange = (field) => (event) => {
    if (field === 'body') {
      setEmailData(prev => ({
        ...prev,
        body: event
      }));
    } else {
      setEmailData(prev => ({
        ...prev,
        [field]: event.target.value
      }));
    }
  };

  // Add test function
  const getProxiedImageUrl = (url) => {
    if (!url) return '';
    const baseUrl = process.env.NODE_ENV === 'production' 
      ? 'https://bdm.purelocations.com.au' 
      : 'https://bdm.purelocations.com.au';
    return `${baseUrl}/api/image-proxy?url=${encodeURIComponent(url)}`;
  };

  // Update the resizeImage function to ensure it uses the proxy
  const resizeImage = async (imageUrl) => {
    try {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // Calculate new dimensions maintaining aspect ratio
          let width = img.width;
          let height = img.height;
          if (width > 400) {
            height = (height * 400) / width;
            width = 400;
          }
          
          canvas.width = width;
          canvas.height = height;
          
          // Draw and resize image
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to data URL
          resolve(canvas.toDataURL('image/jpeg', 0.8));
        };
        img.onerror = (e) => {
          console.error('Error loading image:', e);
          resolve(imageUrl); // Fallback to original URL if resize fails
        };
        // Always use the proxy URL when loading the image
        const proxiedUrl = getProxiedImageUrl(imageUrl);
        // console.log('Loading image with proxied URL:', proxiedUrl);
        img.src = proxiedUrl;
      });
    } catch (error) {
      console.error('Error resizing image:', error);
      return imageUrl; // Fallback to original URL
    }
  };

  // Add function to temporarily pause polling
  const temporarilyPausePolling = () => {
    // Skip if polling is already inactive
    if (!pollingActive) return;
    
    // Store the current polling state
    const wasActive = pollingActive;
    
    // Pause polling
    setPollingActive(false);
    // console.log('Temporarily pausing polling due to user interaction');
    
    // Resume polling after 5 seconds
    setTimeout(() => {
      if (wasActive) {
        setPollingActive(true);
        // console.log('Resuming polling after user interaction');
      }
    }, 5000);
  };

  const handleContactSelection = async (selectedIds) => {
    temporarilyPausePolling();
    
    if (!selectedIds || selectedIds.length === 0) {
      setSelectedContactIds([]);
      setEmailData({
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        body: '',
        selectedContact: null
      });
      setSelectedContact(null);
      return;
    }

    if (!promotedLocation) {
      enqueueSnackbar('Please select a location to promote first', { variant: 'warning' });
      return;
    }
    
    const selectedId = selectedIds[0];
    // Find contact in either regular contacts or VIP contacts based on current tab
    const selectedContact = currentTab === 0 ? 
      contacts.find(contact => contact.id === selectedId) :
      vipContacts.find(contact => contact.id === selectedId);
    
    if (!selectedContact) {
      console.error('Selected contact not found:', selectedId);
      return;
    }

    setSelectedContactIds([selectedContact.id]);
    setSelectedContact(selectedContact);
    setIsGenerating(true);
    
    const firstName = selectedContact.name?.split(' ')[0] || '';
    const locationName = promotedLocation.name;
    const locationUrl = promotedLocation.Internal_Location_URL__c || '';
    const imageUrl = promotedLocation.Website_Feature_Image_URL__c || '';

    try {
      if (!firstName) {
        throw new Error('Contact name is missing');
      }

      if (!locationName) {
        throw new Error('Location name is missing');
      }
      
      const resizedImageUrl = imageUrl ? await resizeImage(imageUrl) : '';

      let pricingInfo = '';
      if (promotedLocation.price8Hours) {
        pricingInfo = '\n\nPricing Information:';
        pricingInfo += `\n• 8 Hour Shoot from $${Number(promotedLocation.price8Hours).toLocaleString()} + $550 Booking Fee + GST`;
      }

      const prompt = `Write a short personalised outreach email to ${firstName} promoting a location we thought they'd like based off their past hires. The location is ${locationName} ${locationUrl ? `(${locationUrl})` : ''}.${pricingInfo}`;
      let generatedContent = await openAIService.generateEmailContent(prompt);

      // Add pricing and image sections
      generatedContent = addPricingAndImageSections(generatedContent, {
        promotedLocation,
        locationName,
        locationUrl,
        resizedImageUrl
      });
      
      setEmailData(prev => ({
        ...prev,
        to: selectedContact.email || '',
        subject: `New Location Just Listed - ${locationName}`,
        body: generatedContent,
        selectedContact: selectedContact
      }));
    } catch (error) {
      console.error('Error in handleContactSelection:', error);
      enqueueSnackbar(error.message || 'Failed to generate email content', { variant: 'error' });
      
      // Set default content if AI generation fails
      const defaultContent = generateDefaultEmailContent({
        firstName,
        locationName,
        locationUrl,
        promotedLocation,
        imageUrl
      });

      setEmailData(prev => ({
        ...prev,
        to: selectedContact.email || '',
        subject: `New Location Just Listed - ${locationName}`,
        body: defaultContent,
        selectedContact: selectedContact
      }));
    } finally {
      setIsGenerating(false);
    }
  };

  // Helper function to add pricing and image sections to email content
  const addPricingAndImageSections = (content, { promotedLocation, locationName, locationUrl, resizedImageUrl }) => {
    if (promotedLocation.price8Hours) {
      const pricingHtml = `<p style="margin-top: 20px; margin-bottom: 20px;"><strong>Pricing Information:</strong><br/>${promotedLocation.price8Hours ? `• 8 Hour Shoot from $${Number(promotedLocation.price8Hours).toLocaleString()} + $550 Booking Fee + GST<br/>` : ''}${promotedLocation.cleaningFee ? `• Cleaning fee: $${Number(promotedLocation.cleaningFee).toLocaleString()}<br/>` : ''}• Based on a day with up to 15 PAX, alternative hours available on request.<br/>• Furniture shoots, TVC's, filming, larger teams will incur additional fees. <a href="${locationUrl}#terms" style="color: #1976d2; text-decoration: underline;">View pricing terms</a>.</p>`;
      content = content + pricingHtml;
    }

    if (resizedImageUrl) {
      const locationImageSection = `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin-top: 20px; border-top: 1px solid #eee;"><tr><td style="padding-top: 20px;"><a href="${locationUrl}" style="color: #1976d2; text-decoration: none; font-size: 16px; font-weight: 500; display: block; margin-bottom: 12px;">${locationName}</a><table width="400" border="0" cellspacing="0" cellpadding="0"><tr><td><img src="${resizedImageUrl}" alt="${locationName}" width="400" style="display: block; border-radius: 4px; max-width: 400px;" /></td></tr></table><p style="margin-top: 15px; font-size: 12px; color: #666;">Please don't hesitate to let me know if you would like to stop receiving these type of emails.</p></td></tr></table>`;
      content = `${content}${locationImageSection}`;
    }

    return content;
  };

  // Helper function to generate default email content
  const generateDefaultEmailContent = ({ firstName, locationName, locationUrl, promotedLocation, imageUrl }) => {
    const locationLink = locationUrl ? 
      `<a href="${locationUrl}" style="color: #1976d2; text-decoration: none;">${locationName}</a>` : 
      locationName;
    
    let pricingHtml = '';
    if (promotedLocation.price8Hours) {
      pricingHtml = `<p style="margin-top: 20px; margin-bottom: 20px;"><strong>Pricing Information:</strong><br/>${promotedLocation.price8Hours ? `• 8 Hour Shoot from $${Number(promotedLocation.price8Hours).toLocaleString()} + $550 Booking Fee + GST<br/>` : ''}${promotedLocation.cleaningFee ? `• Cleaning fee: $${Number(promotedLocation.cleaningFee).toLocaleString()}<br/>` : ''}• Based on a day with up to 15 PAX, alternative hours available on request.<br/>• Furniture shoots, TVC's, filming, larger teams will incur additional fees. <a href="${locationUrl}#terms" style="color: #1976d2; text-decoration: underline;">View pricing terms</a>.</p>`;
    }

    return `<p>Dear ${firstName},</p><p>I hope this email finds you well. I wanted to reach out about an exciting location that I believe would be perfect for your upcoming projects - ${locationLink}.</p><p>Would you like to arrange a recce or get more details about this beautiful space?</p>${pricingHtml}${imageUrl ? `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin-top: 20px; border-top: 1px solid #eee;"><tr><td style="padding-top: 20px;"><a href="${locationUrl}" style="color: #1976d2; text-decoration: none; font-size: 16px; font-weight: 500; display: block; margin-bottom: 12px;">${locationName}</a><table width="400" border="0" cellspacing="0" cellpadding="0"><tr><td><img src="${imageUrl}" alt="${locationName}" width="400" style="display: block; border-radius: 4px; max-width: 400px;" /></td></tr></table><p style="margin-top: 15px; font-size: 12px; color: #666;">Please don't hesitate to let me know if you would like to stop receiving these type of emails.</p></td></tr></table>` : ''}`;
  };

  // Add utility function for better date formatting for logs
  const formatDateForDisplay = (date) => {
    if (!date) return 'Invalid date';
    const d = new Date(date);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()} [UTC: ${d.toISOString()}]`;
  };

  // Add new function to poll for recent Pure Promote activity
  const pollRecentActivity = async () => {
    try {
      // Skip polling if user is composing an email
      const isComposingEmail = selectedContact !== null || 
                               emailData.body.trim() !== '' || 
                               emailData.subject.trim() !== '';
      
      if (isComposingEmail) {
        // console.log('Skipping polling because user is composing an email');
        return;
      }

      // Combine regular and VIP contacts for polling
      let allContacts = [];
      
      if (!contacts || contacts.length === 0) {
        // Get all contacts from IndexedDB if state is empty
        try {
          const savedContacts = await indexedDBService.getPurePromoteContacts();
          if (!savedContacts || savedContacts.length === 0) {
            // console.log('No contacts found in IndexedDB, skipping polling');
            return;
          }
          allContacts = savedContacts;
        } catch (error) {
          console.error('Error loading contacts from IndexedDB:', error);
          return;
        }
      } else {
        // Use contacts from state and add VIP contacts
        allContacts = [...contacts];
        
        // Add VIP contacts if available
        if (vipContacts && vipContacts.length > 0) {
          allContacts = [...allContacts, ...vipContacts];
        }
      }
      
      // Extract unique contact identifiers (avoid duplicates between regular and VIP lists)
      const uniqueContactIds = new Set();
      const contactIdentifiers = [];
      
      allContacts.forEach(contact => {
        const id = contact.contactId || contact.id;
        if (id && !uniqueContactIds.has(id)) {
          uniqueContactIds.add(id);
          contactIdentifiers.push(id);
        }
      });
      
      console.log(`Using ${contactIdentifiers.length} contact identifiers for polling`, {
        hasSalesforceIds: contactIdentifiers.some(id => id && !id.includes('@')),
        hasEmails: contactIdentifiers.some(id => id && id.includes('@')),
        sampleIdentifiers: contactIdentifiers.slice(0, 5)
      });
      
      // Query without date filter to check for any existing records
      const allRecords = await salesforceService.queryPurePromoteByContactIds(contactIdentifiers, null);
      
      // Now do the normal query with date filter
      const recentRecords = await salesforceService.queryPurePromoteByContactIds(contactIdentifiers, lastPollTime);
      
      handlePollResults(recentRecords);
      
      // Always update the lastPollTime to the current time, regardless of results
      setLastPollTime(new Date());
    } catch (error) {
      console.error('Error polling for Pure Promote activity:', error);
    }
  };
  
  // Helper function to handle poll results
  const handlePollResults = (recentRecords) => {
    if (recentRecords.length > 0) {
      console.log(`Found ${recentRecords.length} recent Pure Promote records`, recentRecords.map(r => ({
        contact: r.Contact_Sent_To__c,
        location: r.Location_Account_Promoted__c,
        datetime: formatDateForDisplay(r.Send_Datetime__c)
      })));
      
      // Create a map of contact IDs to their latest record
      const newRecentlyContactedMap = { ...recentlyContactedIds };
      
      // Track which contacts need updating
      const updatedContactIds = [];
      
      // Process each record
      recentRecords.forEach(record => {
        const contactId = record.Contact_Sent_To__c;
        const lastActivityTime = new Date(record.Send_Datetime__c).getTime();
        
        // Check if this is newer than what we have
        if (!newRecentlyContactedMap[contactId] || 
            lastActivityTime > new Date(newRecentlyContactedMap[contactId].timestamp || 0).getTime()) {
          
          newRecentlyContactedMap[contactId] = {
            timestamp: record.Send_Datetime__c,
            locationId: record.Location_Account_Promoted__c,
            recordId: record.Id,
            isRecent: true,
            sentTime: new Date(record.Send_Datetime__c)
          };
          
          updatedContactIds.push(contactId);
        }
      });
      
      if (updatedContactIds.length > 0) {
        // console.log(`Updating ${updatedContactIds.length} contacts with new Pure Promote activity`);
        setRecentlyContactedIds(newRecentlyContactedMap);
        
        // Update regular contacts if needed
        if (contacts && contacts.length > 0) {
          const updatedContacts = contacts.map(contact => updateContactWithActivity(contact, updatedContactIds, newRecentlyContactedMap, recentRecords));
          setContacts(updatedContacts);
        }
        
        // Update VIP contacts if needed
        if (vipContacts && vipContacts.length > 0) {
          const updatedVipContacts = vipContacts.map(contact => updateContactWithActivity(contact, updatedContactIds, newRecentlyContactedMap, recentRecords));
          setVipContacts(updatedVipContacts);
          
          // Also update filtered VIP contacts if they exist
          if (filteredVipContacts && filteredVipContacts.length > 0) {
            setFilteredVipContacts(prevFilteredVipContacts => 
              prevFilteredVipContacts.map(contact => 
                updateContactWithActivity(contact, updatedContactIds, newRecentlyContactedMap, recentRecords)
              )
            );
          }
        }
      }
      
      return true; // Return true to indicate records were found
    } else {
      // console.log('No new Pure Promote records found in polling');
      return false; // Return false to indicate no records were found
    }
  };
  
  // Helper function to update a contact with activity data
  const updateContactWithActivity = (contact, updatedContactIds, recentlyContactedMap, recentRecords) => {
    // Safety check for null contact
    if (!contact || !updatedContactIds || !recentlyContactedMap || !recentRecords) {
      return contact;
    }
    
    // Check if this contact's Salesforce ID is in the updated IDs
    const isSalesforceIdUpdated = contact.contactId && updatedContactIds.includes(contact.contactId);
    
    if (isSalesforceIdUpdated) {
      // Check if this contact was previously selected
      const wasSelected = selectedContactIds && selectedContactIds.includes(contact.id);
      
      // Get the update info using the Salesforce ID
      const updateInfo = recentlyContactedMap[contact.contactId];
      
      if (!updateInfo) {
        return contact;
      }
      
      // Find the matching record to get the Contact_Notes__c field if available
      const matchingRecord = recentRecords.find(rec => rec.Contact_Sent_To__c === contact.contactId);
      
      // Update the contact with the new timestamp
      const updatedContact = {
        ...contact,
        status: 'contacted',
        purePromoteLastContact: updateInfo.timestamp,
        purePromoteLastLocation: updateInfo.locationId,
        Pure_Promote_Last_Contact__c: updateInfo.timestamp,
        Pure_Promote_Last_Location__c: updateInfo.locationId,
        // Add Contact_Notes__c from the record or keep existing notes
        Contact_Notes__c: matchingRecord?.Contact_Sent_To__r?.Contact_Notes__c || 
                          matchingRecord?.Contact_Notes__c || 
                          contact.Contact_Notes__c || "",
        // Add recentActivity for UI display
        recentActivity: {
          sentTime: updateInfo.sentTime,
          locationId: updateInfo.locationId,
          isRecent: true
        }
      };
      
      // Also add this record to the map using the contact's UI ID to ensure it's picked up by the UI
      if (contact.id) {
        recentlyContactedMap[contact.id] = updateInfo;
      }
      
      // If this contact was selected, preserve the selection state
      if (wasSelected) {
        return {
          ...updatedContact,
          isSelected: true
        };
      }
      
      return updatedContact;
    }
    
    // No changes to this contact
    return contact;
  };

  // Add useEffect to start/stop polling
  useEffect(() => {
    // Check if user is actively composing an email
    const isComposingEmail = selectedContact !== null || 
                             emailData.body.trim() !== '' || 
                             emailData.subject.trim() !== '';
    
    // Clear any existing interval
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }

    // Start polling if active AND not composing email
    if (pollingActive && !isComposingEmail) {
      // console.log('Starting Pure Promote activity polling (every 60 seconds)');
      // Do an initial poll
      pollRecentActivity();
      
      // Set up the interval
      pollingIntervalRef.current = setInterval(() => {
        pollRecentActivity();
      }, 60000); // Poll every 60 seconds
    } else if (isComposingEmail) {
      // console.log('Pausing polling while composing email');
    }

    // Cleanup
    return () => {
      if (pollingIntervalRef.current) {
        // console.log('Stopping Pure Promote activity polling');
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [pollingActive, selectedContact, emailData.body, emailData.subject]);

  const handleSendEmail = async (selectedContact) => {
    setIsLoading(true);
    try {
      console.log('Starting handleSendEmail with contact:', selectedContact);
      
      // Get the correct contact ID based on whether it's a VIP or regular contact
      let contactId;
      if (selectedContact.VIP_Contact__c) {
        contactId = selectedContact.Id || selectedContact.id; // VIP contacts might have either Id or id
      } else {
        contactId = selectedContact.contactId || selectedContact.id; // Regular contacts use contactId or id
      }
      
      if (!contactId) {
        console.error('No contact ID found for selected contact:', selectedContact);
        enqueueSnackbar('Cannot send email: Missing contact ID', { variant: 'error' });
        return;
      }

      // Send the email using the owner's email as the sender
      const emailResult = await outlookService.composeEmail({
        to: emailData.to,
        cc: emailData.cc,
        bcc: emailData.bcc,
        subject: emailData.subject,
        body: emailData.body
      }, selectedContact.ownerEmail || selectedContact.owner);

      if (emailResult) {
        try {
          // Create Pure Promote record in Salesforce
          await salesforceService.createPurePromoteRecord(
            contactId,
            promotedLocation?.accountId,
            new Date().toISOString(),
            selectedContact.ownerId || selectedContact.OwnerId // Handle both VIP and regular contact owner IDs
          );
          
          // Update contact status
          const now = new Date().toISOString();
          const updatedContact = {
            ...selectedContact,
            status: 'contacted',
            Pure_Promote_Last_Contact__c: now,
            Pure_Promote_Last_Location__c: promotedLocation?.accountId || '',
            recentActivity: {
              sentTime: now,
              locationId: promotedLocation?.accountId || '',
              isRecent: true
            }
          };

          // Update contacts list based on whether it's a VIP contact
          if (selectedContact.VIP_Contact__c) {
            // Update VIP contacts list
            setVipContacts(prevContacts => 
              prevContacts.map(contact => 
                contact.id === selectedContact.id || contact.Id === selectedContact.Id ? updatedContact : contact
              )
            );
            
            // Also update filtered VIP contacts if they exist
            setFilteredVipContacts(prevContacts => 
              prevContacts.map(contact => 
                contact.id === selectedContact.id || contact.Id === selectedContact.Id ? updatedContact : contact
              )
            );
          } else {
            // Update regular contacts list
            setContacts(prevContacts => 
              prevContacts.map(contact => 
                contact.id === selectedContact.id ? updatedContact : contact
              )
            );

            // Update filtered contacts list
            setFilteredContacts(prevContacts => 
              prevContacts.map(contact => 
                contact.id === selectedContact.id ? updatedContact : contact
              )
            );

            // Update IndexedDB for regular contacts
            try {
              await indexedDBService.updatePurePromoteContact(selectedContact.id, {
                status: 'contacted',
                Pure_Promote_Last_Contact__c: now,
                Pure_Promote_Last_Location__c: promotedLocation?.accountId || ''
              });
            } catch (error) {
              console.error('Error updating IndexedDB:', error);
            }
          }

          // Clear selection and email data
          setSelectedContactIds([]);
          setSelectedContact(null);
          setEmailData({
            to: '',
            cc: '',
            bcc: '',
            subject: '',
            body: '',
            selectedContact: null
          });

          enqueueSnackbar('Email sent and tracking record created', { variant: 'success' });
        } catch (recordError) {
          console.error('Failed to create tracking record:', recordError);
          enqueueSnackbar('Email sent but failed to create tracking record', { variant: 'warning' });
        }
      }
    } catch (error) {
      console.error('Error in handleSendEmail:', error);
      enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOwnerChange = (event) => {
    setCurrentOwner(event.target.value);
  };

  // Add handler functions for state and industry changes
  const handleStateChange = (event) => {
    setCurrentState(event.target.value);
  };

  const handleIndustryChange = (event) => {
    setCurrentIndustry(event.target.value);
  };

  // Add this new component before the columns definition
  const NotesCell = (props) => {
    const { value, row, api } = props;
    const [open, setOpen] = useState(false);
    const [noteText, setNoteText] = useState(value || '');
    
    const handleOpen = (e) => {
      e.stopPropagation(); // Prevent row selection
      setNoteText(value);
      setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };
    
    const handleSave = async () => {
      // Use the same update logic as the cell editing
      if (noteText !== value) {
        try {
          const contactId = row?.contactId || row?.Id;
          if (contactId) {
            // Update Salesforce
            await salesforceService.updateContactNotes(contactId, noteText);
            
            // Update local state
            const newValue = noteText;
            
            // Only update IndexedDB for non-VIP contacts
            if (!row.VIP_Contact__c) {
              try {
                await indexedDBService.updatePurePromoteContact(row.id, {
                  Contact_Notes__c: newValue
                });
              } catch (error) {
                console.log('Skipping IndexedDB update for VIP contact');
              }
            }
            
            // Trigger a refresh of the row data
            api.updateRows([{ id: row.id, Contact_Notes__c: newValue }]);
            
            // Show success message
            enqueueSnackbar('Notes updated successfully', { variant: 'success' });
          }
        } catch (error) {
          console.error('Error updating notes:', error);
          enqueueSnackbar('Failed to update notes', { variant: 'error' });
        }
      }
      handleClose();
    };
    
    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: '0 8px'
      }}>
        <Box sx={{ 
          flexGrow: 1, 
          whiteSpace: 'normal', 
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          backgroundColor: value ? '#f5f5f5' : 'transparent',
          p: value ? 1 : 0,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center'
        }}>
          {String(value || '')}
        </Box>
        <IconButton 
          size="small" 
          onClick={handleOpen}
          sx={{ 
            opacity: 0.7,
            '&:hover': { opacity: 1 },
            ml: 1,
            alignSelf: 'center'
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Edit Contact Notes</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Notes"
              fullWidth
              multiline
              rows={4}
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const columns = [
    {
      field: 'contactId',
      headerName: 'Contact ID',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        if (!params.row) return 'N/A';
        return params.row.contactId || params.row.Id || params.row?.id || 'N/A';
      },
      renderCell: (params) => {
        const id = params.row?.contactId || params.row?.Id || params.row?.id;
        return (
          <Box sx={{ color: id ? 'inherit' : 'error.main' }}>
            {id || 'N/A'}
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => {
        const status = params?.row?.status || 'pending';
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                backgroundColor: status === 'contacted' ? '#4caf50' : '#ff9800',
                color: 'white',
                padding: '4px 12px',
                borderRadius: '50px',
                fontSize: '0.75rem',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '24px',
              }}
            >
              {status === 'contacted' ? 'Sent' : 'Pending'}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'Contact_Notes__c',
      headerName: 'Notes',
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        // Directly access notes from the row object instead of params.value
        const notesValue = params.row?.Contact_Notes__c || '';
        return notesValue;
      },
      renderCell: (params) => {
        return <NotesCell value={params.row?.Contact_Notes__c || ''} row={params.row} api={params.api} />;
      }
    },
    {
      field: 'Pure_Promote_Last_Contact__c',
      headerName: 'Last Interaction',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        // Use recent activity data if available, otherwise fall back to the contact field
        const recentActivity = params.row.recentActivity;
        const lastInteractionDate = recentActivity?.sentTime || params.row.Pure_Promote_Last_Contact__c;
        
        if (!lastInteractionDate) return 'Never';
        
        // Convert to date object
        const date = new Date(lastInteractionDate);
        const now = new Date();
        const diffMs = now - date;
        const diffMins = Math.floor(diffMs / 60000);
        
        // Full formatted date for tooltip
        const fullFormattedDate = date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });

        // Display relative time if recent, otherwise show the date
        let displayText;
        if (diffMins < 60) {
          displayText = `${diffMins}m ago`;
        } else {
          const diffHours = Math.floor(diffMins / 60);
          if (diffHours < 24) {
            displayText = `${diffHours}h ago`;
          } else {
            // Just show the date for older items
            displayText = date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            });
          }
        }
        
        return (
          <Tooltip title={fullFormattedDate}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              // Highlight very recent interactions (less than 1 hour)
              color: diffMins < 60 ? 'success.main' : 'inherit',
              fontWeight: diffMins < 60 ? 'bold' : 'normal'
            }}>
              {displayText}
            </Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'Pure_Promote_Last_Location__c',
      headerName: 'Last Promote',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const recentActivity = params.row.recentActivity;
        const locationId = recentActivity?.locationId || params.row.Pure_Promote_Last_Location__c;
        
        // If we have the location name in the cache, use it
        if (locationId && locationCache[locationId]) {
          return locationCache[locationId];
        }
        
        // If not in cache and not already being fetched, trigger a fetch
        if (locationId && !pendingLocationFetches.current.has(locationId)) {
          // Use setTimeout to avoid state updates during render
          setTimeout(() => {
            fetchLocationName(locationId);
          }, 0);
          
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ mr: 1 }} />
              Loading...
            </Box>
          );
        }
        
        // If no location ID, show default text
        return params.row.Pure_Promote_Last_Location__c || 'None';
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'location',
      headerName: 'Previous Location',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const locationName = params?.row?.location || params?.row?.locationName || params?.row?.locations || 'N/A';
        const locationUrl = params?.row?.locationUrl;
        
        if (locationUrl) {
          return (
            <Link
              href={locationUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {locationName}
            </Link>
          );
        }
        
        return locationName;
      },
    },
    {
      field: 'billingStateCode',
      headerName: 'State',
      width: 120,
      editable: false,
    },
    {
      field: 'industrySector',
      headerName: 'Industry',
      width: 150,
      editable: false,
    },
  ];

  // Filter regular contacts - extremely simplified and safe implementation
  useEffect(() => {
    // Skip if not on the main contact tab
    if (currentTab !== 0) {
      return;
    }
    
    try {
      // Safety check
      if (!contacts || !Array.isArray(contacts)) {
        setFilteredContacts([]);
        return;
      }
      
      // Create a Map to track unique contacts by email
      const uniqueContacts = new Map();
      
      // Basic filtering with safety checks and deduplication
      contacts.forEach(contact => {
        if (!contact) return;
        
        // Skip contacts that have opted out
        if (contact.no_promote__c === true) return;
        
        // Skip VIP contacts
        if (contact.VIP_Contact__c === true) return;
        
        // Filter by owner
        if (currentOwner !== 'all' && contact.owner !== currentOwner) return;
        
        // Filter by state
        if (currentState !== 'all' && contact.billingStateCode !== currentState) return;
        
        // Filter by industry
        if (currentIndustry !== 'all' && contact.industrySector !== currentIndustry) return;
        
        // Use email as unique identifier
        const uniqueKey = contact.email?.toLowerCase();
        if (!uniqueKey) return;
        
        // If we already have this contact, only update if this one is more recent
        const existingContact = uniqueContacts.get(uniqueKey);
        if (existingContact) {
          const existingDate = existingContact.Pure_Promote_Last_Contact__c ? new Date(existingContact.Pure_Promote_Last_Contact__c) : new Date(0);
          const newDate = contact.Pure_Promote_Last_Contact__c ? new Date(contact.Pure_Promote_Last_Contact__c) : new Date(0);
          
          if (newDate > existingDate) {
            uniqueContacts.set(uniqueKey, contact);
          }
        } else {
          uniqueContacts.set(uniqueKey, contact);
        }
      });
      
      // Convert Map values back to array
      const filteredResults = Array.from(uniqueContacts.values());
      
      // Set simple status for display
      const enhancedContacts = filteredResults.map(contact => ({
        ...contact,
        status: contact.Pure_Promote_Last_Contact__c ? 'contacted' : 'pending'
      }));
      
      setFilteredContacts(enhancedContacts);
    } catch (error) {
      console.error('Error filtering regular contacts:', error);
      // Always ensure we have a valid array
      setFilteredContacts([]);
    }
  }, [contacts, currentOwner, currentState, currentIndustry, currentTab]);

  // Add the new function to fetch and cache location names
  const fetchLocationName = useCallback(async (locationId) => {
    // If we're already fetching this location or it's in the cache, skip
    if (pendingLocationFetches.current.has(locationId) || locationCache[locationId]) {
      return;
    }

    try {
      // Mark as pending
      pendingLocationFetches.current.add(locationId);
      
      // First check if it's in availableLocations
      const cachedLocation = availableLocations.find(loc => 
        loc.accountId === locationId || 
        loc.name === locationId
      );
      
      if (cachedLocation) {
        setLocationCache(prev => ({
          ...prev,
          [locationId]: cachedLocation.name
        }));
        return;
      }
      
      // If not in cache, fetch from Salesforce
      await salesforceService.authenticate();
      
      // Check if the locationId is a Salesforce ID (15-18 characters)
      const isSalesforceId = /^[a-zA-Z0-9]{15,18}$/.test(locationId);
      
      const query = isSalesforceId 
        ? `SELECT Id, Name FROM Account WHERE Id = '${locationId}' LIMIT 1`
        : `SELECT Id, Name FROM Account WHERE Name = '${locationId.replace(/'/g, "''")}' LIMIT 1`;
      
      const response = await axios.post(
        `${process.env.NODE_ENV === 'production' ? 'https://bdm.purelocations.com.au' : 'https://bdm.purelocations.com.au'}/api/sf/query`,
        { query },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${salesforceService.accessToken}`
          }
        }
      );
      
      if (response.data.records && response.data.records.length > 0) {
        const locationName = response.data.records[0].Name;
        
        // Update the cache using the original locationId as key
        setLocationCache(prev => ({
          ...prev,
          [locationId]: locationName
        }));
      } else {
        // Cache a placeholder using the original locationId as key
        setLocationCache(prev => ({
          ...prev,
          [locationId]: locationId // Use the original value as fallback
        }));
      }
    } catch (error) {
      console.error(`Error fetching location name for ${locationId}:`, error);
      // Cache error state using the original locationId as key
      setLocationCache(prev => ({
        ...prev,
        [locationId]: locationId // Use the original value as fallback
      }));
    } finally {
      // Remove from pending set
      pendingLocationFetches.current.delete(locationId);
    }
  }, [availableLocations]);

  // Add a batch fetch effect for initial load
  useEffect(() => {
    if (!filteredVipContacts || filteredVipContacts.length === 0) return;
    
    // Collect all unique location IDs that we need to fetch
    const locationIdsToFetch = new Set();
    
    filteredVipContacts.forEach(contact => {
      const locationId = contact.Pure_Promote_Last_Location__c;
      if (locationId && !locationCache[locationId] && !pendingLocationFetches.current.has(locationId)) {
        locationIdsToFetch.add(locationId);
      }
    });
    
    // If we have any location IDs to fetch, do it in a batch
    if (locationIdsToFetch.size > 0) {
      const locationIds = Array.from(locationIdsToFetch);
      locationIds.forEach(id => {
        fetchLocationName(id);
      });
    }
  }, [filteredVipContacts, locationCache, fetchLocationName]);

  // Add a batch fetch function to reduce API calls
  const batchFetchLocationNames = async (locationIds) => {
    try {
      // Safety check for null
      if (!locationIds || !Array.isArray(locationIds)) {
        return;
      }
      
      // Filter out duplicate IDs and nulls
      const uniqueIds = [...new Set(locationIds)].filter(
        id => id && typeof id === 'string' && id.trim() !== ''
      );
      
      if (uniqueIds.length === 0) return;
      
      // Check which IDs we need to fetch (not already in cache)
      const idsToFetch = uniqueIds.filter(id => !locationCache[id]);
      
      if (idsToFetch.length === 0) return; // All IDs are already in cache
      
      // Batch fetch if there are multiple IDs
      if (idsToFetch.length > 0) {
        // Mark IDs as pending to avoid duplicate fetches
        idsToFetch.forEach(id => {
          pendingLocationFetches.current.add(id);
        });
        
        try {
          const locationNames = await salesforceService.getLocationNamesById(idsToFetch);
          
          // Update cache with fetched names
          setLocationCache(prev => ({
            ...prev,
            ...locationNames
          }));
          
          // Remove from pending set
          idsToFetch.forEach(id => {
            pendingLocationFetches.current.delete(id);
          });
        } catch (error) {
          console.error(`Error batch fetching location names:`, error);
          // Cache placeholders for all IDs
          const placeholders = {};
          idsToFetch.forEach(id => {
            placeholders[id] = `${id.substring(0, 6)}...`;
          });
          
          setLocationCache(prev => ({
            ...prev,
            ...placeholders
          }));
        }
      }
    } catch (error) {
      console.error(`Error batch fetching location names:`, error);
    }
  };

  // Add back the function to fetch location details
  const fetchLocationDetails = async (locationId) => {
    try {
      setIsLoading(true);
      
      // Ensure we're authenticated with Salesforce
      await salesforceService.authenticate();
      
      // Use the correct API endpoint format
      const response = await axios.post(
        `${process.env.NODE_ENV === 'production' ? 'https://bdm.purelocations.com.au' : 'https://bdm.purelocations.com.au'}/api/sf/query`,
        {
          query: `
            SELECT 
              Id,
              Name,
              Address__c,
              Internal_Location_URL__c,
              Website_Feature_Image_URL__c,
              Pure_Location_8_Hrs_Photo_Shoot_Online__c,
              Client_Cleaning_Fee__c
            FROM Account
            WHERE Id = '${locationId}'
            LIMIT 1
          `
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.records && response.data.records.length > 0) {
        const locationDetails = response.data.records[0];
        // console.log('Location details fetched:', locationDetails);
        
        // Update the promoted location with the fetched details
        setPromotedLocation(prev => ({
          ...prev,
          Website_Feature_Image_URL__c: locationDetails.Website_Feature_Image_URL__c,
          Internal_Location_URL__c: locationDetails.Internal_Location_URL__c,
          address: locationDetails.Address__c,
          price8Hours: locationDetails.Pure_Location_8_Hrs_Photo_Shoot_Online__c,
          cleaningFee: locationDetails.Client_Cleaning_Fee__c
        }));
        
        // Also update the location name in our cache
        setLocationCache(prev => ({
          ...prev,
          [locationId]: locationDetails.Name
        }));
      } else {
        console.warn(`No location details found for ID: ${locationId}`);
        enqueueSnackbar('Location details not available in Salesforce', { variant: 'warning' });
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
      
      // More user-friendly error message based on the error type
      let errorMessage = 'Failed to fetch location details.';
      
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          errorMessage += ' Authentication issue - please log out and log in again.';
        } else if (error.response.status === 404) {
          errorMessage += ' The location record was not found.';
        } else if (error.response.status >= 500) {
          errorMessage += ' Server error - please try again later.';
        }
      } else if (error.request) {
        errorMessage += ' Network connection problem.';
      }
      
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Update the Autocomplete onChange handler
  const handleLocationChange = async (event, newValue) => {
    setPromotedLocation(newValue);
    if (newValue?.accountId) {
      await fetchLocationDetails(newValue.accountId);
    }
  };

  const EmailDialog = ({ open, onClose, selectedContact, location }) => {
    const [sending, setSending] = useState(false);
    const [notes, setNotes] = useState(selectedContact?.Contact_Notes__c || '');
    
    useEffect(() => {
      // Update notes state when selectedContact changes
      setNotes(selectedContact?.Contact_Notes__c || '');
    }, [selectedContact]);
    
    const handleEmailSend = async () => {
      setSending(true);
      try {
        await handleSendEmail(selectedContact);
        onClose();
      } catch (error) {
        console.error('Error sending email:', error);
        enqueueSnackbar('Failed to send email: ' + error.message, { variant: 'error' });
      } finally {
        setSending(false);
      }
    };
    
    const handleNotesChange = async (e) => {
      const newNotes = e.target.value;
      setNotes(newNotes);
      
      // Debounced notes saving would be good here, but for simplicity
      // we'll update directly on change
      try {
        if (selectedContact?.contactId) {
          await salesforceService.updateContactNotes(selectedContact.contactId, newNotes);
          // Update the local contact data with the new notes
          setContacts(prevContacts => 
            prevContacts.map(contact => 
              contact.id === selectedContact.id ? 
                {...contact, Contact_Notes__c: newNotes} : 
                contact
            )
          );
        }
      } catch (error) {
        console.error('Failed to save contact notes:', error);
      }
    };
    
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Compose Email
          {selectedContact && (
            <Typography variant="caption" display="block" color="textSecondary">
              Contact ID: {selectedContact.contactId || selectedContact.Id}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {selectedContact ? (
            <>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="To"
                  value={emailData.to}
                  onChange={handleEmailChange('to')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
                <TextField
                  label="CC"
                  value={emailData.cc}
                  onChange={handleEmailChange('cc')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
                <TextField
                  label="BCC"
                  value={emailData.bcc}
                  onChange={handleEmailChange('bcc')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
                <TextField
                  label="Subject"
                  value={emailData.subject}
                  onChange={handleEmailChange('subject')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Box>
              
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Contact Notes
                </Typography>
                <TextField
                  multiline
                  rows={3}
                  value={notes}
                  onChange={handleNotesChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="Add notes about this contact here..."
                  helperText="These notes are saved to Salesforce and will be visible on the contact record"
                />
              </Box>
              
              <Box>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Email Body
                </Typography>
                <TextField
                  multiline
                  rows={10}
                  value={emailData.body}
                  onChange={handleEmailChange('body')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="Enter email content here..."
                />
              </Box>
            </>
          ) : (
            <Typography>No contact selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            onClick={handleEmailSend}
            loading={sending}
            disabled={!selectedContact || sending}
            variant="contained"
            startIcon={<SendIcon />}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  // Add renderContactRow function for visual indicators
  const renderContactRow = useCallback((contact) => {
    const isSelected = selectedContactIds.includes(contact.id);
    const hasRecentActivity = contact.recentActivity && contact.recentActivity.isRecent;
    
    
    
    // Check if the contact was recently contacted with the same location
    const wasRecentlyContactedForSameLocation = 
      hasRecentActivity && 
      promotedLocation && 
      contact.recentActivity.locationId === promotedLocation.accountId;
    
    // Determine how recently the contact was emailed (within last hour, today, etc.)
    let recentTimeDescription = '';
    if (hasRecentActivity) {
      const activityTime = new Date(contact.recentActivity.sentTime);
      const now = new Date();
      const diffMs = now - activityTime;
      const diffMins = Math.floor(diffMs / 60000);
      
      if (diffMins < 60) {
        recentTimeDescription = `${diffMins}m ago`;
      } else {
        const diffHours = Math.floor(diffMins / 60);
        if (diffHours < 24) {
          recentTimeDescription = `${diffHours}h ago`;
        } else {
          recentTimeDescription = activityTime.toLocaleDateString();
        }
      }
    }
    
    const result = {
      ...contact,
      status: hasRecentActivity ? 'contacted' : (contact.status || 'pending'),
      statusDetails: hasRecentActivity ? {
        recentTimeDescription,
        wasRecentlyContactedForSameLocation
      } : null,
      // Make sure Contact_Notes__c is explicitly preserved
      Contact_Notes__c: contact.Contact_Notes__c || ''
    };
    
    
    
    return result;
  }, [selectedContactIds, promotedLocation]);

  // Update DataGrid to include custom rendering for recently contacted
  const CustomStatusCell = (params) => {
    if (!params || !params.row) {
      // Safety return if params are invalid
      return (
        <Box
          sx={{
            backgroundColor: '#ff9800',
            color: 'white',
            padding: '4px 12px',
            borderRadius: '50px',
            fontSize: '0.75rem',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
          }}
        >
          Pending
        </Box>
      );
    }

    const status = params?.row?.status || 'pending';
    const lastContact = params?.row?.Pure_Promote_Last_Contact__c;
    
    // Format last contact date if available
    let formattedTime = '';
    if (lastContact) {
      const contactTime = new Date(lastContact);
      const now = new Date();
      const diffMs = now - contactTime;
      const diffMins = Math.floor(diffMs / 60000);
      
      if (diffMins < 60) {
        formattedTime = `${diffMins}m ago`;
      } else {
        const diffHours = Math.floor(diffMins / 60);
        if (diffHours < 24) {
          formattedTime = `${diffHours}h ago`;
        } else {
          formattedTime = new Intl.DateTimeFormat('en-US', {
            month: 'short', 
            day: 'numeric'
          }).format(contactTime);
        }
      }
    }
    
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            backgroundColor: status === 'contacted' ? '#4caf50' : '#ff9800',
            color: 'white',
            padding: '4px 12px',
            borderRadius: '50px',
            fontSize: '0.75rem',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
          }}
        >
          {status === 'contacted' ? 
            (formattedTime ? `Sent ${formattedTime}` : 'Sent') 
            : 'Pending'}
        </Box>
      </Box>
    );
  };
  
  // Update the columns definition to use the custom cell renderer
  const updatedColumns = [...columns];
  const statusColumnIndex = updatedColumns.findIndex(col => col.field === 'status');
  if (statusColumnIndex >= 0) {
    updatedColumns[statusColumnIndex] = {
      ...updatedColumns[statusColumnIndex],
      renderCell: CustomStatusCell
    };
  }

  // Create our enhanced contacts list with recent activity indicators
  const enhancedContacts = useMemo(() => {
    // If no contacts, return empty array
    if (!filteredContacts.length) return [];
    
    return filteredContacts.map(contact => {
      // Get current selection state
      const isSelected = selectedContactIds.includes(contact.id);
      
      
      
      // Apply recent activity rendering without changing other properties
      const enhancedContact = renderContactRow(contact);
      
      
      
      // Make sure Contact_Notes__c is explicitly preserved
      enhancedContact.Contact_Notes__c = contact.Contact_Notes__c || '';
      
      // If this is the currently selected contact, ensure we preserve exact reference to prevent disruption
      if (isSelected && selectedContact && selectedContact.id === contact.id) {
        // Keep the exact selectedContact reference to avoid disrupting the composition process
        return {
          ...enhancedContact,
          // Preserve any properties that might have been added during the email composition process
          ...Object.keys(selectedContact)
            .filter(key => !Object.keys(enhancedContact).includes(key))
            .reduce((obj, key) => {
              obj[key] = selectedContact[key];
              return obj;
            }, {})
        };
      }
      
      return enhancedContact;
    });
  }, [filteredContacts, renderContactRow, selectedContactIds, selectedContact]);
  
  // Update handleCheckboxClick to temporarily pause polling
  const handleCheckboxClick = (event, id) => {
    // Temporarily pause polling while user interacts with the grid
    temporarilyPausePolling();
    
    const selectedIndex = selectedContactIds.indexOf(id);
    let newSelected = [];
    
    if (selectedIndex === -1) {
      newSelected = [id];
    } else {
      newSelected = [];
    }
    
    setSelectedContactIds(newSelected);
    
    if (newSelected.length > 0) {
      const contact = filteredContacts.find(c => c.id === newSelected[0]);
      if (contact) {
        setSelectedContact(contact);
      }
    } else {
      setSelectedContact(null);
    }
  };

  // Add a useEffect to batch fetch location names when filtered contacts change
  useEffect(() => {
    if (!filteredContacts || filteredContacts.length === 0) return;
    
    // Collect all unique location IDs that we need to fetch
    const locationIdsToFetch = new Set();
    
    filteredContacts.forEach(contact => {
      // Extract location ID from recent activity if available
      if (contact.recentActivity?.locationId) {
        locationIdsToFetch.add(contact.recentActivity.locationId);
      }
    });
    
    // Convert the Set to an Array and filter out IDs we already have in cache
    const uncachedLocationIds = Array.from(locationIdsToFetch)
      .filter(id => !locationCache[id]);
    
    // If we have any location IDs to fetch, do it in a batch
    if (uncachedLocationIds.length > 0) {
      // console.log(`Need to fetch ${uncachedLocationIds.length} location names`);
      batchFetchLocationNames(uncachedLocationIds);
    }
  }, [filteredContacts]);

  // Process row updates for the DataGrid - handle inline editing
  const processRowUpdate = async (newRow, oldRow) => {
    try {
      // Check if notes field was changed
      if (newRow.Contact_Notes__c !== oldRow.Contact_Notes__c) {
        const contactId = newRow.contactId || newRow.Id;
        
        if (!contactId) {
          console.error('Cannot update notes: No contact ID found for contact', newRow);
          enqueueSnackbar('Cannot update notes: Missing contact ID', { variant: 'error' });
          return oldRow; // Return old row to revert changes
        }
        
        // Update notes in Salesforce
        await salesforceService.updateContactNotes(contactId, newRow.Contact_Notes__c);
        
        // Only update IndexedDB for non-VIP contacts
        if (!newRow.VIP_Contact__c) {
          try {
            await indexedDBService.updatePurePromoteContact(newRow.id, {
              Contact_Notes__c: newRow.Contact_Notes__c
            });
          } catch (error) {
            console.log('Skipping IndexedDB update for VIP contact');
          }
        }
        
        // Update the contacts state to reflect changes in the grid
        setContacts(prevContacts => 
          prevContacts.map(contact => 
            contact.id === newRow.id 
              ? { ...contact, Contact_Notes__c: newRow.Contact_Notes__c } 
              : contact
          )
        );
        
        enqueueSnackbar('Notes updated successfully', { variant: 'success' });
        
        // Return the new row to confirm changes
        return newRow;
      }
      
      return newRow;
    } catch (error) {
      console.error('Error updating notes:', error);
      enqueueSnackbar('Failed to update notes: ' + (error.message || 'Unknown error'), { variant: 'error' });
      // Return old row to revert changes
      return oldRow;
    }
  };
  
  // Handle error if row update fails
  const handleProcessRowUpdateError = (error) => {
    console.error('Error during row update:', error);
    enqueueSnackbar('Failed to save changes', { variant: 'error' });
  };

  // Use very simple filter logic for VIP contacts in the loadVIPContacts function
  useEffect(() => {
    const loadVIPContacts = async () => {
      if (currentTab === 1) { // VIP Contact List tab
        setIsLoadingVIP(true);
        setVipError(null);
        setFilteredVipContacts([]); // Empty array by default
        setVipSelectionModel([]); // Reset selection when loading new data
        
        try {
          let vipContactsData = await salesforceService.getVIPContacts();
          // Ensure vipContactsData is always an array
          const validatedVipContacts = Array.isArray(vipContactsData) ? vipContactsData : [];
          
          // Add unique IDs if they don't exist
          const contactsWithIds = validatedVipContacts.map((contact, index) => ({
            ...contact,
            id: contact.id || `vip-${index}` // Ensure each contact has a unique ID
          }));

          setVipContacts(contactsWithIds);
          setFilteredVipContacts(contactsWithIds);

          // Extract unique values for filters using the validated array
          const uniqueOwners = [...new Set(contactsWithIds
            .filter(c => c && c.ownerName)
            .map(c => c.ownerName))].sort();

          const uniqueStates = [...new Set(contactsWithIds
            .filter(c => c && c.billingStateCode)
            .map(c => c.billingStateCode))].sort();

          const uniqueIndustries = [...new Set(contactsWithIds
            .filter(c => c && c.industrySector)
            .map(c => c.industrySector))].sort();

          setVipOwners(uniqueOwners);
          setVipStates(uniqueStates);
          setVipIndustries(uniqueIndustries);
          
        } catch (error) {
          console.error('Error loading VIP contacts:', error);
          setVipError(error.message || 'Failed to load VIP contacts');
          // Set everything to empty arrays on error
          setVipContacts([]);
          setFilteredVipContacts([]);
          setVipOwners([]);
          setVipStates([]);
          setVipIndustries([]);
        } finally {
          setIsLoadingVIP(false);
        }
      }
    };

    loadVIPContacts();
  }, [currentTab]);

  // Handle VIP contact selection
  const handleVipContactSelection = (newSelection) => {
    setVipSelectionModel(newSelection);
    if (newSelection.length === 1) {
      const selectedId = newSelection[0];
      const selectedContact = filteredVipContacts.find(c => 
        c.id === selectedId || c.Id === selectedId
      );
      if (selectedContact) {
        // Ensure the contact has a consistent id field
        const contactWithId = {
          ...selectedContact,
          id: selectedContact.id || selectedContact.Id, // Use existing id or Id
          Id: selectedContact.Id || selectedContact.id  // Ensure Id is also set
        };
        handleContactSelection([contactWithId.id]);
      }
    } else {
      handleContactSelection([]);
    }
  };

  // Update VIP columns to use the same status renderer as the main list
  const vipColumns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      minWidth: 120,
      renderCell: CustomStatusCell
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'Contact_Notes__c',
      headerName: 'Notes',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        if (!params || !params.row) return null;
        const value = params.row.Contact_Notes__c || '';
        return <NotesCell value={value} row={params.row} api={params.api} />;
      }
    },
    {
      field: 'Pure_Promote_Last_Contact__c',
      headerName: 'Last Interaction',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        // Use recent activity data if available, otherwise fall back to the contact field
        const recentActivity = params.row.recentActivity;
        const lastInteractionDate = recentActivity?.sentTime || params.row.Pure_Promote_Last_Contact__c;
        
        if (!lastInteractionDate) return 'Never';
        
        // Convert to date object
        const date = new Date(lastInteractionDate);
        const now = new Date();
        const diffMs = now - date;
        const diffMins = Math.floor(diffMs / 60000);
        
        // Full formatted date for tooltip
        const fullFormattedDate = date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });

        // Display relative time if recent, otherwise show the date
        let displayText;
        if (diffMins < 60) {
          displayText = `${diffMins}m ago`;
        } else {
          const diffHours = Math.floor(diffMins / 60);
          if (diffHours < 24) {
            displayText = `${diffHours}h ago`;
          } else {
            displayText = date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            });
          }
        }
        
        return (
          <Tooltip title={fullFormattedDate}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              color: diffMins < 60 ? 'success.main' : 'inherit',
              fontWeight: diffMins < 60 ? 'bold' : 'normal'
            }}>
              {displayText}
            </Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'Pure_Promote_Last_Location__c',
      headerName: 'Last Promote',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const recentActivity = params.row.recentActivity;
        const locationId = recentActivity?.locationId || params.row.Pure_Promote_Last_Location__c;
        
        // If we have the location name in the cache, use it
        if (locationId && locationCache[locationId]) {
          return locationCache[locationId];
        }
        
        // If not in cache and not already being fetched, trigger a fetch
        if (locationId && !pendingLocationFetches.current.has(locationId)) {
          // Use setTimeout to avoid state updates during render
          setTimeout(() => {
            fetchLocationName(locationId);
          }, 0);
          
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ mr: 1 }} />
              Loading...
            </Box>
          );
        }
        
        // If no location ID, show default text
        return params.row.Pure_Promote_Last_Location__c || 'None';
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'ownerName',
      headerName: 'Owner',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'billingStateCode',
      headerName: 'State',
      width: 120,
    },
    {
      field: 'industrySector',
      headerName: 'Industry',
      width: 150,
    }
  ];

  // Update VIP contacts filtering effect to include status and recent activity processing
  useEffect(() => {
    // Skip if not on VIP tab or if vipContacts is empty/null
    if (currentTab !== 1 || !vipContacts || !Array.isArray(vipContacts)) {
      return;
    }
    
    try {
      // Create a Map to track unique VIP contacts by email
      const uniqueVipContacts = new Map();
      
      // Filter and deduplicate VIP contacts
      vipContacts.forEach(contact => {
        if (!contact) return;
        
        // Skip contacts that have opted out
        if (contact.no_promote__c === true) return;
        
        // Filter by owner
        if (currentOwner !== 'all' && contact.ownerName !== currentOwner) return;
        
        // Filter by state
        if (currentState !== 'all' && contact.billingStateCode !== currentState) return;
        
        // Filter by industry
        if (currentIndustry !== 'all' && contact.industrySector !== currentIndustry) return;
        
        // Use email as unique identifier
        const uniqueKey = contact.email?.toLowerCase();
        if (!uniqueKey) return;
        
        // If we already have this contact, only update if this one is more recent
        const existingContact = uniqueVipContacts.get(uniqueKey);
        if (existingContact) {
          const existingDate = existingContact.Pure_Promote_Last_Contact__c ? new Date(existingContact.Pure_Promote_Last_Contact__c) : new Date(0);
          const newDate = contact.Pure_Promote_Last_Contact__c ? new Date(contact.Pure_Promote_Last_Contact__c) : new Date(0);
          
          if (newDate > existingDate) {
            uniqueVipContacts.set(uniqueKey, contact);
          }
        } else {
          uniqueVipContacts.set(uniqueKey, contact);
        }
      });
      
      // Convert Map values back to array and enhance contacts
      const filtered = Array.from(uniqueVipContacts.values()).map(contact => {
        // Apply the same contact enhancement logic as regular contacts
        const enhancedContact = renderContactRow(contact);
        
        return {
          ...enhancedContact,
          ownerName: contact.ownerName,
          Contact_Notes__c: contact.Contact_Notes__c || '',
          status: contact.Pure_Promote_Last_Contact__c ? 'contacted' : 'pending',
          recentActivity: contact.Pure_Promote_Last_Contact__c ? {
            sentTime: new Date(contact.Pure_Promote_Last_Contact__c),
            locationId: contact.Pure_Promote_Last_Location__c,
            isRecent: true
          } : null
        };
      });

      setFilteredVipContacts(filtered);
    } catch (error) {
      console.error('Error filtering VIP contacts:', error);
      setFilteredVipContacts([]);
    }
  }, [currentTab, vipContacts, currentOwner, currentState, currentIndustry, renderContactRow]);

  // Add effect to check test mode periodically
  useEffect(() => {
    const checkTestMode = () => {
      const currentTestMode = outlookService.getTestMode();
      setIsTestMode(currentTestMode);
    };

    // Check immediately
    checkTestMode();

    // Check every 5 seconds
    const interval = setInterval(checkTestMode, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ height: 'calc(100vh - 100px)', width: '100%', maxWidth: 'calc(100vw - 290px)', overflow: 'scroll' }}>
      {/* Instructions Accordion */}
      <Accordion sx={{ mb: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="instructions-content"
          id="instructions-header"
          sx={{
            backgroundColor: '#eaeaea',
            color: 'primary.main',
            '&:hover': {
              backgroundColor: '#eaeaea',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <InfoIcon />
            <Typography>How to Use Pure Promote</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6" gutterBottom>Quick Start Guide:</Typography>
            <Box component="ol" sx={{ pl: 2, '& > li': { mb: 1 } }}>
              <li>
                <Typography><strong>Select the related locations:</strong> From the <Link href="/locations" target="_blank" rel="noopener noreferrer">Locations</Link> page: Search for the location name, select it then click the ADD TO PURE PROMOTE button.</Typography>
              </li>
              <li>
                <Typography><strong>Generate your report:</strong> Click GENERATE REPORT button (bottom left) and choose OPEN IN PURE PROMOTE.</Typography>
              </li>
              <li>
                <Typography><strong>Select Location to Promote:</strong> Use the search box below to find and select the location you want to promote.</Typography>
              </li>
              <li>
                <Typography><strong>Choose Contacts:</strong> From the contact list, select the contacts you want to send the promotion to. The list shows their previous interactions and locations.</Typography>
              </li>
              <li>
                <Typography><strong>Review & Send Email:</strong> An AI-generated email will be created automatically. Review the content, make any necessary adjustments, and send the email.</Typography>
              </li>
            </Box>
            <Typography variant="h6" gutterBottom>Tips:</Typography>
            <Box component="ul" sx={{ pl: 2, '& > li': { mb: 1 } }}>
              <li>
                <Typography>Filter contacts by owner using the dropdown in the contact list.</Typography>
              </li>
              <li>
                <Typography>Green highlighted rows indicate contacts who have already received promotions.</Typography>
              </li>
              <li>
                <Typography>The email will automatically include location details and pricing if available.</Typography>
              </li>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Location Selector and Preview */}
      <Box sx={{ mb: 3 }}>
        <Autocomplete
          value={promotedLocation}
          onChange={handleLocationChange}
          options={availableLocations}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Location to Promote"
              placeholder="Start typing to search locations..."
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={`${option.name}-${option.accountId}`}>
              <Box>
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.address || 'No address'}
                </Typography>
              </Box>
            </Box>
          )}
          isOptionEqualToValue={(option, value) => option?.accountId === value?.accountId}
          loading={isLoading}
          disabled={isLoading}
        />

        {/* Location Preview */}
        {promotedLocation && (
          <Card sx={{ mt: 2 }}>
            <CardContent sx={{ 
              display: 'flex', 
              alignItems: 'flex-start', 
              gap: 2,
              p: 2,
              '&:last-child': { pb: 2 }
            }}>
              {promotedLocation.Website_Feature_Image_URL__c ? (
                <Box
                  component="img"
                  src={getProxiedImageUrl(promotedLocation.Website_Feature_Image_URL__c)}
                  alt={promotedLocation.name}
                  sx={{
                    width: 180,
                    height: 120,
                    objectFit: 'cover',
                    borderRadius: 1,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: 180,
                    height: 120,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'grey.100',
                    borderRadius: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem'
                  }}
                >
                  No image available
                </Box>
              )}
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 0.5 }}>
                  {promotedLocation.name}
                </Typography>
                {promotedLocation.Internal_Location_URL__c && (
                  <Link
                    href={promotedLocation.Internal_Location_URL__c}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      color: 'primary.main',
                      textDecoration: 'none',
                      mb: 1,
                      fontSize: '0.875rem',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    View Location Page <OpenInNewIcon sx={{ fontSize: '1rem' }} />
                  </Link>
                )}
                {promotedLocation.address && (
                  <Typography variant="body2" color="text.secondary">
                    {promotedLocation.address}
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>

      <Grid container spacing={2} sx={{ height: 'calc(100% - 120px)' }}>
        <Grid item xs={12} md={8} sx={{ height: "100%" }}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '0 0 auto', pb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Pure Promote Contact Lists</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {selectedContactIds.length > 0 && (
                    <Button 
                      size="small" 
                      color="secondary" 
                      variant="outlined"
                      onClick={() => handleContactSelection([])}
                    >
                      Clear Selection
                    </Button>
                  )}
                  <Tooltip title="Reset polling to fetch records from the last 24 hours">
                    <Button 
                      size="small" 
                      color="primary" 
                      variant="outlined"
                      onClick={() => {
                        // Reset lastPollTime to 24 hours ago
                        const pastDate = new Date();
                        pastDate.setHours(pastDate.getHours() - 24);
                        setLastPollTime(pastDate);
                        enqueueSnackbar('Poll timestamp reset to 24 hours ago. Recent team activity will appear in the next poll.', {
                          variant: 'info',
                          autoHideDuration: 5000
                        });
                      }}
                    >
                      Reset Poll History
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
              
              {/* Tabs navigation */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                <Tabs 
                  value={currentTab} 
                  onChange={handleTabChange} 
                  aria-label="pure promote tabs"
                  variant="standard"
                  sx={{ 
                    '& .MuiTab-root': { 
                      fontWeight: 'bold',
                      fontSize: '0.9rem' 
                    } 
                  }}
                >
                  <Tab label="Location Clients Contact List" id="pure-promote-tab-0" aria-controls="pure-promote-tabpanel-0" />
                  <Tab label="VIP Contact List" id="pure-promote-tab-1" aria-controls="pure-promote-tabpanel-1" />
                </Tabs>
              </Box>
            </CardContent>
            
            <Box sx={{ flex: '1 1 auto', minHeight: 0 }}>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {/* Clients Contact List Tab */}
                  <TabPanel value={currentTab} index={0}>
                    <DataGridPro
                      rows={enhancedContacts}
                      columns={updatedColumns}
                      density="comfortable"
                      pagination
                      checkboxSelection={false}
                      disableRowSelectionOnClick={false}
                      getRowId={(row) => row.id || row.email}
                      processRowUpdate={processRowUpdate}
                      onProcessRowUpdateError={handleProcessRowUpdateError}
                      slots={{
                        toolbar: CustomToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          onOwnerChange: handleOwnerChange,
                          currentOwner: currentOwner,
                          owners: owners,
                          onStateChange: handleStateChange,
                          currentState: currentState,
                          states: states,
                          onIndustryChange: handleIndustryChange,
                          currentIndustry: currentIndustry,
                          industries: industries,
                        },
                      }}
                      onRowClick={(params) => {
                        // If the row that was clicked is already selected, deselect it
                        if (selectedContactIds.includes(params.id)) {
                          handleContactSelection([]);
                        } else {
                          handleContactSelection([params.id]);
                        }
                      }}
                      selectionModel={selectedContactIds[0] || null}
                      getRowClassName={(params) => {
                        const isSelected = selectedContactIds.includes(params.id);
                        const isContacted = params.row.status === 'contacted';
                        
                        if (isSelected) return 'selected-row';
                        if (isContacted) return 'contacted-row';
                        return '';
                      }}
                      sx={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        '& .MuiDataGrid-root': {
                          border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                        },
                        '& .contacted-row': {
                          backgroundColor: 'rgba(76, 175, 80, 0.08)',
                          '&:hover': {
                            backgroundColor: 'rgba(76, 175, 80, 0.12)',
                          },
                        },
                        '& .selected-row': {
                          backgroundColor: 'rgba(25, 118, 210, 0.08)',
                          '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.12)',
                          },
                        },
                      }}
                      initialState={{
                        pagination: { pageSize: 25 },
                        sorting: {
                          sortModel: [{ field: 'lastInteraction', sort: 'desc' }],
                        },
                        columns: {
                          columnVisibilityModel: {
                            phone: false,
                            mobile: false,
                            contactId: false,
                            lastInteraction: false,
                            lastPromote: false,
                          },
                        },
                      }}
                      onStateChange={(params) => {
                        // Preserve custom column visibility settings
                        if (params.columns?.columnVisibilityModel) {
                          // Ensure lastInteraction stays hidden
                          params.columns.columnVisibilityModel.lastInteraction = false;
                        }
                      }}
                    />
                  </TabPanel>
                  
                  {/* VIP Contact List Tab */}
                  <TabPanel value={currentTab} index={1}>
                    {isLoadingVIP ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                      </Box>
                    ) : vipError ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', gap: 2 }}>
                        <Typography color="error">{vipError}</Typography>
                        <Button 
                          variant="contained" 
                          onClick={() => {
                            setCurrentTab(1); // This will trigger a reload
                          }}
                        >
                          Retry
                        </Button>
                      </Box>
                    ) : filteredVipContacts.length === 0 ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography>No VIP contacts found</Typography>
                      </Box>
                    ) : (
                      <DataGridPro
                        rows={filteredVipContacts}
                        columns={vipColumns}
                        density="comfortable"
                        slots={{
                          toolbar: CustomToolbar,
                          noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Typography>No VIP contacts found with the selected filters</Typography>
                            </Stack>
                          )
                        }}
                        slotProps={{
                          toolbar: {
                            onOwnerChange: handleOwnerChange,
                            currentOwner: currentOwner,
                            owners: vipOwners,
                            onStateChange: handleStateChange,
                            currentState: currentState,
                            states: vipStates,
                            onIndustryChange: handleIndustryChange,
                            currentIndustry: currentIndustry,
                            industries: vipIndustries,
                          },
                        }}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        getRowId={(row) => row.id}
                        loading={false}
                        disableRowSelectionOnClick={false}
                        checkboxSelection={false}
                        onRowClick={(params) => {
                          // If the row that was clicked is already selected, deselect it
                          if (selectedContactIds.includes(params.id)) {
                            handleContactSelection([]);
                          } else {
                            handleContactSelection([params.id]);
                          }
                        }}
                        selectionModel={selectedContactIds[0] || null}
                        getRowClassName={(params) => {
                          const isSelected = selectedContactIds.includes(params.id);
                          const isContacted = params.row.status === 'contacted';

                          if (isSelected) return 'selected-row';
                          if (isContacted) return 'contacted-row';
                          return '';
                        }}
                        sx={{
                          height: '100%',
                          width: '100%',
                          overflow: 'hidden',
                          '& .MuiDataGrid-root': {
                            border: 'none',
                          },
                          '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                          },
                          '& .contacted-row': {
                            backgroundColor: 'rgba(76, 175, 80, 0.08)',
                            '&:hover': {
                              backgroundColor: 'rgba(76, 175, 80, 0.12)',
                            },
                          },
                          '& .selected-row': {
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            '&:hover': {
                              backgroundColor: 'rgba(25, 118, 210, 0.12)',
                            },
                          },
                        }}
                        initialState={{
                          pagination: { pageSize: 25 },
                          sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                          },
                          columns: {
                            columnVisibilityModel: {
                              phone: false,
                              mobile: false,
                              contactId: false,
                            },
                          },
                        }}
                      />
                    )}
                  </TabPanel>
                </>
              )}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} sx={{ height: "100%" }}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '0 0 auto', pb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="h6">
                    Email Composer
                  </Typography>
                  {isTestMode && (
                    <Chip 
                      label="TEST MODE" 
                      color="warning"
                      size="small"
                      sx={{ 
                        fontWeight: 'bold',
                        animation: 'pulse 2s infinite',
                        '@keyframes pulse': {
                          '0%': { opacity: 1 },
                          '50%': { opacity: 0.6 },
                          '100%': { opacity: 1 }
                        }
                      }}
                    />
                  )}
                  {isGenerating && (
                    <CircularProgress size={20} sx={{ ml: 2 }} />
                  )}
                </Box>
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="To"
                  value={emailData.to}
                  onChange={handleEmailChange('to')}
                  fullWidth
                  size="small"
                  disabled={true}
                />
                <TextField
                  label="CC"
                  value={emailData.cc}
                  onChange={handleEmailChange('cc')}
                  fullWidth
                  size="small"
                />
                <TextField
                  label="Subject"
                  value={emailData.subject}
                  onChange={handleEmailChange('subject')}
                  fullWidth
                  size="small"
                />
                <Box sx={{ 
                  flex: 1,
                  minHeight: '320px',
                  '& .quill': {
                    height: '320px',
                    display: 'flex',
                    flexDirection: 'column'
                  },
                  '& .ql-container': {
                    flex: 1,
                    overflow: 'auto'
                  }
                }}>
                  <Editor
                    ref={editorRef}
                    value={emailData.body}
                    onChange={(content) => handleEmailChange('body')(content)}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['link'],
                        ['clean']
                      ]
                    }}
                    placeholder="Compose your email..."
                    theme="snow"
                    preserveWhitespace
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 0 }}>
                  <Button
                    variant="contained"
                    startIcon={<SendIcon />}
                    onClick={() => {
                      if (selectedContactIds[0] && emailData.selectedContact) {
                        handleSendEmail(emailData.selectedContact);
                      } else {
                        enqueueSnackbar('Please select a contact first', { variant: 'error' });
                      }
                    }}
                    disabled={!emailData.to || !emailData.subject || !emailData.body || isGenerating}
                  >
                    Send Promote Email
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PurePromote; 