import salesforceService from './salesforceService';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const API_BASE_URL = 'https://bdm.purelocations.com.au/api/sf';

class PurePromoteService {
  constructor() {
    this.salesforceService = salesforceService;
    this.currentReportData = null;
  }

  // Add a diagnostic method to check if any data exists for a location with less restrictive filters
  async testLocationDataExists(locationId) {
    try {
      // Ensure we're authenticated with Salesforce
      if (!this.salesforceService.accessToken) {
        await this.salesforceService.authenticate();
      }

      // Test query with minimal filters
      const testQuery = `
        SELECT Id, Name, StageName, CloseDate, Primary_Contact__c,
               Primary_Contact__r.Name, Primary_Contact__r.Email,
               Primary_Contact__r.no_promote__c, Primary_Contact__r.Account.BillingState, Primary_Contact__r.Account.Industry_Sector__c
        FROM Opportunity 
        WHERE Location_Name__c = '${locationId}'
        ORDER BY CloseDate DESC
        LIMIT 10
      `;

      // console.log('Executing diagnostic test query:', testQuery);

      const response = await axios.post(`${API_BASE_URL}/query`, 
        { query: testQuery },
        { 
          headers: {
            'Authorization': `Bearer ${this.salesforceService.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      // console.log(`DIAGNOSTIC: Found ${response.data.records.length} basic opportunities for location ${locationId}`);
      
      if (response.data.records.length > 0) {
        // Log all opportunities for debugging
        // console.log('Recent opportunities for this location:');
        response.data.records.forEach(opp => {
          // console.log(`- ${opp.Name}, Stage: ${opp.StageName}, CloseDate: ${opp.CloseDate}, Has Primary Contact: ${!!opp.Primary_Contact__c}`);
          if (opp.Primary_Contact__r) {
            // console.log(`  Contact: ${opp.Primary_Contact__r.Name}, Email: ${opp.Primary_Contact__r.Email}, No Promote: ${opp.Primary_Contact__r.no_promote__c}`);
          } else {
            // console.log(`  No Primary Contact relationship data available`);
          }
        });
        
        // Check if opportunities exist with different stage values
        const stageQuery = `
          SELECT StageName, COUNT(Id) recordCount
          FROM Opportunity 
          WHERE Location_Name__c = '${locationId}'
          GROUP BY StageName
        `;
        
        const stageResponse = await axios.post(`${API_BASE_URL}/query`, 
          { query: stageQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        // console.log('Stage breakdown:', stageResponse.data.records);
        
        // Check if any opportunities would match the LAST_N_YEARS:2 filter
        // console.log('Checking for opportunities using LAST_N_YEARS:2 filter:');
        const lastYearsQuery = `
          SELECT COUNT(Id) count
          FROM Opportunity 
          WHERE Location_Name__c = '${locationId}'
          AND StageName IN ('Closed Won', 'Closed Lost')
          AND CloseDate = LAST_N_YEARS:2
        `;
        
        const lastYearsResponse = await axios.post(`${API_BASE_URL}/query`, 
          { query: lastYearsQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        const lastYearsCount = lastYearsResponse.data.records[0]?.count || 0;
        // console.log(`Found ${lastYearsCount} opportunities matching LAST_N_YEARS:2 filter`);
        
        // Check if any opportunities would match the THIS_YEAR filter
        // console.log('Checking for opportunities using THIS_YEAR filter:');
        const thisYearQuery = `
          SELECT COUNT(Id) count
          FROM Opportunity 
          WHERE Location_Name__c = '${locationId}'
          AND StageName IN ('Closed Won', 'Closed Lost')
          AND CloseDate = THIS_YEAR
        `;
        
        const thisYearResponse = await axios.post(`${API_BASE_URL}/query`, 
          { query: thisYearQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        const thisYearCount = thisYearResponse.data.records[0]?.count || 0;
        // console.log(`Found ${thisYearCount} opportunities matching THIS_YEAR filter`);
        
        // Check specific Closed Won opportunities from 2025
        // console.log('Checking specifically for 2025 Closed Won opportunities:');
        const closedWonQuery = `
          SELECT Id, Name, CloseDate, Primary_Contact__r.Name, Primary_Contact__r.no_promote__c
          FROM Opportunity 
          WHERE Location_Name__c = '${locationId}'
          AND StageName = 'Closed Won'
          AND CALENDAR_YEAR(CloseDate) = 2025
          ORDER BY CloseDate DESC
        `;
        
        const closedWonResponse = await axios.post(`${API_BASE_URL}/query`, 
          { query: closedWonQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        // console.log(`Found ${closedWonResponse.data.records.length} Closed Won opportunities from 2025`);
        
        if (closedWonResponse.data.records.length > 0) {
          closedWonResponse.data.records.forEach(opp => {
            // console.log(`- ${opp.Name}, CloseDate: ${opp.CloseDate}`);
            if (opp.Primary_Contact__r) {
              // console.log(`  Contact: ${opp.Primary_Contact__r.Name}, No Promote: ${opp.Primary_Contact__r.no_promote__c}`);
              
              // This would explain why they're not showing up
              if (opp.Primary_Contact__r.no_promote__c === true) {
                // console.log(`  REASON: This contact has no_promote__c = true, so it's being excluded from the report`);
              }
            } else {
              // console.log(`  REASON: No Primary Contact relationship data available`);
            }
          });
        }
        
        // Check date range for all opportunities
        const dateQuery = `
          SELECT CALENDAR_YEAR(CloseDate) year, COUNT(Id) recordCount
          FROM Opportunity 
          WHERE Location_Name__c = '${locationId}'
          AND StageName IN ('Closed Won', 'Closed Lost')
          GROUP BY CALENDAR_YEAR(CloseDate)
          ORDER BY CALENDAR_YEAR(CloseDate)
        `;
        
        const dateResponse = await axios.post(`${API_BASE_URL}/query`, 
          { query: dateQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        // console.log('Year breakdown for closed opps:', dateResponse.data.records);
        
        // Check if primary contacts have no_promote flag
        const noPromoteQuery = `
          SELECT COUNT(Id) recordCount
          FROM Opportunity 
          WHERE Location_Name__c = '${locationId}'
          AND StageName IN ('Closed Won', 'Closed Lost')
          AND (CloseDate = LAST_N_YEARS:2 OR CloseDate = THIS_YEAR)
          AND Primary_Contact__r.no_promote__c = true
        `;
        
        const noPromoteResponse = await axios.post(`${API_BASE_URL}/query`, 
          { query: noPromoteQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (noPromoteResponse.data.records.length > 0) {
          const excludedCount = noPromoteResponse.data.records[0].recordCount || 0;
          // console.log(`Found ${excludedCount} opportunities excluded due to no_promote__c = true`);
        }
      }

      return response.data.records.length > 0;
    } catch (error) {
      console.error('Error in diagnostic test:', error);
      return false;
    }
  }

  /**
   * Process and group data by owner
   */
  processDataByOwner(opportunities, potentialLocations) {
    // Validate input data structure
    if (!Array.isArray(opportunities)) {
      console.error('CRITICAL ERROR: processDataByOwner received invalid opportunities data', opportunities);
      throw new Error('PurePromote contact generation failed: Invalid opportunity data');
    }

    // console.log(`Processing ${opportunities.length} opportunities and ${potentialLocations.length} potential locations`);
    
    // Log all opportunity names for debugging
    if (opportunities.length > 0) {
      // console.log('Opportunity names:', opportunities.map(opp => opp.Name).join(', '));
    }
    
    // Verify the data structure of the first opportunity to ensure it has all required fields
    if (opportunities.length > 0) {
      const firstOpp = opportunities[0];
      // Check for the critical fields needed for PurePromote contacts
      if (!firstOpp.Primary_Contact__r || !firstOpp.Location_Name__r) {
        console.error('CRITICAL ERROR: Opportunity data is missing required fields for PurePromote', {
          hasLocationData: !!firstOpp.Location_Name__r,
          hasContactData: !!firstOpp.Primary_Contact__r,
          opportunity: firstOpp
        });
        console.warn('This likely indicates that the opportunity structure was modified somewhere else in the application');
        // Log a stack trace to help identify where this was called from
        console.trace('PurePromote data validation failed');
      } else {
        console.log('First opportunity has valid data structure', {
          opportunityName: firstOpp.Name,
          locationName: firstOpp.Location_Name__r?.Name,
          contactName: firstOpp.Primary_Contact__r?.Name,
          contactEmail: firstOpp.Primary_Contact__r?.Email
        });
      }
    }

    // Create a map to store data by owner
    const ownerMap = new Map();

    // Count opportunities processed and contacts created
    let opportunitiesProcessed = 0;
    let contactsCreated = 0;
    let skippedDueToNoEmail = 0;
    let skippedDueToPromotion = 0;
    
    // Add a safe data extraction function to handle undefined values
    const safeGet = (obj, path, defaultValue = '') => {
      if (!obj) return defaultValue;
      const keys = path.split('.');
      let result = obj;
      for (const key of keys) {
        if (result === undefined || result === null) return defaultValue;
        result = result[key];
      }
      return result !== undefined && result !== null ? result : defaultValue;
    };

    // Process opportunities
    opportunities.forEach(opp => {
      try {
        // Skip if contact has no_promote__c = true
        if (opp.Primary_Contact__r?.no_promote__c === true) {
          skippedDueToPromotion++;
          return;
        }

        if (!opp.Primary_Contact__r?.Email) {
          skippedDueToNoEmail++;
          return;
        }

        const ownerName = opp.Account?.Owner?.Name || 'Unassigned';
        const ownerEmail = opp.Account?.Owner?.Email;
        
        if (!ownerMap.has(ownerName)) {
          ownerMap.set(ownerName, {
            opportunities: [],
            potentialLocations: [],
            contactsByEmail: new Map(),
            ownerEmail: ownerEmail,
            ownerId: opp.Account?.OwnerId,
            billingStateCode: safeGet(opp, 'Primary_Contact__r.Account.BillingStateCode'),
            industrySector: safeGet(opp, 'Primary_Contact__r.Account.Industry_Sectors__c')
          });
        }

        opportunitiesProcessed++;
        const ownerData = ownerMap.get(ownerName);
        ownerData.opportunities.push({
          id: opp.Id,
          name: opp.Name,
          stage: opp.StageName,
          closeDate: opp.CloseDate,
          accountName: opp.Account?.Name,
          locationName: opp.Location_Name__r?.Name,
          locationUrl: opp.Location_Name__r?.Internal_Location_URL__c,
          contact: {
            name: opp.Primary_Contact__r?.Name,
            firstName: opp.Primary_Contact__r?.FirstName,
            lastName: opp.Primary_Contact__r?.LastName,
            email: opp.Primary_Contact__r?.Email,
            phone: opp.Primary_Contact__r?.Phone,
            mobile: opp.Primary_Contact__r?.MobilePhone
          },
          billingStateCode: safeGet(opp, 'Primary_Contact__r.Account.BillingStateCode') || '',
          industrySector: safeGet(opp, 'Primary_Contact__r.Account.Industry_Sectors__c') || ''
        });

        // Add or update contact if it exists
        if (opp.Primary_Contact__r?.Email) {
          const email = opp.Primary_Contact__r.Email.toLowerCase();
          const existingContact = ownerData.contactsByEmail.get(email);
          
          const contactData = {
            name: opp.Primary_Contact__r?.Name || '',
            firstName: opp.Primary_Contact__r?.FirstName || '',
            lastName: opp.Primary_Contact__r?.LastName || '',
            email: email,
            phone: opp.Primary_Contact__r?.Phone || (existingContact?.phone || ''),
            mobile: opp.Primary_Contact__r?.MobilePhone || (existingContact?.mobile || ''),
            company: opp.Account?.Name || (existingContact?.company || ''),
            locationName: opp.Location_Name__r?.Name || (existingContact?.locationName || ''),
            locationUrl: opp.Location_Name__r?.Internal_Location_URL__c || (existingContact?.locationUrl || ''),
            interactions: existingContact?.interactions || [],
            sources: existingContact?.sources || new Set(),
            ownerEmail: ownerEmail,
            ownerId: opp.Account?.OwnerId,
            Id: opp.Primary_Contact__r?.Id,
            contactId: opp.Primary_Contact__r?.Id,
            Pure_Promote_Last_Contact__c: opp.Primary_Contact__r?.Pure_Promote_Last_Contact__c || existingContact?.Pure_Promote_Last_Contact__c || null,
            Pure_Promote_Last_Location__c: opp.Primary_Contact__r?.Pure_Promote_Last_Location__c || existingContact?.Pure_Promote_Last_Location__c || null,
            Contact_Notes__c: opp.Primary_Contact__r?.Contact_Notes__c || existingContact?.Contact_Notes__c || '',
            owner: ownerName,
            billingStateCode: safeGet(opp, 'Primary_Contact__r.Account.BillingStateCode') || existingContact?.billingStateCode || '',
            industrySector: safeGet(opp, 'Primary_Contact__r.Account.Industry_Sectors__c') || existingContact?.industrySector || '',
          };

          // Add this interaction
          contactData.interactions.push({
            date: opp.CloseDate,
            type: 'Opportunity',
            name: opp.Name
          });
          contactData.sources.add('Opportunity');

          ownerData.contactsByEmail.set(email, contactData);
          contactsCreated++;
        }
      } catch (error) {
        console.error('Error processing opportunity:', { error, opportunityId: opp.Id, name: opp.Name });
      }
    });

    // Process potential locations
    potentialLocations.forEach(pl => {
      try {
        // Skip if contact has no_promote__c = true
        if (pl.Opportunity__r?.Primary_Contact__r?.no_promote__c === true) {
          return;
        }

        console.log('Processing potential location:', {
          id: pl.Id,
          name: pl.Name,
          enquiryContact: {
            email: pl.Enquiry_Contact_Email__c,
            firstName: pl.Enquiry_Contact_First_Name__c,
            lastName: pl.Enquiry_Contact_Last_Name__c
          },
          opportunityContact: {
            id: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Id'),
            name: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Name'),
            email: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Email')
          },
          owner: {
            name: safeGet(pl, 'Opportunity__r.Account.Owner.Name'),
            email: safeGet(pl, 'Opportunity__r.Account.Owner.Email')
          }
        });

        const ownerName = pl.Opportunity__r?.Account?.Owner?.Name || 'Unassigned';
        const ownerEmail = pl.Opportunity__r?.Account?.Owner?.Email;
        
        if (!ownerMap.has(ownerName)) {
          ownerMap.set(ownerName, {
            opportunities: [],
            potentialLocations: [],
            contactsByEmail: new Map(),
            ownerEmail: ownerEmail,
            ownerId: pl.Opportunity__r?.Account?.OwnerId,
            billingStateCode: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.BillingStateCode'),
            industrySector: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.Industry_Sectors__c')
          });
        }

        const ownerData = ownerMap.get(ownerName);
        ownerData.potentialLocations.push({
          id: pl.Id,
          name: pl.Name,
          stage: pl.Recce_Stage__c,
          createdDate: pl.CreatedDate,
          opportunityName: safeGet(pl, 'Opportunity__r.Name'),
          accountName: safeGet(pl, 'Opportunity__r.Account.Name'),
          locationName: safeGet(pl, 'Account__r.Name'),
          locationUrl: safeGet(pl, 'Account__r.Internal_Location_URL__c'),
          contact: {
            firstName: pl.Enquiry_Contact_First_Name__c || '',
            lastName: pl.Enquiry_Contact_Last_Name__c || '',
            email: pl.Enquiry_Contact_Email__c || '',
            phone: '',
            mobile: '',
            opportunityContact: {
              name: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Name'),
              email: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Email'),
              phone: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Phone'),
              mobile: safeGet(pl, 'Opportunity__r.Primary_Contact__r.MobilePhone')
            },
            billingStateCode: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.BillingStateCode'),
            industrySector: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.Industry_Sectors__c')
          }
        });

        // Process enquiry contact if it exists
        if (pl.Enquiry_Contact_Email__c) {
          const email = pl.Enquiry_Contact_Email__c.toLowerCase();
          const existingContact = ownerData.contactsByEmail.get(email);
          
          const contactData = {
            name: `${pl.Enquiry_Contact_First_Name__c || ''} ${pl.Enquiry_Contact_Last_Name__c || ''}`.trim(),
            firstName: pl.Enquiry_Contact_First_Name__c,
            lastName: pl.Enquiry_Contact_Last_Name__c,
            email: email,
            phone: pl.Opportunity__r?.Primary_Contact__r?.Phone || existingContact?.phone || '',
            mobile: pl.Opportunity__r?.Primary_Contact__r?.MobilePhone || existingContact?.mobile || '',
            company: pl.Opportunity__r?.Account?.Name || (existingContact?.company || ''),
            locationName: pl.Account__r?.Name || (existingContact?.locationName || ''),
            locationUrl: pl.Account__r?.Internal_Location_URL__c || (existingContact?.locationUrl || ''),
            interactions: existingContact?.interactions || [],
            sources: existingContact?.sources || new Set(),
            ownerEmail: ownerEmail,
            ownerId: pl.Opportunity__r?.Account?.OwnerId || existingContact?.ownerId || null,
            Id: pl.Opportunity__r?.Primary_Contact__r?.Id || existingContact?.Id || null,
            contactId: pl.Opportunity__r?.Primary_Contact__r?.Id || existingContact?.contactId || null,
            Pure_Promote_Last_Contact__c: pl.Opportunity__r?.Primary_Contact__r?.Pure_Promote_Last_Contact__c || existingContact?.Pure_Promote_Last_Contact__c || null,
            Pure_Promote_Last_Location__c: pl.Opportunity__r?.Primary_Contact__r?.Pure_Promote_Last_Location__c || existingContact?.Pure_Promote_Last_Location__c || null,
            Contact_Notes__c: pl.Opportunity__r?.Primary_Contact__r?.Contact_Notes__c || existingContact?.Contact_Notes__c || '',
            owner: ownerName,
            billingStateCode: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.BillingStateCode') || existingContact?.billingStateCode || '',
            industrySector: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.Industry_Sectors__c') || existingContact?.industrySector || '',
          };

          // Add this interaction
          contactData.interactions.push({
            date: pl.CreatedDate,
            type: 'Potential Location',
            name: pl.Opportunity__r?.Name || pl.Name
          });
          contactData.sources.add('Potential Location');

          ownerData.contactsByEmail.set(email, contactData);
        }

        // Process opportunity contact if different from enquiry contact
        if (pl.Opportunity__r?.Primary_Contact__r?.Email && 
            (!pl.Enquiry_Contact_Email__c || 
             pl.Opportunity__r.Primary_Contact__r.Email.toLowerCase() !== pl.Enquiry_Contact_Email__c.toLowerCase())) {
          
          const email = pl.Opportunity__r.Primary_Contact__r.Email.toLowerCase();
          const existingContact = ownerData.contactsByEmail.get(email);
          
          const contactData = {
            name: pl.Opportunity__r.Primary_Contact__r.Name,
            email: email,
            phone: pl.Opportunity__r.Primary_Contact__r.Phone || (existingContact?.phone || ''),
            mobile: pl.Opportunity__r.Primary_Contact__r.MobilePhone || (existingContact?.mobile || ''),
            company: pl.Opportunity__r?.Account?.Name || (existingContact?.company || ''),
            interactions: existingContact?.interactions || [],
            sources: existingContact?.sources || new Set(),
            ownerEmail: ownerEmail,
            ownerId: pl.Opportunity__r?.Account?.OwnerId,
            Id: pl.Opportunity__r.Primary_Contact__r.Id,
            contactId: pl.Opportunity__r.Primary_Contact__r.Id,
            Pure_Promote_Last_Contact__c: pl.Opportunity__r.Primary_Contact__r.Pure_Promote_Last_Contact__c,
            Pure_Promote_Last_Location__c: pl.Opportunity__r.Primary_Contact__r.Pure_Promote_Last_Location__c,
            Contact_Notes__c: pl.Opportunity__r.Primary_Contact__r.Contact_Notes__c || existingContact?.Contact_Notes__c || '',
            owner: ownerName,
            billingStateCode: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.BillingStateCode') || existingContact?.billingStateCode || '',
            industrySector: safeGet(pl, 'Opportunity__r.Primary_Contact__r.Account.Industry_Sectors__c') || existingContact?.industrySector || '',
          };

          // Add this interaction
          contactData.interactions.push({
            date: pl.CreatedDate,
            type: 'Potential Location - Opportunity Contact',
            name: pl.Opportunity__r?.Name || pl.Name
          });
          contactData.sources.add('Potential Location - Opportunity Contact');

          ownerData.contactsByEmail.set(email, contactData);
        }
      } catch (error) {
        console.error('Error processing potential location:', { error, pl_id: pl.Id, pl_name: pl.Name });
      }
    });

    // Log processing summary
    console.log('Processing summary:', {
      opportunitiesInput: opportunities.length,
      opportunitiesProcessed,
      contactsCreated,
      skippedDueToNoEmail,
      skippedDueToPromotion,
      ownersCreated: ownerMap.size
    });

    // Convert Maps and Sets to arrays and sort
    const result = {};
    ownerMap.forEach((data, ownerName) => {
      // Convert contacts map to array and sort by email
      const contacts = Array.from(data.contactsByEmail.values())
        .map(contact => {
          // Sort interactions by date to get the most recent
          const sortedInteractions = contact.interactions.sort((a, b) => 
            new Date(b.date) - new Date(a.date)
          );
          
          return {
            ...contact,
            lastInteraction: sortedInteractions[0]?.date,
            source: Array.from(contact.sources).join(', '),
            opportunityName: sortedInteractions[0]?.name || '',
            ownerEmail: contact.ownerEmail || data.ownerEmail,
            billingStateCode: contact.billingStateCode || data.billingStateCode,
            industrySector: contact.industrySector || data.industrySector
          };
        })
        .sort((a, b) => a.email.localeCompare(b.email));

      result[ownerName] = {
        opportunities: data.opportunities,
        potentialLocations: data.potentialLocations,
        contacts: contacts,
        ownerEmail: data.ownerEmail,
        ownerId: data.ownerId,
        billingStateCode: data.billingStateCode,
        industrySector: data.industrySector
      };
    });

    // Add metadata about the result
    result.__metadata = {
      hasData: true,
      totalOpportunities: Object.values(result).reduce((count, ownerData) => 
        count + (ownerData.opportunities?.length || 0), 0),
      totalPotentialLocations: Object.values(result).reduce((count, ownerData) => 
        count + (ownerData.potentialLocations?.length || 0), 0),
      totalContacts: Object.values(result).reduce((count, ownerData) => 
        count + (ownerData.contacts?.length || 0), 0),
      locationCount: Object.keys(result).length,
      locations: Object.keys(result).map(loc => loc)
    };

    // Don't reference the metadata's properties until they are defined
    return result;
  }

  /**
   * Fetches all required data from Salesforce for the location
   */
  async fetchLocationData(locationId, locationName) {
    try {
      // Ensure authentication
      if (!this.salesforceService.accessToken) {
        console.log('Authenticating with Salesforce...');
        await this.salesforceService.authenticate();
      }
      
      // First, run a test to see if ANY data exists for this location
      // console.log(`Running pre-check for location: ${locationName}...`);
      const testQuery = `
        SELECT COUNT(Id)
        FROM Opportunity
        WHERE Location_Name__c = '${locationId}'
      `;
      
      const testResponse = await axios.post(`${API_BASE_URL}/query`, 
        { query: testQuery },
        { 
          headers: {
            'Authorization': `Bearer ${this.salesforceService.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      const totalOpportunities = testResponse.data.records[0]?.expr0 || 0;
      // console.log(`PRE-CHECK: Location has ${totalOpportunities} total opportunities`);
      
      // Also check if any are closed won/lost (without the no_promote filter)
      const closedCheckQuery = `
        SELECT COUNT(Id)
        FROM Opportunity
        WHERE Location_Name__c = '${locationId}'
        AND StageName IN ('Closed Won', 'Closed Lost')
        AND (CloseDate = LAST_N_YEARS:2 OR CloseDate = THIS_YEAR)
      `;
      
      const closedCheckResponse = await axios.post(`${API_BASE_URL}/query`, 
        { query: closedCheckQuery },
        { 
          headers: {
            'Authorization': `Bearer ${this.salesforceService.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      const totalClosedOpportunities = closedCheckResponse.data.records[0]?.expr0 || 0;
      // console.log(`PRE-CHECK: Location has ${totalClosedOpportunities} closed opportunities in last 2 years plus this year`);

      // Fetch opportunities for the location
      const opportunityQuery = `
        SELECT 
          Id,
          Name,
          Owner.Name,
          Account.Name,
          Account.Id,
          Account.Owner.Name,
          Account.Owner.Email,
          Account.OwnerId,
          Primary_Contact__r.FirstName,
          Primary_Contact__r.LastName,
          Primary_Contact__r.Email,
          Primary_Contact__r.Name,
          Primary_Contact__r.Phone,
          Primary_Contact__r.MobilePhone,
          Primary_Contact__r.Id,
          Primary_Contact__r.Pure_Promote_Last_Contact__c,
          Primary_Contact__r.Pure_Promote_Last_Location__c,
          Primary_Contact__r.Contact_Notes__c,
          Primary_Contact__r.no_promote__c,
          Primary_Contact__r.Account.BillingStateCode,
          Primary_Contact__r.Account.Industry_Sectors__c,
          StageName,
          CloseDate,
          Loss_Reason__c,
          Location_Name__r.Name,
          Location_Name__r.Internal_Location_URL__c,
          Location_Name__r.Website_Feature_Image_URL__c
        FROM Opportunity
        WHERE Location_Name__c = '${locationId}'
        AND StageName IN ('Closed Won', 'Closed Lost')
        AND (CloseDate = LAST_N_YEARS:2 OR CloseDate = THIS_YEAR)
        AND (Primary_Contact__r.no_promote__c = false OR Primary_Contact__r.no_promote__c = null)
        ORDER BY Owner.Name, CloseDate DESC
        LIMIT 2000
      `;

      // Add debug logging for the query
      console.log('Executing opportunity query:', opportunityQuery);

      // Fetch potential locations for the location
      const potentialLocationsQuery = `
        SELECT 
          Id,
          Name,
          Enquiry_Contact_Email__c,
          Enquiry_Contact_First_Name__c,
          Enquiry_Contact_Last_Name__c,
          Recce_Stage__c,
          CreatedDate,
          Account__r.Name,
          Account__r.Internal_Location_URL__c,
          Account__r.Website_Feature_Image_URL__c,
          Opportunity__r.Id,
          Opportunity__r.Name,
          Opportunity__r.Primary_Contact__r.FirstName,
          Opportunity__r.Primary_Contact__r.LastName,
          Opportunity__r.Primary_Contact__r.Email,
          Opportunity__r.Primary_Contact__r.Phone,
          Opportunity__r.Primary_Contact__r.MobilePhone,
          Opportunity__r.Primary_Contact__r.Id,
          Opportunity__r.Primary_Contact__r.Pure_Promote_Last_Contact__c,
          Opportunity__r.Primary_Contact__r.Pure_Promote_Last_Location__c,
          Opportunity__r.Primary_Contact__r.Contact_Notes__c,
          Opportunity__r.Primary_Contact__r.no_promote__c,
          Opportunity__r.Primary_Contact__r.Account.BillingStateCode,
          Opportunity__r.Primary_Contact__r.Account.Industry_Sectors__c,
          Opportunity__r.Account.Id,
          Opportunity__r.Account.Name,
          Opportunity__r.Account.Owner.Name,
          Opportunity__r.Account.Owner.Email,
          Opportunity__r.Account.OwnerId
        FROM Potential_Locations__c
        WHERE Account__c = '${locationId}'
        AND (CreatedDate = LAST_N_YEARS:2 OR CreatedDate = THIS_YEAR)
        AND (Opportunity__r.Primary_Contact__r.no_promote__c = false OR Opportunity__r.Primary_Contact__r.no_promote__c = null)
        ORDER BY Opportunity__r.Account.Owner.Name, CreatedDate DESC
        LIMIT 2000
      `;

      // console.log('Executing opportunity query:', opportunityQuery);
      // console.log('Executing potential locations query:', potentialLocationsQuery);

      // Execute both queries in parallel
      const [opportunityResponse, potentialLocationsResponse] = await Promise.all([
        axios.post(`${API_BASE_URL}/query`, 
          { query: opportunityQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        ),
        axios.post(`${API_BASE_URL}/query`, 
          { query: potentialLocationsQuery },
          { 
            headers: {
              'Authorization': `Bearer ${this.salesforceService.accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
      ]);

      // Calculate how many were excluded due to no_promote flag
      const excludedDueToNoPromote = totalClosedOpportunities - opportunityResponse.data.records.length;
      // console.log(`RESULTS: Found ${opportunityResponse.data.records.length} opportunities that match all criteria`);
      
      if (excludedDueToNoPromote > 0) {
        // console.log(`WARNING: ${excludedDueToNoPromote} opportunities were excluded because they have Primary_Contact__r.no_promote__c = true`);
      }

      // After successfully getting results, log the count of records
      // console.log(`Location ID ${locationId} returned: ${opportunityResponse.data.records.length} opportunities and ${potentialLocationsResponse.data.records.length} potential locations`);

      // Process and group the data by owner
      const groupedData = this.processDataByOwner(
        opportunityResponse.data.records,
        potentialLocationsResponse.data.records
      );

      // If we found no data but we know opportunities exist, run the detailed diagnostic
      if (opportunityResponse.data.records.length === 0 && totalOpportunities > 0) {
        // console.log('Detailed diagnostics required - running tests...');
        await this.testLocationDataExists(locationId);
      }

      // Add detailed logging
      console.log('Fetching data for location:', {
        locationId,
        locationName,
        timestamp: new Date().toISOString()
      });

      return {
        opportunities: opportunityResponse.data.records,
        potentialLocations: potentialLocationsResponse.data.records,
        groupedByOwner: groupedData,
        metadata: {
          totalOpportunities: totalOpportunities,
          totalClosedOpportunities: totalClosedOpportunities,
          excludedDueToNoPromote: excludedDueToNoPromote,
          opportunityCount: opportunityResponse.data.records.length,
          potentialLocationCount: potentialLocationsResponse.data.records.length
        }
      };
    } catch (error) {
      console.error('Error fetching location data:', error);
      throw new Error('Failed to fetch location data from Salesforce');
    }
  }

  /**
   * Generate Excel workbook from grouped data
   */
  generateExcel(groupedData, locationName) {
    const workbook = XLSX.utils.book_new();

    // Create a summary sheet
    const summaryData = [
      ['Location Pure Promote Report'],
      ['Location:', locationName],
      ['Generated:', new Date().toLocaleString()],
      [''],
      ['Account Owner', 'Total Contacts']
    ];

    // Add summary data for each owner
    Object.entries(groupedData).forEach(([ownerName, data]) => {
      summaryData.push([
        ownerName,
        data.contacts.length
      ]);
    });

    const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary');

    // Create a sheet for each owner
    Object.entries(groupedData).forEach(([ownerName, data]) => {
      // Contact list sheet
      const contactsData = [
        ['Contact List'],
        ['Name', 'Email', 'Phone', 'Mobile', 'Company', 'Location', 'Billing State Code', 'Industry Sector', 'Last Interaction', 'Source', 'Opportunity', 'Owner', 'Actioned', 'Comments']
      ];

      data.contacts.forEach(contact => {
        contactsData.push([
          contact.name || `${contact.firstName || ''} ${contact.lastName || ''}`.trim(),
          contact.email || '',
          contact.phone || '',
          contact.mobile || '',
          contact.company || '',
          contact.locationName || '',
          contact.billingStateCode || '',
          contact.industrySector || '',
          contact.lastInteraction ? new Date(contact.lastInteraction).toLocaleDateString() : '',
          contact.source || '',
          contact.opportunityName || '',
          ownerName,
          false,
          ''
        ]);
      });

      // Create the sheet
      const contactSheet = XLSX.utils.aoa_to_sheet(contactsData);

      // Set column widths
      const columnWidths = [
        { wch: 30 }, // Name
        { wch: 35 }, // Email
        { wch: 15 }, // Phone
        { wch: 15 }, // Mobile
        { wch: 30 }, // Company
        { wch: 30 }, // Location
        { wch: 15 }, // Billing State Code
        { wch: 15 }, // Industry Sector
        { wch: 15 }, // Last Interaction
        { wch: 25 }, // Source
        { wch: 40 }, // Opportunity
        { wch: 25 }, // Owner
        { wch: 10 }, // Actioned
        { wch: 30 }  // Comments
      ];
      contactSheet['!cols'] = columnWidths;

      // Add data validation for Actioned column (dropdown)
      if (!contactSheet['!dataValidation']) {
        contactSheet['!dataValidation'] = {};
      }

      // Calculate the range for data validation (skip header rows)
      const startRow = 3; // Start after headers
      const endRow = data.contacts.length + 2; // Add 2 for header rows
      
      // Add data validation for each cell in the Actioned column (column I)
      for (let row = startRow; row <= endRow; row++) {
        const cellRef = `I${row}`;
        contactSheet['!dataValidation'][cellRef] = {
          type: 'list',
          operator: 'equal',
          formula1: '"TRUE,FALSE"',
          showDropdown: true
        };
      }

      // Add the sheet to the workbook
      XLSX.utils.book_append_sheet(workbook, contactSheet, ownerName.substring(0, 31));
    });

    return workbook;
  }

  /**
   * Main function to generate and download the report
   */
  async generateReport(locationId, locationName) {
    try {
  
      // Fetch data
      const rawData = await this.fetchLocationData(locationId, locationName);
      
      // Check if we found any opportunities or potential locations
      const totalOpportunitiesFound = rawData.opportunities.length;
      const totalPotentialLocationsFound = rawData.potentialLocations.length;
      
 
      // If no data was found, run diagnostic test to investigate why
      if (totalOpportunitiesFound === 0) {
        // console.log(`No opportunities found for ${locationName}. Running diagnostic to investigate...`);
        const hasOpportunities = await this.testLocationDataExists(locationId);
        
        if (hasOpportunities) {

        } else {

        }
      }
      
  
      rawData.potentialLocations.forEach(pl => {
        console.log('Processing Potential Location:', {
          name: pl.Name,
          owner: pl.Opportunity__r?.Account?.Owner?.Name,
          enquiryContact: {
            email: pl.Enquiry_Contact_Email__c,
            firstName: pl.Enquiry_Contact_First_Name__c,
            lastName: pl.Enquiry_Contact_Last_Name__c
          },
          opportunityContact: {
            name: pl.Opportunity__r?.Primary_Contact__r?.Name,
            email: pl.Opportunity__r?.Primary_Contact__r?.Email
          }
        });
      });
      
      // Log grouped data details
      // console.log('\n=== GROUPED DATA ===');
      Object.entries(rawData.groupedByOwner).forEach(([ownerName, data]) => {
        // console.log(`\nOwner: ${ownerName}`);
        // console.log('Number of Contacts:', data.contacts.length);
        console.log('Contacts:', data.contacts.map(contact => ({
          name: contact.name,
          email: contact.email,
          company: contact.company,
          source: contact.source,
          lastInteraction: contact.lastInteraction,
          opportunityName: contact.opportunityName,
          interactions: contact.interactions
        })));
      });

      // Log final data structure before Excel generation
      // console.log('\n=== FINAL DATA STRUCTURE ===');
      // console.log('Total number of owners:', Object.keys(rawData.groupedByOwner).length);
      // console.log('Owner names:', Object.keys(rawData.groupedByOwner));
      console.log('Total contacts across all owners:', 
        Object.values(rawData.groupedByOwner).reduce((sum, data) => sum + data.contacts.length, 0)
      );

      // Generate Excel workbook
      const workbook = this.generateExcel(rawData.groupedByOwner, locationName);
      
      // Convert workbook to buffer
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Generate filename with current date
      const date = new Date().toISOString().split('T')[0];
      const filename = `${locationName}_Pure_Promote_Report_${date}.xlsx`;
      
      // Save the file
      saveAs(data, filename);

      return {
        success: true,
        message: 'Report generated and downloaded successfully'
      };
    } catch (error) {
      console.error('Error generating report:', error);
      throw new Error('Failed to generate Pure Promote report');
    }
  }

  /**
   * Generate and download a consolidated report for multiple locations
   */
  async generateMultiLocationReport(locations) {
    try {
      // console.log('Starting multi-location report generation for:', locations);
      
      // Fetch data for all locations in parallel
      const locationDataPromises = locations.map(location => 
        this.fetchLocationData(location.accountId, location.name)
      );
      
      const locationsData = await Promise.all(locationDataPromises);
      
      // Combine all data while maintaining location separation
      const combinedData = this.combineLocationsData(locationsData, locations);
      
      // Generate Excel workbook with combined data
      const workbook = this.generateMultiLocationExcel(combinedData, locations);
      
      // Convert workbook to buffer and download
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Generate filename with current date
      const date = new Date().toISOString().split('T')[0];
      const filename = `Pure_Promote_Multi_Location_Report_${date}.xlsx`;
      
      // Save the file
      saveAs(data, filename);

      return {
        success: true,
        message: 'Multi-location report generated and downloaded successfully'
      };
    } catch (error) {
      console.error('Error generating multi-location report:', error);
      throw new Error('Failed to generate multi-location Pure Promote report');
    }
  }

  /**
   * Combine data from multiple locations
   */
  combineLocationsData(locationsData, locationsList) {
    if (!Array.isArray(locationsData) || !Array.isArray(locationsList)) {
      console.error('Invalid input: locationsData and locationsList must be arrays');
      return {};
    }

    console.log('combineLocationsData called with:', {
      locationsDataCount: locationsData.length,
      locationsListCount: locationsList.length
    });

    // Check if we have any actual data across all locations
    const totalOpportunities = locationsData.reduce((count, locationData) => 
      count + (locationData.metadata?.opportunityCount || 0), 0);
      
    const totalPotentialLocations = locationsData.reduce((count, locationData) => 
      count + (locationData.metadata?.potentialLocationCount || 0), 0);
      
    console.log('Total data across all locations:', {
      totalOpportunities,
      totalPotentialLocations,
      hasData: totalOpportunities > 0 || totalPotentialLocations > 0
    });
    
    // If there's no data at all, add special metadata
    if (totalOpportunities === 0 && totalPotentialLocations === 0) {
      console.warn('No data found for any of the selected locations. Report will be empty.');
      return {
        __metadata: {
          hasData: false,
          message: 'No opportunities or contacts found for the selected locations',
          locationCount: locationsList.length,
          locations: locationsList.map(loc => loc.name)
        }
      };
    }

    // Check if we have lots of excluded records
    const totalExcluded = locationsData.reduce((count, locationData) => 
      count + (locationData.metadata?.excludedDueToNoPromote || 0), 0);
    
    if (totalExcluded > 0) {
      console.warn(`NOTE: ${totalExcluded} opportunities were excluded because contacts have no_promote=true`);
      
      // If all or most records were excluded
      if (totalExcluded > totalOpportunities) {
        return {
          __metadata: {
            hasData: false,
            totalExcluded,
            message: `${totalExcluded} opportunities were excluded because contacts have no_promote=true. To include them, update contact records in Salesforce.`,
            locationCount: locationsList.length,
            locations: locationsList.map(loc => loc.name)
          }
        };
      }
    }

    const combinedOwnerMap = new Map();
    
    locationsData.forEach((locationData, index) => {
      if (!locationData || !locationData.groupedByOwner) {
        console.warn(`Skipping invalid location data at index ${index}`);
        return;
      }

      const locationName = locationsList[index]?.name || 'Unknown Location';
      const locationUrl = locationsList[index]?.locationUrl || '';
      
      console.log(`Processing location: ${locationName}`, {
        ownerCount: Object.keys(locationData.groupedByOwner).length
      });
      
      Object.entries(locationData.groupedByOwner).forEach(([ownerName, ownerData]) => {
        if (!ownerName || !ownerData) {
          console.warn(`Skipping invalid owner data for location ${locationName}`);
          return;
        }

        console.log(`Processing owner: ${ownerName}`, {
          opportunities: ownerData.opportunities?.length || 0,
          potentialLocations: ownerData.potentialLocations?.length || 0,
          contacts: ownerData.contacts?.length || 0
        });

        if (!combinedOwnerMap.has(ownerName)) {
          combinedOwnerMap.set(ownerName, {
            opportunities: [],
            potentialLocations: [],
            contacts: new Map(),
            locationStats: new Map()
          });
        }
        
        const combinedData = combinedOwnerMap.get(ownerName);
        
        // Add opportunities with location context
        if (Array.isArray(ownerData.opportunities)) {
          ownerData.opportunities.forEach(opp => {
            if (opp) {
              combinedData.opportunities.push({
                ...opp,
                locationName,
                locationUrl: opp.locationUrl || locationUrl
              });
            }
          });
        }
        
        // Add potential locations with location context
        if (Array.isArray(ownerData.potentialLocations)) {
          ownerData.potentialLocations.forEach(pl => {
            if (pl) {
              combinedData.potentialLocations.push({
                ...pl,
                locationName,
                locationUrl: pl.locationUrl || locationUrl
              });
            }
          });
        }
        
        // Merge contacts with deduplication
        if (Array.isArray(ownerData.contacts)) {
          ownerData.contacts.forEach(contact => {
            if (!contact || !contact.email) {
              console.warn(`Skipping invalid contact for owner ${ownerName}`);
              return;
            }

            const email = contact.email.toLowerCase();
            if (!combinedData.contacts.has(email)) {
              combinedData.contacts.set(email, {
                ...contact,
                locationName: locationName,
                locationUrl: contact.locationUrl || locationUrl,
                locations: new Set([locationName]),
                opportunityName: contact.opportunityName || '',
                latestOpportunity: contact.opportunityName || '',
                billingStateCode: contact.billingStateCode || '',
                industrySector: contact.industrySector || '',
              });
            } else {
              const existingContact = combinedData.contacts.get(email);
              // Merge locations
              existingContact.locations.add(locationName);
              // Update location name to be the most recent
              existingContact.locationName = locationName;
              // Update location URL if not already set
              if (!existingContact.locationUrl) {
                existingContact.locationUrl = contact.locationUrl || locationUrl;
              }
              // Merge interactions
              if (Array.isArray(contact.interactions)) {
                existingContact.interactions = [
                  ...existingContact.interactions,
                  ...contact.interactions
                ].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              // Update opportunity name if more recent
              if (contact.lastInteraction && (!existingContact.lastInteraction || 
                  new Date(contact.lastInteraction) > new Date(existingContact.lastInteraction))) {
                existingContact.opportunityName = contact.opportunityName || existingContact.opportunityName;
                existingContact.latestOpportunity = contact.opportunityName || existingContact.latestOpportunity;
              }
              // Update other fields if needed
              if (contact.phone) existingContact.phone = contact.phone;
              if (contact.mobile) existingContact.mobile = contact.mobile;
              if (contact.company) existingContact.company = contact.company;
              if (contact.billingStateCode) existingContact.billingStateCode = contact.billingStateCode;
              if (contact.industrySector) existingContact.industrySector = contact.industrySector;
            }
          });
        }
        
        // Track location stats
        if (!combinedData.locationStats.has(locationName)) {
          combinedData.locationStats.set(locationName, {
            opportunities: Array.isArray(ownerData.opportunities) ? ownerData.opportunities.length : 0,
            potentialLocations: Array.isArray(ownerData.potentialLocations) ? ownerData.potentialLocations.length : 0,
            contacts: Array.isArray(ownerData.contacts) ? ownerData.contacts.length : 0,
            locationUrl: locationUrl
          });
        }
      });
    });
    
    // Convert the combined data to the final format
    const result = {};
    combinedOwnerMap.forEach((data, ownerName) => {
      result[ownerName] = {
        opportunities: data.opportunities,
        potentialLocations: data.potentialLocations,
        contacts: Array.from(data.contacts.values()).map(contact => ({
          ...contact,
          locations: Array.from(contact.locations).join(', '),
          locationName: contact.locationName,
          locationUrl: contact.locationUrl,
          opportunityName: contact.opportunityName,
          latestOpportunity: contact.latestOpportunity,
          billingStateCode: contact.billingStateCode,
          industrySector: contact.industrySector,
        })),
        locationStats: Object.fromEntries(data.locationStats)
      };
    });
    
    // Add metadata about the result
    result.__metadata = {
      hasData: true,
      totalOpportunities,
      totalPotentialLocations,
      totalContacts: Object.values(result).reduce((count, ownerData) => count + (ownerData.contacts?.length || 0), 0),
      locationCount: locationsList.length,
      locations: locationsList.map(loc => loc.name)
    };
    
    return result;
  }

  /**
   * Generate Excel workbook for multiple locations
   */
  generateMultiLocationExcel(combinedData, locations) {
    const workbook = XLSX.utils.book_new();

    // Create a summary sheet
    const summaryData = [
      ['Pure Promote Multi-Location Report'],
      ['Generated:', new Date().toLocaleString()],
      [''],
      ['Locations Included:'],
      ...locations.map(loc => ['• ' + loc.name]),
      [''],
      ['Account Owner', 'Total Contacts', 'Total Opportunities', 'Total Potential Locations']
    ];

    // Add summary data for each owner
    Object.entries(combinedData).forEach(([ownerName, data]) => {
      summaryData.push([
        ownerName,
        data.contacts.length,
        data.opportunities.length,
        data.potentialLocations.length
      ]);
    });

    const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary');

    // Create a consolidated contacts sheet
    const contactsData = [
      ['All Contacts'],
      ['Name', 'Email', 'Phone', 'Mobile', 'Company', 'Locations', 'Billing State Code', 'Industry Sector', 'Last Interaction', 'Source', 'Latest Opportunity', 'Owner', 'Actioned', 'Comments']
    ];

    Object.entries(combinedData).forEach(([ownerName, data]) => {
      data.contacts.forEach(contact => {
        contactsData.push([
          contact.name,
          contact.email,
          contact.phone || '',
          contact.mobile || '',
          contact.company || '',
          contact.locations,
          contact.billingStateCode || '',
          contact.industrySector || '',
          contact.lastInteraction ? new Date(contact.lastInteraction).toLocaleDateString() : '',
          contact.source || '',
          contact.opportunityName || '',
          ownerName,
          '',
          ''
        ]);
      });
    });

    const contactsSheet = XLSX.utils.aoa_to_sheet(contactsData);
    
    // Set column widths
    const columnWidths = [
      { wch: 30 }, // Name
      { wch: 35 }, // Email
      { wch: 15 }, // Phone
      { wch: 15 }, // Mobile
      { wch: 30 }, // Company
      { wch: 40 }, // Locations
      { wch: 15 }, // Billing State Code
      { wch: 15 }, // Industry Sector
      { wch: 15 }, // Last Interaction
      { wch: 25 }, // Source
      { wch: 40 }, // Latest Opportunity
      { wch: 25 }, // Owner
      { wch: 10 }, // Actioned
      { wch: 30 }  // Comments
    ];
    contactsSheet['!cols'] = columnWidths;

    XLSX.utils.book_append_sheet(workbook, contactsSheet, 'All Contacts');

    // Create individual owner sheets
    Object.entries(combinedData).forEach(([ownerName, data]) => {
      const ownerData = [
        ['Contact List for ' + ownerName],
        ['Name', 'Email', 'Phone', 'Mobile', 'Company', 'Locations', 'Billing State Code', 'Industry Sector', 'Last Interaction', 'Source', 'Latest Opportunity', 'Actioned', 'Comments']
      ];

      data.contacts.forEach(contact => {
        ownerData.push([
          contact.name,
          contact.email,
          contact.phone || '',
          contact.mobile || '',
          contact.company || '',
          contact.locations,
          contact.billingStateCode || '',
          contact.industrySector || '',
          contact.lastInteraction ? new Date(contact.lastInteraction).toLocaleDateString() : '',
          contact.source || '',
          contact.opportunityName || '',
          '',
          ''
        ]);
      });

      const ownerSheet = XLSX.utils.aoa_to_sheet(ownerData);
      ownerSheet['!cols'] = columnWidths;
      
      XLSX.utils.book_append_sheet(workbook, ownerSheet, ownerName.substring(0, 31));
    });

    return workbook;
  }

  // Add methods for managing report data
  setReportData(data) {
    this.currentReportData = data;
  }

  getReportData() {
    return this.currentReportData;
  }

  clearReportData() {
    this.currentReportData = null;
  }
}

export default new PurePromoteService(); 
