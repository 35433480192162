import axios from 'axios';

class OutlookService {
  constructor() {
    this.baseUrl = 'https://graph.microsoft.com/v1.0';
    this.clientId = process.env.REACT_APP_MSGRAPH_CLIENT_ID;
    this.clientSecret = process.env.REACT_APP_MSGRAPH_CLIENT_SECRET;
    this.tenantId = process.env.REACT_APP_MSGRAPH_TENANT_ID;
    this.proxyBaseUrl = 'https://bdm.purelocations.com.au'; // Always use production URL for testing
    
    // Flag to control test mode (defaults to false for production)
    this.isTestMode = false;
    this.testEmailAddress = 'damian@purelocations.com.au';

    // Validate required environment variables
    if (!this.clientId || !this.clientSecret || !this.tenantId) {
      console.error('Missing required Microsoft Graph API credentials in environment variables.');
      throw new Error(
        'Microsoft Graph API credentials are missing. Please ensure:\n' +
        '1. You have a .env file in your project root\n' +
        '2. The file contains REACT_APP_MSGRAPH_CLIENT_ID, REACT_APP_MSGRAPH_CLIENT_SECRET, and REACT_APP_MSGRAPH_TENANT_ID\n' +
        '3. You have restarted your development server'
      );
    }
  }

  /**
   * Set test mode on or off
   * @param {boolean} enabled - Whether test mode should be enabled
   */
  setTestMode(enabled) {
    this.isTestMode = enabled;
    // console.log(`Email test mode is now ${enabled ? 'ENABLED' : 'DISABLED'}`);
    // console.log(`Emails will be sent to ${enabled ? this.testEmailAddress : 'actual recipients'}`);
    return this.isTestMode;
  }

  /**
   * Get current test mode status
   * @returns {boolean} Whether test mode is currently enabled
   */
  getTestMode() {
    return this.isTestMode;
  }

  /**
   * Get access token for Microsoft Graph API
   */
  async getAccessToken() {
    try {
      // console.log('Requesting access token from proxy server...');
      
      const tokenRequest = {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        scope: 'https://graph.microsoft.com/.default',
        grant_type: 'client_credentials',
        tenant_id: this.tenantId
      };

      const response = await axios.post(`${this.proxyBaseUrl}/api/token`, tokenRequest, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.data || !response.data.access_token) {
        console.error('Invalid token response:', response.data);
        throw new Error('Invalid token response from server');
      }

      // console.log('Successfully obtained access token');
      return response.data.access_token;
    } catch (error) {
      console.error('Error getting access token:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        message: error.message
      });
      
      const errorMessage = error.response?.data?.error_description || 
                          error.response?.data?.message ||
                          error.message;
      
      throw new Error('Failed to get access token: ' + errorMessage);
    }
  }

  /**
   * Send email using Microsoft Graph API
   * @param {Object} emailData - The email data
   * @param {string} sendAsEmail - The email address to send as
   */
  async sendEmail(emailData, sendAsEmail) {
    try {
      console.log('Starting email send process...', {
        hasTo: !!emailData.to,
        hasSubject: !!emailData.subject,
        hasBody: !!emailData.body,
        sendAsEmail
      });

      const accessToken = await this.getAccessToken();
      // console.log('Successfully got access token');
      
      const emailMessage = {
        message: {
          subject: emailData.subject,
          body: {
            contentType: 'HTML',
            content: emailData.body
          },
          toRecipients: [
            {
              emailAddress: {
                address: emailData.to
              }
            }
          ]
        },
        saveToSentItems: true
      };

      // Add CC recipients if specified
      if (emailData.cc) {
        emailMessage.message.ccRecipients = emailData.cc.split(',').map(email => ({
          emailAddress: {
            address: email.trim()
          }
        }));
      }

      // Add BCC recipients if specified
      if (emailData.bcc) {
        emailMessage.message.bccRecipients = emailData.bcc.split(',').map(email => ({
          emailAddress: {
            address: email.trim()
          }
        }));
      }

      console.log('Constructed email message:', {
        subject: emailMessage.message.subject,
        toRecipient: emailData.to,
        sendAsEmail,
        hasBody: !!emailMessage.message.body.content
      });

      // Construct the full URL for sending email
      const sendMailUrl = `${this.baseUrl}/users/${encodeURIComponent(sendAsEmail)}/sendMail`;
      // console.log('Sending email to URL:', sendMailUrl);

      // Send the email using Microsoft Graph API
      const response = await axios.post(
        sendMailUrl,
        emailMessage,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Email sent successfully:', {
        status: response.status,
        statusText: response.statusText,
        recipient: emailData.to,
        sendAsEmail
      });

      return response.status === 202;
    } catch (error) {
      // Enhanced error logging
      console.error('Detailed error in sendEmail:', {
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack,
        responseStatus: error.response?.status,
        responseStatusText: error.response?.statusText,
        responseData: error.response?.data,
        graphError: error.response?.data?.error,
        sendAsEmail,
        originalRecipient: emailData.to
      });

      // Try to get the most specific error message
      const graphErrorMessage = error.response?.data?.error?.message;
      const graphErrorCode = error.response?.data?.error?.code;
      const statusCode = error.response?.status;
      
      let errorMessage = 'Failed to send email: ';
      
      if (graphErrorMessage) {
        errorMessage += `Graph API Error - ${graphErrorCode}: ${graphErrorMessage}`;
      } else if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else if (statusCode) {
        errorMessage += `HTTP ${statusCode}: ${error.message}`;
      } else {
        errorMessage += error.message;
      }

      throw new Error(errorMessage);
    }
  }

  /**
   * Main method to compose and send email
   * @param {Object} emailData - The email data
   * @param {string} ownerEmail - The owner's email address to send as
   */
  async composeEmail(emailData, ownerEmail) {
    if (!ownerEmail) {
      throw new Error('Owner email is required to send the email');
    }

    try {
      // console.log('Composing email for owner:', ownerEmail);
      
      let emailToSend = emailData;
      
      // Only redirect and modify emails if in test mode
      if (this.isTestMode) {
        // console.log(`TEST MODE ACTIVE - Redirecting email to ${this.testEmailAddress}`);
        
        // Save original recipient for reference
        const originalRecipient = emailData.to;
        
        // Create a modified email object for testing
        emailToSend = {
          ...emailData,
          // Override the recipient with test email
          to: this.testEmailAddress,
          // Add original recipient information to the subject
          subject: `[TEST MODE - Original: ${originalRecipient}] ${emailData.subject}`,
          // Add a notice at the top of the email body
          body: `<div style="background-color: #f8d7da; color: #721c24; padding: 10px; margin-bottom: 15px; border: 1px solid #f5c6cb; border-radius: 4px;"><strong>TEST MODE</strong><br>Original Recipient: ${originalRecipient}<br>This is a test email that would normally be sent to the contact.</div>${emailData.body}`
        };
        
        console.log('Redirecting email to test address:', {
          originalRecipient,
          testRecipient: emailToSend.to
        });
      } else {
        // console.log('LIVE MODE - Sending email to actual recipient:', emailData.to);
      }
      
      // Send the email (either the test version or the original)
      const result = await this.sendEmail(emailToSend, ownerEmail);
      return result;
    } catch (error) {
      console.error('Error composing email:', error);
      throw error;
    }
  }
}

export default new OutlookService(); 