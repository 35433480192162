import { openDB } from 'idb';

const DB_NAME = 'purelytics';
const DB_VERSION = 5;

class IndexedDBService {
  constructor() {
    this.dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion) {
        // console.log(`Upgrading IndexedDB from version ${oldVersion} to ${newVersion}`);

        // Create stores if they don't exist
        if (!db.objectStoreNames.contains('clients')) {
          // console.log('Creating clients store');
          const clientStore = db.createObjectStore('clients', { keyPath: 'accountId' });
          clientStore.createIndex('clientName', 'clientName');
          clientStore.createIndex('lastSyncedAt', 'lastSyncedAt');
        }

        if (!db.objectStoreNames.contains('locations')) {
          // console.log('Creating locations store');
          const locationStore = db.createObjectStore('locations', { keyPath: 'accountId' });
          locationStore.createIndex('name', 'name');
          locationStore.createIndex('lastSyncedAt', 'lastSyncedAt');
          locationStore.createIndex('featureImageUrl', 'Website_Feature_Image_URL__c');
        }

        if (!db.objectStoreNames.contains('purePromoteContacts')) {
          // console.log('Creating purePromoteContacts store');
          const contactStore = db.createObjectStore('purePromoteContacts', { keyPath: 'id' });
          contactStore.createIndex('email', 'email');
          contactStore.createIndex('status', 'status');
          contactStore.createIndex('lastProcessed', 'lastProcessed');
          contactStore.createIndex('contactId', 'contactId');
          contactStore.createIndex('Id', 'Id');
          contactStore.createIndex('Pure_Promote_Last_Contact__c', 'Pure_Promote_Last_Contact__c');
          contactStore.createIndex('Pure_Promote_Last_Location__c', 'Pure_Promote_Last_Location__c');
          contactStore.createIndex('Contact_Notes__c', 'Contact_Notes__c');
          contactStore.createIndex('VIP_Contact__c', 'VIP_Contact__c');
          contactStore.createIndex('billingStateCode', 'billingStateCode');
          contactStore.createIndex('industrySector', 'industrySector');
        }

        if (!db.objectStoreNames.contains('purePromoteReports')) {
          // console.log('Creating purePromoteReports store');
          const reportStore = db.createObjectStore('purePromoteReports', { 
            keyPath: 'id', 
            autoIncrement: true 
          });
          reportStore.createIndex('createdAt', 'createdAt');
          reportStore.createIndex('locationId', 'locationId');
        }

        // console.log('Database upgrade complete');
      },
      blocked(currentVersion, blockedVersion, event) {
        console.warn('Database upgrade blocked. Please close other tabs/windows.', {
          currentVersion,
          blockedVersion,
          event
        });
      },
      blocking(currentVersion, blockedVersion, event) {
        console.warn('This tab is blocking a database upgrade. Please reload.', {
          currentVersion,
          blockedVersion,
          event
        });
      },
      terminated() {
        console.error('Database connection terminated unexpectedly.');
      }
    }).catch(error => {
      console.error('Failed to open database:', error);
      throw error;
    });

    // Initialize the database
    this.initDB().catch(error => {
      console.error('Failed to initialize database:', error);
    });
  }

  async initDB() {
    try {
      const db = await this.dbPromise;
      console.log('Database initialized successfully', {
        name: db.name,
        version: db.version,
        stores: Array.from(db.objectStoreNames)
      });
      this.db = db;
      return db;
    } catch (error) {
      console.error('Error initializing database:', error);
      throw error;
    }
  }

  async getDb() {
    return await this.dbPromise;
  }

  async getAllClients() {
    const db = await this.getDb();
    return db.getAll('clients');
  }

  async getClientById(id) {
    const db = await this.getDb();
    return db.get('clients', id);
  }

  async searchClients(query) {
    const clients = await this.getAllClients();
    query = query.toLowerCase();
    
    return clients.filter(client => 
      client.clientName.toLowerCase().includes(query) ||
      client.location.toLowerCase().includes(query)
    );
  }

  async getTopClients(limit = 10) {
    const clients = await this.getAllClients();
    return clients
      .sort((a, b) => {
        const aRevenue = a.opportunities.reduce((sum, opp) => 
          sum + (Number(opp.Total_Hire_Fees__c) || 0), 0);
        const bRevenue = b.opportunities.reduce((sum, opp) => 
          sum + (Number(opp.Total_Hire_Fees__c) || 0), 0);
        return bRevenue - aRevenue;
      })
      .slice(0, limit);
  }

  async getRecentClients(days = 90) {
    const clients = await this.getAllClients();
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - days);

    return clients.filter(client => {
      const lastOpp = client.opportunities[0];
      if (!lastOpp) return false;
      return new Date(lastOpp.CloseDate) >= cutoffDate;
    });
  }

  async getClientMetrics() {
    const clients = await this.getAllClients();
    
    const totalRevenue = clients.reduce((sum, client) => 
      sum + client.opportunities.reduce((oppSum, opp) => 
        oppSum + (Number(opp.Total_Hire_Fees__c) || 0), 0), 0);

    const totalProfit = clients.reduce((sum, client) => 
      sum + client.opportunities.reduce((oppSum, opp) => 
        oppSum + (Number(opp.Net_Profit__c) || 0), 0), 0);

    // Get metrics by type of hire
    const typeOfHireMetrics = clients.reduce((metrics, client) => {
      client.opportunities.forEach(opp => {
        const type = opp.typeOfHire || 'N/A';
        if (!metrics[type]) {
          metrics[type] = {
            count: 0,
            revenue: 0,
            profit: 0
          };
        }
        metrics[type].count++;
        metrics[type].revenue += Number(opp.Total_Hire_Fees__c) || 0;
        metrics[type].profit += Number(opp.Net_Profit__c) || 0;
      });
      return metrics;
    }, {});

    return {
      totalClients: clients.length,
      totalRevenue,
      totalProfit,
      averageRevenuePerClient: totalRevenue / clients.length,
      typeOfHireMetrics
    };
  }

  async getOpportunitiesByTypeOfHire(typeOfHire) {
    const clients = await this.getAllClients();
    return clients.reduce((opportunities, client) => {
      const filteredOpps = client.opportunities.filter(opp => 
        opp.typeOfHire === typeOfHire
      ).map(opp => ({
        ...opp,
        clientName: client.clientName,
        clientId: client.accountId
      }));
      return [...opportunities, ...filteredOpps];
    }, []);
  }

  async getTypeOfHireDistribution(startDate = null, endDate = null) {
    const clients = await this.getAllClients();
    const distribution = {};
    
    clients.forEach(client => {
      client.opportunities.forEach(opp => {
        // Apply date filter if provided
        if (startDate && endDate) {
          const oppDate = new Date(opp.CloseDate);
          if (oppDate < new Date(startDate) || oppDate > new Date(endDate)) {
            return;
          }
        }

        const type = opp.typeOfHire || 'N/A';
        if (!distribution[type]) {
          distribution[type] = {
            count: 0,
            revenue: 0,
            opportunities: []
          };
        }
        distribution[type].count++;
        distribution[type].revenue += Number(opp.Total_Hire_Fees__c) || 0;
        distribution[type].opportunities.push({
          id: opp.Id,
          name: opp.Name,
          clientName: client.clientName,
          closeDate: opp.CloseDate,
          revenue: opp.Total_Hire_Fees__c,
          profit: opp.Net_Profit__c
        });
      });
    });

    return distribution;
  }

  async getLocationsByTypeOfHire() {
    const locations = await this.getAllLocations();
    const distribution = {};
    
    locations.forEach(location => {
      location.potentialLocations.forEach(pl => {
        const type = pl.typeOfHire || 'N/A';
        if (!distribution[type]) {
          distribution[type] = {
            count: 0,
            locations: []
          };
        }
        distribution[type].count++;
        distribution[type].locations.push({
          locationId: location.accountId,
          locationName: location.name,
          potentialLocationId: pl.Id,
          potentialLocationName: pl.Name,
          recceStage: pl.Recce_Stage__c,
          recceStartDateTime: pl.Recce_Start_Date_Time__c
        });
      });
    });

    return distribution;
  }

  async saveClient(client) {
    const db = await this.getDb();
    const tx = db.transaction('clients', 'readwrite');
    await tx.store.put(client);
    await tx.done;
  }

  async saveClients(clients) {
    console.log('Saving clients to IndexedDB:', {
      count: clients?.length || 0,
      sampleClient: clients?.[0]
    });
    const db = await this.getDb();
    const tx = db.transaction('clients', 'readwrite');
    await Promise.all((clients || []).map(client => tx.store.put(client)));
    await tx.done;
    // console.log('Clients saved successfully');
  }

  async saveLocation(location) {
    const db = await this.getDb();
    const tx = db.transaction('locations', 'readwrite');
    await tx.store.put(location);
    await tx.done;
  }

  async saveLocations(locations) {
    console.log('Saving locations to IndexedDB:', {
      count: locations?.length || 0,
      sampleLocation: locations?.[0]
    });
    const db = await this.getDb();
    const tx = db.transaction('locations', 'readwrite');
    await Promise.all((locations || []).map(location => tx.store.put(location)));
    await tx.done;
    // console.log('Locations saved successfully');
  }

  async getAllLocations() {
    const db = await this.getDb();
    return db.getAll('locations');
  }

  async clearClients() {
    // console.log('Clearing clients store...');
    const db = await this.getDb();
    const tx = db.transaction('clients', 'readwrite');
    await tx.store.clear();
    await tx.done;
    // console.log('Clients store cleared');
  }

  async clearLocations() {
    // console.log('Clearing locations store...');
    const db = await this.getDb();
    const tx = db.transaction('locations', 'readwrite');
    await tx.store.clear();
    await tx.done;
    // console.log('Locations store cleared');
  }

  // Pure Promote specific methods
  async savePurePromoteContacts(contacts) {
    try {
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteContacts', 'readwrite');
      const store = tx.objectStore('purePromoteContacts');

      // Process each contact
      await Promise.all(contacts.map(async (contact) => {
        try {
          // Get existing contact data if it exists
          const existing = await store.get(contact.id);

          // Merge existing data with new data, preserving Salesforce fields
          const updatedContact = {
            ...contact,
            status: existing?.status || 'pending',
            lastProcessed: existing?.lastProcessed || null,
            emailsSent: existing?.emailsSent || [],
            notes: existing?.notes || '',
            lastUpdated: new Date().toISOString(),
            // Preserve Salesforce fields
            Id: contact.Id || existing?.Id || null,
            contactId: contact.contactId || contact.Id || existing?.contactId || existing?.Id || null,
            Pure_Promote_Last_Contact__c: contact.Pure_Promote_Last_Contact__c || existing?.Pure_Promote_Last_Contact__c || null,
            Pure_Promote_Last_Location__c: contact.Pure_Promote_Last_Location__c || existing?.Pure_Promote_Last_Location__c || null,
            Contact_Notes__c: contact.Contact_Notes__c !== undefined ? contact.Contact_Notes__c : existing?.Contact_Notes__c,
            VIP_Contact__c: contact.VIP_Contact__c !== undefined ? contact.VIP_Contact__c : existing?.VIP_Contact__c,
            // Filter fields
            ownerId: contact.ownerId || contact.OwnerId || existing?.ownerId || existing?.OwnerId || null,
            ownerName: contact.ownerName || contact.Owner?.Name || existing?.ownerName || null,
            billingStateCode: contact.billingStateCode || contact.BillingStateCode || existing?.billingStateCode || null,
            industrySector: contact.industrySector || contact.Industry_Sectors__c || existing?.industrySector || null,
            // Location fields
            locationName: contact.locationName || existing?.locationName || null,
            locationUrl: contact.locationUrl || existing?.locationUrl || null
          };

          await store.put(updatedContact);
        } catch (error) {
          console.error('Error processing contact:', error);
        }
      }));

      await tx.complete;
    } catch (error) {
      console.error('Error saving Pure Promote contacts:', error);
      throw error;
    }
  }

  async getPurePromoteContacts() {
    try {
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteContacts', 'readonly');
      const contacts = await tx.store.getAll();
      
      // Debug log to check data being retrieved
      console.log('Retrieved Pure Promote contacts from IndexedDB:', contacts?.map(contact => ({
        id: contact.id,
        email: contact.email,
        Pure_Promote_Last_Contact__c: contact.Pure_Promote_Last_Contact__c,
        Pure_Promote_Last_Location__c: contact.Pure_Promote_Last_Location__c,
        Contact_Notes__c: contact.Contact_Notes__c,
        VIP_Contact__c: contact.VIP_Contact__c,
        lastInteraction: contact.lastInteraction,
        lastPromoteLocation: contact.lastPromoteLocation
      })));
      
      return contacts;
    } catch (error) {
      console.error('Error getting Pure Promote contacts:', error);
      throw error;
    }
  }

  /**
   * Get only VIP contacts from the database
   * @param {Object} filters - Optional filters to apply
   * @param {string[]} filters.owners - Array of owner IDs to filter by
   * @param {string[]} filters.states - Array of state codes to filter by
   * @param {string[]} filters.industries - Array of industries to filter by
   * @returns {Promise<Array>} Array of contacts with VIP_Contact__c set to true
   */
  async getVIPContacts(filters = {}) {
    try {
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteContacts', 'readonly');
      const allContacts = await tx.store.getAll();
      
      // First filter contacts where VIP_Contact__c is true
      let vipContacts = allContacts.filter(contact => contact.VIP_Contact__c === true);
      
      // Apply additional filters if provided
      if (filters.owners && filters.owners.length > 0) {
        vipContacts = vipContacts.filter(contact => 
          contact.ownerId && filters.owners.includes(contact.ownerId)
        );
      }
      
      if (filters.states && filters.states.length > 0) {
        vipContacts = vipContacts.filter(contact => 
          contact.billingStateCode && filters.states.includes(contact.billingStateCode)
        );
      }
      
      if (filters.industries && filters.industries.length > 0) {
        vipContacts = vipContacts.filter(contact => {
          if (!contact.industrySector) return false;
          
          // Handle case where industry is stored as comma-separated values
          const contactIndustries = typeof contact.industrySector === 'string' 
            ? contact.industrySector.split(',').map(i => i.trim())
            : [contact.industrySector];
            
          return filters.industries.some(industry => 
            contactIndustries.includes(industry)
          );
        });
      }
      
      console.log('Retrieved VIP contacts from IndexedDB with filters:', {
        totalContacts: allContacts.length,
        vipContactsTotal: allContacts.filter(c => c.VIP_Contact__c === true).length,
        filteredVipContacts: vipContacts.length,
        appliedFilters: {
          owners: filters.owners?.length || 0,
          states: filters.states?.length || 0,
          industries: filters.industries?.length || 0
        }
      });
      
      return vipContacts;
    } catch (error) {
      console.error('Error getting VIP contacts:', error);
      throw error;
    }
  }

  /**
   * Get unique owner information for VIP contacts
   * @returns {Promise<Array>} Array of unique owners with their IDs and names
   */
  async getUniqueVIPContactOwners() {
    try {
      const vipContacts = await this.getVIPContacts();
      const ownersMap = new Map();
      
      vipContacts.forEach(contact => {
        if (contact.ownerId && contact.ownerName) {
          ownersMap.set(contact.ownerId, {
            id: contact.ownerId,
            name: contact.ownerName
          });
        }
      });
      
      return Array.from(ownersMap.values()).sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('Error getting unique VIP contact owners:', error);
      return [];
    }
  }

  /**
   * Get unique state codes for VIP contacts
   * @returns {Promise<Array>} Array of unique state codes
   */
  async getUniqueVIPContactStates() {
    try {
      const vipContacts = await this.getVIPContacts();
      const states = new Set();
      
      vipContacts.forEach(contact => {
        if (contact.billingStateCode) {
          states.add(contact.billingStateCode);
        }
      });
      
      return Array.from(states).sort();
    } catch (error) {
      console.error('Error getting unique VIP contact states:', error);
      return [];
    }
  }

  /**
   * Get unique industries for VIP contacts
   * @returns {Promise<Array>} Array of unique industries
   */
  async getUniqueVIPContactIndustries() {
    try {
      const vipContacts = await this.getVIPContacts();
      const industries = new Set();
      
      vipContacts.forEach(contact => {
        if (contact.industrySector) {
          // Handle comma-separated industry sectors
          const sectors = typeof contact.industrySector === 'string'
            ? contact.industrySector.split(',').map(sector => sector.trim())
            : [contact.industrySector];
            
          sectors.forEach(sector => {
            if (sector) industries.add(sector);
          });
        }
      });
      
      return Array.from(industries).sort();
    } catch (error) {
      console.error('Error getting unique VIP contact industries:', error);
      return [];
    }
  }

  async updatePurePromoteContact(contactId, updates) {
    try {
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteContacts', 'readwrite');
      const store = tx.objectStore('purePromoteContacts');

      // First get the existing contact
      const existingContact = await store.get(contactId);
      
      if (!existingContact) {
        throw new Error('Contact not found');
      }

      // Create updated contact with all fields
      const updatedContact = {
        ...existingContact,
        ...updates,
        lastUpdated: new Date().toISOString(),
        // Ensure these fields are always present
        status: updates.status || existingContact.status || 'pending',
        emailsSent: updates.emailsSent || existingContact.emailsSent || [],
        lastProcessed: updates.lastProcessed || existingContact.lastProcessed || null,
        // Preserve Salesforce fields
        Id: updates.Id || existingContact.Id || null,
        contactId: updates.contactId || updates.Id || existingContact.contactId || existingContact.Id || null,
        Pure_Promote_Last_Contact__c: updates.Pure_Promote_Last_Contact__c || existingContact.Pure_Promote_Last_Contact__c || null,
        Pure_Promote_Last_Location__c: updates.Pure_Promote_Last_Location__c || existingContact.Pure_Promote_Last_Location__c || null,
        Contact_Notes__c: updates.Contact_Notes__c !== undefined ? updates.Contact_Notes__c : existingContact.Contact_Notes__c,
        VIP_Contact__c: updates.VIP_Contact__c !== undefined ? updates.VIP_Contact__c : existingContact.VIP_Contact__c,
        // Filter fields
        ownerId: updates.ownerId || updates.OwnerId || existingContact.ownerId || existingContact.OwnerId || null,
        ownerName: updates.ownerName || updates.Owner?.Name || existingContact.ownerName || null,
        billingStateCode: updates.billingStateCode || updates.BillingStateCode || existingContact.billingStateCode || null,
        industrySector: updates.industrySector || updates.Industry_Sectors__c || existingContact.industrySector || null,
        // Location fields
        locationName: updates.locationName || existingContact.locationName || null,
        locationUrl: updates.locationUrl || existingContact.locationUrl || null
      };

      await store.put(updatedContact);
      await tx.complete;
      return updatedContact;
    } catch (error) {
      console.error('Error updating Pure Promote contact:', error);
      throw error;
    }
  }

  async recordEmailSent(contactId, emailDetails) {
    await this.initDB();
    const tx = this.db.transaction('purePromoteContacts', 'readwrite');
    const store = tx.objectStore('purePromoteContacts');

    return new Promise((resolve, reject) => {
      const getRequest = store.get(contactId);
      
      getRequest.onsuccess = () => {
        const contact = getRequest.result;
        if (!contact) {
          reject(new Error('Contact not found'));
          return;
        }

        // Add new email to the history
        const emailRecord = {
          ...emailDetails,
          sentAt: new Date().toISOString()
        };

        const updatedContact = {
          ...contact,
          emailsSent: [...(contact.emailsSent || []), emailRecord],
          lastProcessed: new Date().toISOString(),
          status: 'contacted',
          lastUpdated: new Date().toISOString()
        };

        const putRequest = store.put(updatedContact);
        putRequest.onsuccess = () => resolve(updatedContact);
        putRequest.onerror = () => reject(putRequest.error);
      };
      
      getRequest.onerror = () => reject(getRequest.error);
    });
  }

  async clearPurePromoteContacts() {
    try {
      // console.log('Clearing Pure Promote contacts...');
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteContacts', 'readwrite');
      await tx.store.clear();
      await tx.done;
      // console.log('Pure Promote contacts cleared successfully');
    } catch (error) {
      console.error('Error clearing Pure Promote contacts:', error);
      throw error;
    }
  }

  // Pure Promote Report methods
  async savePurePromoteReport(report) {
    try {
      const db = await this.dbPromise;
      if (!db.objectStoreNames.contains('purePromoteReports')) {
        throw new Error('purePromoteReports store not found. Please reload the application.');
      }

      const reportToSave = {
        ...report,
        id: report.locationId || Date.now().toString(),
        createdAt: new Date().toISOString(),
        lastUpdated: new Date().toISOString()
      };

      const tx = db.transaction('purePromoteReports', 'readwrite');
      await tx.store.put(reportToSave);
      await tx.done;
      return reportToSave;
    } catch (error) {
      console.error('Error saving Pure Promote report:', error);
      throw error;
    }
  }

  async getPurePromoteReport(locationId) {
    try {
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteReports', 'readonly');
      const index = tx.store.index('locationId');
      return await index.get(locationId);
    } catch (error) {
      console.error('Error getting Pure Promote report:', error);
      throw error;
    }
  }

  async getAllPurePromoteReports() {
    try {
      const db = await this.dbPromise;
      return db.getAll('purePromoteReports');
    } catch (error) {
      console.error('Error getting Pure Promote reports:', error);
      throw error;
    }
  }

  async clearPurePromoteReports() {
    try {
      const db = await this.dbPromise;
      const tx = db.transaction('purePromoteReports', 'readwrite');
      await tx.store.clear();
      await tx.done;
    } catch (error) {
      console.error('Error clearing Pure Promote reports:', error);
      throw error;
    }
  }

  async getUniqueIndustries() {
    const clients = await this.getAllClients();
    const industries = new Set();
    
    clients.forEach(client => {
      if (client.industry && client.industry !== 'N/A') {
        // Handle comma-separated industry sectors
        const sectors = client.industry.split(',').map(sector => sector.trim());
        sectors.forEach(sector => industries.add(sector));
      }
    });

    return Array.from(industries).sort();
  }

  async getUniqueTypeOfHires() {
    const clients = await this.getAllClients();
    const types = new Set();
    
    clients.forEach(client => {
      client.opportunities.forEach(opp => {
        if (opp.typeOfHire && opp.typeOfHire !== 'N/A') {
          types.add(opp.typeOfHire);
        }
      });
    });

    return Array.from(types).sort();
  }

  /**
   * CRITICAL: Ensures all opportunities maintain the complete data structure needed for PurePromote
   * This method must not be modified without careful consideration of its impact on PurePromote
   * 
   * @param {Array} opportunities - Opportunities to ensure have all required fields
   * @returns {Array} - Processed opportunities with all required fields
   */
  ensurePurePromoteCompatibility(opportunities) {
    if (!Array.isArray(opportunities)) {
      console.error('Invalid input: opportunities must be an array');
      return [];
    }

    return opportunities.map(opp => {
      if (!opp) return null;

      // Never modify the original Location_Name__r reference - essential for PurePromote
      // Make sure Primary_Contact__r data is preserved - essential for PurePromote contacts
      
      return {
        ...opp, // Keep ALL original fields
        // Only add fallbacks for missing fields, never override existing ones
        Location_Name__r: opp.Location_Name__r || null,
        Primary_Contact__r: opp.Primary_Contact__r || null
      };
    }).filter(Boolean);
  }

  async filterOpportunities({ industries = [], typeOfHires = [], startDate = null, endDate = null }) {
    const clients = await this.getAllClients();
    const filteredOpportunities = [];

    clients.forEach(client => {
      // Check if client's industry matches any of the selected industries
      const clientIndustries = client.industry.split(',').map(i => i.trim());
      const matchesIndustry = industries.length === 0 || 
        industries.some(industry => clientIndustries.includes(industry));

      if (matchesIndustry) {
        client.opportunities.forEach(opp => {
          // Check if opportunity matches type of hire filter
          const matchesTypeOfHire = typeOfHires.length === 0 || 
            typeOfHires.includes(opp.typeOfHire);

          // Check if opportunity falls within date range
          let matchesDateRange = true;
          if (startDate && endDate) {
            const oppDate = new Date(opp.CloseDate);
            matchesDateRange = oppDate >= new Date(startDate) && 
                             oppDate <= new Date(endDate);
          }

          if (matchesTypeOfHire && matchesDateRange) {
            filteredOpportunities.push({
              ...opp,
              clientName: client.clientName,
              clientId: client.accountId,
              industry: client.industry
            });
          }
        });
      }
    });

    // CRITICAL: Always ensure filtered opportunities maintain PurePromote compatibility
    return this.ensurePurePromoteCompatibility(filteredOpportunities);
  }

  async getFilteredMetrics({ industries = [], typeOfHires = [], startDate = null, endDate = null }) {
    const opportunities = await this.filterOpportunities({ 
      industries, 
      typeOfHires, 
      startDate, 
      endDate 
    });

    const metrics = {
      totalOpportunities: opportunities.length,
      totalRevenue: 0,
      totalProfit: 0,
      byIndustry: {},
      byTypeOfHire: {},
      byMonth: {}
    };

    opportunities.forEach(opp => {
      const revenue = Number(opp.Total_Hire_Fees__c) || 0;
      const profit = Number(opp.Net_Profit__c) || 0;
      
      // Update totals
      metrics.totalRevenue += revenue;
      metrics.totalProfit += profit;

      // Update industry metrics
      const industries = opp.industry.split(',').map(i => i.trim());
      industries.forEach(industry => {
        if (!metrics.byIndustry[industry]) {
          metrics.byIndustry[industry] = { count: 0, revenue: 0, profit: 0 };
        }
        metrics.byIndustry[industry].count++;
        metrics.byIndustry[industry].revenue += revenue;
        metrics.byIndustry[industry].profit += profit;
      });

      // Update type of hire metrics
      const type = opp.typeOfHire || 'N/A';
      if (!metrics.byTypeOfHire[type]) {
        metrics.byTypeOfHire[type] = { count: 0, revenue: 0, profit: 0 };
      }
      metrics.byTypeOfHire[type].count++;
      metrics.byTypeOfHire[type].revenue += revenue;
      metrics.byTypeOfHire[type].profit += profit;

      // Update monthly metrics
      const monthYear = new Date(opp.CloseDate).toISOString().slice(0, 7);
      if (!metrics.byMonth[monthYear]) {
        metrics.byMonth[monthYear] = { count: 0, revenue: 0, profit: 0 };
      }
      metrics.byMonth[monthYear].count++;
      metrics.byMonth[monthYear].revenue += revenue;
      metrics.byMonth[monthYear].profit += profit;
    });

    return metrics;
  }

  /**
   * Sync VIP contacts from Salesforce to IndexedDB
   * @param {Array} vipContacts - VIP contacts from Salesforce
   * @returns {Promise<number>} Number of VIP contacts synced
   */
  async syncVIPContacts(vipContacts) {
    try {
      console.log('Syncing VIP contacts to IndexedDB:', {
        count: vipContacts?.length || 0
      });

      // Use the savePurePromoteContacts method to save VIP contacts
      // This reuses the existing logic and ensures all fields are handled properly
      await this.savePurePromoteContacts(vipContacts);

      console.log('Successfully synced VIP contacts to IndexedDB', {
        count: vipContacts?.length || 0
      });
      
      return vipContacts?.length || 0;
    } catch (error) {
      console.error('Error syncing VIP contacts to IndexedDB:', error);
      throw error;
    }
  }
}

export default new IndexedDBService(); 