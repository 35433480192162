import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';

const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(() => {
    const savedStartDate = localStorage.getItem('globalStartDate');
    return savedStartDate ? dayjs(savedStartDate) : dayjs().subtract(365, 'day');
  });
  
  const [endDate, setEndDate] = useState(() => {
    const savedEndDate = localStorage.getItem('globalEndDate');
    return savedEndDate ? dayjs(savedEndDate) : dayjs();
  });

  const updateDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DateContext.Provider value={{ startDate, endDate, updateDates }}>
      {children}
    </DateContext.Provider>
  );
};

export const useDateRange = () => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDateRange must be used within a DateProvider');
  }
  return context;
}; 