import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-au';  // Import Australian locale
import { DateProvider } from './context/DateContext';  // Import DateProvider
import DashboardLayout from './layouts/DashboardLayout';
import Dashboard from './pages/Dashboard';
import Clients from './pages/Clients';
import ClientDetails from './pages/ClientDetails';
import Locations from './pages/Locations';
import LocationDetails from './pages/LocationDetails';
import Insights from './pages/Insights';
import PurePromote from './pages/PurePromote';
import Marketing from './pages/Marketing';
import './index.css';

// Set dayjs to use Australian locale
dayjs.locale('en-au');

function App({ onLogout, userRole }) {
  return (
    <DateProvider>
      <LocalizationProvider 
        dateAdapter={AdapterDayjs} 
        adapterLocale="en-au"
        localeText={{ 
          start: 'Start Date', 
          end: 'End Date',
          fieldMonthPlaceholder: () => 'MM',
          fieldDayPlaceholder: () => 'DD',
          fieldYearPlaceholder: () => 'YYYY',
        }}
      >
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <Router>
            <Routes>
              <Route path="/" element={<DashboardLayout onLogout={onLogout} userRole={userRole} />}>
                {userRole === 'admin' ? (
                  <>
                    <Route index element={<Dashboard />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="clients/:clientId" element={<ClientDetails />} />
                    <Route path="locations" element={<Locations />} />
                    <Route path="locations/:locationId" element={<LocationDetails />} />
                    <Route path="insights" element={<Insights />} />
                    <Route path="pure-promote" element={<PurePromote />} />
                    <Route path="marketing" element={<Marketing />} />
                  </>
                ) : (
                  <>
                    <Route path="clients" element={<Clients />} />
                    <Route path="clients/:clientId" element={<ClientDetails />} />
                    <Route path="locations" element={<Locations />} />
                    <Route path="locations/:locationId" element={<LocationDetails />} />
                    <Route path="pure-promote" element={<PurePromote />} />
                    <Route path="*" element={<Clients />} />
                  </>
                )}
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </DateProvider>
  );
}

export default App;
